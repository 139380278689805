import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import {Link} from 'react-router-dom'
import  {UserContext} from "../../providers/UserProvider";

import {useParams} from 'react-router-dom'

export default function AuditSettingsQuestionEdit (){
    const user = useContext(UserContext)
    const {qid} = useParams()
    const [question, setQuestion] = useState('');
    const onChangeHandler = (event) => {
      const {name, value} = event.currentTarget;
      if(name === 'Question') {
          setQuestion(value);
      }
      
    }
  
  const [cards, setCards]=useState([])
    const updateQuestion = async(event) =>
      {
          event.preventDefault();  
          const options =   
          {
            method: 'post',
            data: {'question':question,
                  'questionID':qid},
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: 'https://gbmercury.com/audit/question/update',
          }
          
          const response = await axios(options)
          
          console.log(response)
          
          
      }
    useEffect(()=>{
      
      const loadData = async() =>
      {
        
        const response = await axios(
          {
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/audit/question/${qid}`
        }
        )
        //setCampusDetail(responseC.data[0])
        setQuestion(response.data[0].Question)
      }
      loadData()   
      
    },[])

    return (
        <>
        <Link to={`/auditsettings-question`}>back to question bank</Link>
        <form >
            <label>Question</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="Question"
            value = {question}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!question}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {updateQuestion(event)}}
            
            >
                Update Question
            </button>
            

            </form>
            </>
    )
}
