import React, { useContext } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Item from './item'
import Logo from './logo'
import '../../css/components/left-sidebar-1.css'
import { UserContext } from '../../providers/UserProvider'
import { FiCompass, FiTrendingDown, FiVideo } from 'react-icons/fi'
import { FaSchool, FaRegCalendarCheck, FaBarcode } from 'react-icons/fa'
import { GiChart, GiSettingsKnobs } from 'react-icons/gi'
import { HiDocumentReport } from 'react-icons/hi'
import { GrDocumentPdf } from 'react-icons/gr'
import { GiAirZigzag } from 'react-icons/gi'
import { GiBlackHandShield } from "react-icons/gi";
import { MdCleanHands } from "react-icons/md";

const Sidebar = () => {
    const user = useContext(UserContext)
    // const {navigation} = useSelector(
    //   (state) => ({
    //     navigation: state.navigation
    //   }),
    //   shallowEqual
    // )
    // {
    //   title: 'School Data',
    //   items: [
    //     {
    //       url: '/school-attendance',
    //       icon: <FaSchool size={20} />,
    //       title: 'Attendance Chart',
    //       items: []
    //     },

    //   ]
    // },
    var navigation = []
    const attendanceMenu={
          title: 'School Data',
          items: [
            {
              url: '/school-attendance',
              icon: <FaSchool size={20} />,
              title: 'Attendance Chart',
              items: []
            },
    
          ]
        }
    const atpMenu = {
        title: 'ATP',
        items: [
            {
                url: '/atp-client',
                icon: <GiChart size={20} />,
                title: 'My ATP Samples',
                items: [],
            },
            {
                url: '/atp-all',
                icon: <GiChart size={20} />,
                title: 'All ATP Data',
                items: [],
            },
            {
                url: '/atp-facility',
                icon: <GiChart size={20} />,
                title: 'ATP by Area',
                items: [],
            },
            {
                url: '/',
                icon: <FiTrendingDown size={20} />,
                title: 'Trends',
                items: [
                    {
                        url: '/atp-month',
                        title: 'Monthly',
                        items: [],
                    },
                    {
                        url: '/atp-quarter',
                        title: 'Quarterly',
                        items: [],
                    },
                    {
                        url: '/atp-year',
                        title: 'Yearly',
                        items: [],
                    },
                ],
            },
        ],
    }
    var auditMenu = {
        title: 'Audit Module',
        items: [
            {
                url: '/audit-dashboard',
                icon: <FiCompass size={20} />,
                title: 'Dashboard',
                items: [],
            },
            {
                url: '/audit-history',
                icon: <FiCompass size={20} />,
                title: 'Audit History',
                items: [],
            },
        ],
    }
    const auditSettingsMenu = {
        url: '/',
        icon: <GiSettingsKnobs size={20} />,
        title: 'Settings',
        items: [
            {
                url: '/auditsettings-facility',
                title: 'Facilities',
                items: [],
            },
            {
                url: '/auditsettings-personnel',
                title: 'Personnel',
                items: [],
            },
            {
                url: '/auditsettings-question',
                title: 'Questions',
                items: [],
            },
            {
                url: '/auditsettings-template',
                title: 'Templates',
                items: [],
            },
        ],
    }
    console.log(user)
    const auditReportsMenu = {
        url: '/',
        icon: <HiDocumentReport size={20} />,
        title: 'Reports',
        items: [
            {
                url: '/auditreport-atp',
                title: 'ATP',
                items: [],
            },
            {
                url: '/auditreport-facility',
                title: 'Facility',
                items: [],
            },
            {
                url: '/auditreport-personnel',
                title: 'Personnel',
                items: [],
            },
        ],
    }
    if (user.auditadmin > 1) {
        auditMenu.items = [...auditMenu.items, auditSettingsMenu]
    }
    if (user.auditadmin > 2) {
        auditMenu.items = [...auditMenu.items, auditReportsMenu]
    }
    if (user.pdfLevel > 0 || user.videoLevel > 0) {
        var resourcesMenu = {
            title: 'Resources',
            items: [],
        }
        const pdfSubMenu = {
            url: '/resources-library',
            icon: <GrDocumentPdf size={20} />,
            title: 'Library',
            items: [],
        }
        const videoSubMenu = {
            url: '/resources-video',
            icon: <FiVideo size={20} />,
            title: 'Videos',
            items: [],
        }
        const videoReportSubMenu = {
            url: '/video-report',
            icon: <FiVideo size={20} />,
            title: 'Video Reporting',
            items: [],
        }
        if (user.pdfLevel > 0) {
            resourcesMenu.items = [pdfSubMenu, ...resourcesMenu.items]
        }
        if (user.videoLevel > 0) {
            resourcesMenu.items = [videoSubMenu, ...resourcesMenu.items]
        }
        if (user.videoLevel > 1) {
            resourcesMenu.items = [...resourcesMenu.items, videoReportSubMenu]
        }
        navigation = [resourcesMenu, ...navigation]
    }

    if (user.equipLevel > 0 || user.serviceLevel > 0) {
        var serviceMenu = {
            title: 'Service Reports',
            items: [],
        }
        const equipSubMenu = {
            url: '/reports-equipment',
            icon: <FaBarcode size={20} />,
            title: 'Equipment',
            items: [],
        }
        const serviceSubMenu = {
            url: '/reports-services',
            icon: <FaRegCalendarCheck size={20} />,
            title: 'Services',
            items: [],
        }
        if (user.serviceLevel > 0) {
            serviceMenu.items = [serviceSubMenu, ...serviceMenu.items]
        }
        if (user.equipLevel > 0) {
            serviceMenu.items = [equipSubMenu, ...serviceMenu.items]
        }
        navigation = [serviceMenu, ...navigation]
    }
    const iaqMenu = {
        title: 'Indoor Air Quality',
        items: [
            {
                url: '/iaq-dash',
                icon: <GiAirZigzag size={20} />,
                title: 'Indoor Air Quality',
                items: [],
            },
        ],
    }
    // const hhMenu = {
    //     title: 'Hand Hygiene',
    //     items: [
    //         {
    //             url: '/handhygienedash',
    //             icon: <GiBlackHandShield size={20} />,
    //             title: 'Hand Hygiene Dashboard',
    //             items: [],
    //         },
    //     ],
    // }


    const gbMenu = {
        title: 'GBResources',
        items: [
            {
                url: '/change-office',
                icon: <GrDocumentPdf size={20} />,
                title: 'Change Office',
                items: [],
            },
        ],
    }
    const clientSwitch = {
        title: 'Accounts',
        items: [
            {
                url: '/change-account',
                icon: <GrDocumentPdf size={20} />,
                title: 'Change Accounts',
                items: [],
            },
        ],
    }
    if (user.auditadmin > 0) {
        navigation = [auditMenu, ...navigation]
    }
    if (user.iaqLevel > 0) {
        navigation = [iaqMenu, ...navigation]
    }
    // if (user.handHygieneLevel == 0) {
    //     navigation = [hhMenu, ...navigation]
    // }
    if (user.attendance > 0) {
        navigation = [attendanceMenu, ...navigation]
    }
    if (user.atpLevel > 0) {
        navigation = [atpMenu, ...navigation]
    }
    

    if (user.isGBEmployee > 0) {
        navigation = [...navigation, gbMenu]
    }
    if (user.multipleClients > 0) {
        navigation = [...navigation, clientSwitch]
    }
    return (
        <div className='left-sidebar left-sidebar-1'>
            <Logo />
            {navigation.map((menu, i) => (
                <div key={i}>
                    <div className='left-sidebar-title'>
                        <span>{menu.title}</span>
                    </div>
                    <ul>
                        {menu.items.map((l0, a) => (
                            <li key={a} className='l0'>
                                <Item {...l0} />
                                <ul>
                                    {l0.items.map((l1, b) => (
                                        <li key={b} className='l1'>
                                            <Item {...l1} />
                                            <ul>
                                                {l1.items.map((l2, c) => (
                                                    <li key={c} className='l2'>
                                                        <Item {...l2} />
                                                        <ul>
                                                            {l2.items.map(
                                                                (l3, d) => (
                                                                    <li
                                                                        key={d}
                                                                        className='l3'
                                                                    >
                                                                        <Item
                                                                            {...l3}
                                                                        />
                                                                        <ul>
                                                                            {l3.items.map(
                                                                                (
                                                                                    l4,
                                                                                    e
                                                                                ) => (
                                                                                    <li
                                                                                        key={
                                                                                            e
                                                                                        }
                                                                                        className='l4'
                                                                                    >
                                                                                        <Item
                                                                                            {...l4}
                                                                                        />
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}

export default Sidebar
