import React, {useEffect, useState, useContext}  from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import MyBarChart from '../../components/charts/auditatp'
import axios from 'axios'
import moment from 'moment'
import {ClientContext} from '../../providers/ClientProvider'

const AuditReportATP = () => {
const [allATP, setAllATP]=useState()
var auditSamples=[]
const [client, setClient]=useContext(ClientContext)
const [dailyData, setDailyData]=useState([])
const [campusTable, setCampusTable]= useState()
const [custodianTable, setCustodianTable]=useState()
const [areaTable, setAreaTable]=useState()
const [mainAudit, setMainAudit]= useState()
useEffect(()=>{
  const options =   
  {
    method: 'get',
    headers: {'Accept':'application/json',
      'culper':'culper',
      'Content-Type':'application/json'},
    url: `https://gbmercury.com/auditatp/all/${client}`,
  }
  const options2 =   
  {
    method: 'get',
    headers: {'Accept':'application/json',
      'culper':'culper',
      'Content-Type':'application/json'},
    url: `https://gbmercury.com/auditcustodian/all/${client}`,
  }
  const options3 =   
  {
    method: 'get',
    headers: {'Accept':'application/json',
      'culper':'culper',
      'Content-Type':'application/json'},
    url: `https://gbmercury.com/auditcampus/all/${client}`,
  }
  const options4 =   
  {
    method: 'get',
    headers: {'Accept':'application/json',
      'culper':'culper',
      'Content-Type':'application/json'},
    url: `https://gbmercury.com/auditarea/all/${client}`,
  }
  
  const loadData = async() =>
  {

   axios.all([
      axios(options),
      axios(options2),
      axios(options3),
      axios(options4),
    ]).then(axios.spread(function(atp, custodian, campus, area) {
      console.log(atp.data)
      console.log(custodian.data)
      console.log(campus.data)
      console.log(area.data)
      atp.data.sort((a,b)=> a.DateTime > b.DateTime ? 1 : -1)
      atp.data.map(ad=>{
         dailyData.push({'Raw': (ad.DateTime*1000), 'Date':moment(ad.DateTime*1000).format("MM/D/YYYY"),
        'custname': ad.CustodianID, 'ATP': ad.ATPResult,
      'Area': ad.AreaID, 'Campus': ad.CampusID, 'Description': ad.ATPSite, Id: ad.ID}) 
      })

var custodians=custodian.data
var campuses= campus.data
var areas=area.data

setMainAudit(atp.data)
setCustodianTable(custodian.data)
setCampusTable(campus.data)
setAreaTable(area.data)
console.log(dailyData)

    Object.entries(dailyData).map(([k,v]) => {
  console.log(custodians[0].CustodianID)
  console.log(v.custname)
      const myObj={
        "Raw":v.Raw ,
        "Title":v.Date + v.Id,
        "tagTitle":v.Date + " " + custodians.filter(c=>c.CustodianID===v.custname)[0].Name +
        " @ " + campuses.filter(c=>c.CampusID===v.Campus)[0].CampusName  +
       " " ,
        "tagTitle2": areas.filter(a=>a.AreaID===v.Area)[0].AreaName +
        " - " + v.Description,
        "Result":v.ATP,
        "Total":v.ATP,
      }
      auditSamples.push(myObj)

    })
  
    setAllATP(auditSamples)
   
    console.log(mainAudit)
    })).catch(err=>{
      console.log(err + ' there was an error')
    })

  }
  if(client >0){
  loadData()   
  }
},[client])
if (allATP ) {
return(
  <>
    <SectionTitle title="Pages" subtitle={`Audit Report - ATP ${client} `} />
    <Widget title="Page title" description={<span>See ATP Samples from Your Audits Here</span>}>
 

      <MyBarChart chartData={allATP} />
      <table className="table border-gray-600 border-4">
        <thead className="font-semibold">
          <tr >
            <td className="text-center" >Date/Time</td>
            <td className="text-center">ATP Location</td>
            <td className="text-center"> ATP Value (RLU)</td>
          </tr>
        </thead>
        <tbody>
          
            {mainAudit.map(as=>{
             return <tr key={as.ID}>
            
               <td>{moment(as.DateTime*1000).format("MM/D/YYYY")}</td>
                <td className="text-center">{custodianTable.filter(c=>c.CustodianID===as.CustodianID)[0].Name } @ 
                {campusTable.filter(c=>c.CampusID===as.CampusID)[0].CampusName + " " }
                {areaTable.filter(a=>a.AreaID===as.AreaID)[0].AreaName + " " + as.ATPSite} </td> 
               <td>{as.ATPResult}</td>
               </tr>
            })}
          
        </tbody>
      </table>
    </Widget>
  </>
)} else {
  return(
    <>
      <SectionTitle title="Trend Reports" subtitle="Monthly Trends" />
      <Widget title="Month Chart" description={<span>See a chart with averages by month</span>}>
        
        Loading Chart Data...
      </Widget>
    </>
  ) } }
  
export default AuditReportATP
