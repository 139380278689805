import React, {Component} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
class ATPPreVertical extends Component {
    componentDidMount() {
      let chart = am4core.create("atporder", am4charts.XYChart);
      chart.paddingRight = 20;
      let data = this.props.chartData
      chart.data = data;
      console.log(data)  
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category="ATPloc"
      categoryAxis.fontSize="0px";
      //don't show the labels!!!! they don't look good.  dates are too long
      //categoryAxis.fontSize="0px";
      //let label = categoryAxis.renderer.labels.template;
      //label.disabled=true;
    //   dateAxis.renderer.grid.template.location = 0;
    //   dateAxis.groupData = false;
    //   dateAxis.baseInterval={
    //       "timeUnit":"second",
    //       "count":1
    //   }
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
      
      let title = chart.titles.create();
      title.text="ATP Samples from Most Recent Service";
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "ATPloc";
      series.dataFields.valueY = "ATPVal";
      series.tooltipText = `{ATPloc} - {ATPVal} RLU `;

      chart.cursor = new am4charts.XYCursor();
  
      //let scrollbarX = new am4charts.XYChartScrollbar();
      //scrollbarX.renderer.labels.template.disabled=true;

      //scrollbarX.series.push(series);
      
      //chart.scrollbarX = scrollbarX;
    
      this.chart = chart;
    }
  
    componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }
  
    render() {
      return (
        <div id="atporder" style={{ width: "100%", height: "300px" }}></div>
      );
    }
  }
  export default ATPPreVertical