import React, {useContext, useEffect, useState} from 'react'
import firebase from '../../firebase' 
import SectionTitle from '../../components/section-title'
import Section from '../../components/dashboard/section'
import {ClientContext} from '../../providers/ClientProvider'
import axios from 'axios'
import {
  BsCloudDownload
} from 'react-icons/bs'

function ResourcesLibrary () {
  const [client, setClient] = useContext(ClientContext)
  const [clientInfo, setClientInfo]=useState()
  useEffect(()=>{
    
    const loadData = async() =>
    {
      const responseCI = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/customer/id/${client}`
      }
      )
      setClientInfo(responseCI.data[0])
      
    }
    loadData()   
    
  },[client])
  return (
  <>
    <SectionTitle title="Resources" subtitle="File Library" />
    <div className="flex flex-row flex-wrap">
        <div className="w-full">
         <Section
            title="Pre-Service Checklists"
            description={<span>Get checklists so you're ready for your next GermBlast service.</span>}
            >
            <ul>
            {
              clientInfo && clientInfo.CType == 4 ?
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FResidentialPre-serviceChecklist2022.pdf?alt=media&token=b4b24ec6-f54e-482b-9adc-8615963246be"  target="_blank" rel="noopener noreferrer" >
            <li>Pre-Service Checklist - Residential.  A checklist for home environments.</li>
            </a>
            :null}
            {
              clientInfo && clientInfo.CType == 2 ?
            <>
            
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FGB%20Pre-serviceAthleticsCL2022.pdf?alt=media&token=358b32df-ac9a-4e57-a40e-ae2e5ee0fd84"  target="_blank" rel="noopener noreferrer" >
            <li>Pre-Service Checklist - Athletics.  A checklist for athletic environments.</li>
            </a>
     
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FEducationPre-serviceChecklistApril2022.pdf?alt=media&token=435b7115-6d11-45c9-8957-fbe114846833"  target="_blank" rel="noopener noreferrer" >
            <li>Pre-Service Checklist - Education Facilities.  A checklist for school environments.</li>
            </a>
            </>
            :null}
            {
              clientInfo && clientInfo.CType != 2 && clientInfo.CType != 1 && clientInfo.CType != 24 && clientInfo.CType != 4 ?
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FGeneralPre-serviceChecklist2022.pdf?alt=media&token=ea2ad1ff-8c40-4589-83f0-92d76dcf47b7"  target="_blank" rel="noopener noreferrer" >
            <li>Pre-Service Checklist - General.  A checklist for most environments.</li>
            </a>
            :null}
            {
              clientInfo && (clientInfo.CType == 1 || clientInfo.CType == 24) ?
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FHealthcarePre-serviceChecklist2022.pdf?alt=media&token=974a1137-9568-4130-8386-e2bdb9ce7708"  target="_blank" rel="noopener noreferrer" >
              
            <li>Pre-Service Checklist - Healthcare.  A checklist for heatlhcare environments.</li>
            </a>
            :null}
            {
              clientInfo && (clientInfo.CType == 22 || clientInfo.CType == 23) ?
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FGB%20Pre-serviceChildcarePreschoolChurches%20CL2022.pdf?alt=media&token=ea91b128-7c8a-40a4-9264-46cb1755a43c"  target="_blank" rel="noopener noreferrer" >
            <li>Pre-Service Checklist - Childcare.  A checklist for childcare environments.</li>
            </a>
            :null}
            
            </ul>
          </Section>
          
        </div>
        
  </div>


  
    <div className="flex flex-row flex-wrap">
        <div className="w-full">
         <Section
            title="Protocols"
            description={<span>Find protocols from the GermBlast team here.</span>}
            >
            <ul>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fproshieldlaundry.pdf?alt=media&token=1f36cdba-4020-4914-9399-f3231def2aab"  target="_blank" rel="noopener noreferrer" >
            <li>Proshield 5000D Laundry Protocol</li>
            </a>
            </ul>
          </Section>
          
        </div>
        
  </div>
  
  <div className="flex flex-row flex-wrap">
        <div className="w-full">
         <Section
            title="SDS Sheets"
            description={<span>Find an SDS for all disinfectants and other chemicals used by the GermBlast team.</span>}
            >
            <ul>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FAlpetD2SDS.pdf?alt=media&token=af783e00-6830-40e8-a25e-e5411f6b75ce"  target="_blank" rel="noopener noreferrer" >
            <li>Alpet D2</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fbioshield75msds.pdf?alt=media&token=23238b61-7558-46b3-bde1-fe8bc79abd95"  target="_blank" rel="noopener noreferrer" >
            <li>Bioshield 75</li>
            </a>
            
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fgoogonesds.pdf?alt=media&token=52085a0b-25c8-4ec9-9a94-ae10fa50f42d"  target="_blank" rel="noopener noreferrer" >
            <li>Goo Gone</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fhalomistsds.pdf?alt=media&token=e62f1d4f-0b59-4b6d-aeea-4b5f32bc0133"  target="_blank" rel="noopener noreferrer" >
            <li>Halomist</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FOpticideSDS.pdf?alt=media&token=96bf9c25-3afd-4dae-858b-5bb6ebf4378c"  target="_blank" rel="noopener noreferrer" >
            <li>Opticide</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Foxivirfive16concentratesds.pdf?alt=media&token=6d3bd14c-5e87-4920-a80d-6f4d1418b6a6"  target="_blank" rel="noopener noreferrer" >
            <li>Oxivir Five16</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fproshield5000d.pdf?alt=media&token=58ffffcb-9d52-43eb-8256-f8aad3666a1c"  target="_blank" rel="noopener noreferrer" >
            <li>Proshield 5000D</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FSTERIFABSDS.pdf?alt=media&token=51a7ac35-2e25-4801-b5f5-b10810a83832"  target="_blank" rel="noopener noreferrer" >
            <li>Sterifab</li>
            </a>
            </ul>
          </Section>
          
        </div>
        
  </div>

  <div className="flex flex-row flex-wrap">
        <div className="w-full">
         <Section
            title="Awareness Flyers"
            description={<span>Share these informational pieces with your clients and other stakeholders.</span>}
            >
            <ul>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FGSNorovirus2017.pdf?alt=media&token=880ab9cf-a378-4e07-a9e9-62e274b1bd5d"  target="_blank" rel="noopener noreferrer" >
            <li>Norovirus</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2FGSflu.pdf?alt=media&token=98d8f75e-83cf-42eb-b773-474e42064bdc"  target="_blank" rel="noopener noreferrer" >
            <li>Influenza</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fschoolflyer-eng.pdf?alt=media&token=8a7c9c26-704e-4dc6-87d4-c52e6a54e6a2"  target="_blank" rel="noopener noreferrer" >
            <li>School District Partnership</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fschoolflyer-spa.pdf?alt=media&token=11e6a506-3e9e-40c6-a198-11680a5ef6ab"  target="_blank" rel="noopener noreferrer" >
            <li>School District Partnership - Spanish</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fwhatisgb.pdf?alt=media&token=9a733511-8ddf-479f-9851-f344eea1e7b8"  target="_blank" rel="noopener noreferrer" >
            <li>What is GermBlast?</li>
            </a>
            </ul>
          </Section>
          
        </div>
        
  </div>


  <div className="flex flex-row flex-wrap">
        <div className="w-full">
         <Section
            title="Infectious Disease Reference Documents"
            description={<span>A library of technical information on infectious diseases from the CDC and other experts.</span>}
            >
            <ul>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2F2019-ncov-factsheet.pdf?alt=media&token=e3e388d4-de67-4f68-bc7c-a8e982f4b77a"  target="_blank" rel="noopener noreferrer" >
            <li>Novel Coronavirus (2019-nCOV)</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Facinetobacter-cdc.pdf?alt=media&token=43fe39f2-e190-4c1f-94c8-9542c414a54b"  target="_blank" rel="noopener noreferrer" >
            <li>Multidrug-Resistant Acinetobacter</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fcampylobacter-cdc.pdf?alt=media&token=12b5f836-7ad1-46b5-b391-6a0e6258b5f6"  target="_blank" rel="noopener noreferrer" >
            <li>Drug-Resistant Campylobacter</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fcandida-cdc.pdf?alt=media&token=70974063-fd71-4da5-a2bb-9cdd7f91b013"  target="_blank" rel="noopener noreferrer" >
            <li>Fluconazole-Resistant Candida</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fcdiff-cdc.pdf?alt=media&token=7b4f1d5b-f6f2-474c-a504-724d52c15d15"  target="_blank" rel="noopener noreferrer" >
            <li>Clostridium difficile</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fcre-cdc.pdf?alt=media&token=648c8cee-fa68-4005-915d-d0ac9e766b95"  target="_blank" rel="noopener noreferrer" >
            <li>Carbapenum-Resistant Enterobacteriaceae</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fcre-cdc.pdf?alt=media&token=648c8cee-fa68-4005-915d-d0ac9e766b95"  target="_blank" rel="noopener noreferrer" >
            <li>Clindamycin-Resistant Group B Streptococcus</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fenterobacteriaceae-cdc.pdf?alt=media&token=ecd3e46f-adac-40be-bd5a-a9f3b0dabe48"  target="_blank" rel="noopener noreferrer" >
            <li>Extended Spectrum Beta-Lactamase (ESBL) Producing Enterobacteraceae</li>
            </a>
            
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fgonorrhoeae-cdc.pdf?alt=media&token=ef235b29-73b9-4e9b-a2ac-23ad3e0d2c15"  target="_blank" rel="noopener noreferrer" >
            <li>Drug-Resistant Neisseria Gonorrhoeae</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fmrsa-cdc.pdf?alt=media&token=ecce0a27-d91c-4707-b875-52ba05dcd7e2"  target="_blank" rel="noopener noreferrer" >
            <li>Methicillin-Resistant Staphylococcus Aureus (MRSA)</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fpseudomonas-cdc.pdf?alt=media&token=02736faf-0323-4455-9f26-13b59b83e9a1"  target="_blank" rel="noopener noreferrer" >
            <li>Multidrug-Resistant Pseudomonas Aeruginosa</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fsalmonella-cdc.pdf?alt=media&token=7d0f3dce-269e-42a3-b21b-5e5d65e46a48"  target="_blank" rel="noopener noreferrer" >
            <li>Drug-Resistant Non-Typhoidal Salmonella</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fsalmonellatyphi-cdc.pdf?alt=media&token=9f54bf99-9807-4da3-a335-a368038b0520"  target="_blank" rel="noopener noreferrer" >
            <li>Drug-Resistant Salmonella Serotype Typhi</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fshigella-cdc.pdf?alt=media&token=088854a3-5286-4484-a987-5e9b16e2a619"  target="_blank" rel="noopener noreferrer" >
            <li>Drug-Resistant Shigella</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fstreptococcus-cdc.pdf?alt=media&token=1daaa8e4-5b72-4c61-aa74-28032211ed87"  target="_blank" rel="noopener noreferrer" >
            <li>Drug-Resistant Streptococcus Pneumoniae</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Ftuberculosis-cdc.pdf?alt=media&token=40eca30c-b89d-4fbd-9f3a-c4b6996a5696"  target="_blank" rel="noopener noreferrer" >
            <li>Drug-Resistant Tuberculosis</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fvre-cdc.pdf?alt=media&token=c79bf521-c3f8-4610-b7c3-48ecb35ed1ca"  target="_blank" rel="noopener noreferrer" >
            <li>Vancomycin-Resistant Enterococcus (VRE)</li>
            </a>
            <a href="https://firebasestorage.googleapis.com/v0/b/matrix2-26dce.appspot.com/o/gs-docs%2Fvrsa-cdc.pdf?alt=media&token=8ec1474f-39c6-4a17-92af-8eab23ece42e"  target="_blank" rel="noopener noreferrer" >
            <li>Vancomycin-Resistant Staphylococcus Aureus (VRSA)</li>
            </a>
            
            </ul>
          </Section>
          
        </div>
        
  </div>    

   

  </>
)}
export default ResourcesLibrary
