import React from 'react';
import {Link} from 'react-router-dom'
import moment from 'moment'
export default function AuditList  (props) {
    
    
    return (
        <table  className="w-full">
            <tbody>
            {props.Audits.map((singleA, index)=>(
                <tr className="mb-4" key={index}>
                    <td className="m-3" key={singleA.AuditResultsID}><Link to={`/auditreportcard/${singleA.AuditResultsID}`}>
                    <table className="w-full">
                        <tbody>
                        <tr className="w-full">
                            <td className="w-48">
                            <span className="text-2xl font-bold">{moment((singleA.PerformedOn*1000)).format("M/D/YYYY")}</span>
                            </td>
                            <td className="w-1/2">
                            <span className="text-2xl font-bold">Audit Template: {singleA.AuditName}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="w-1/2">
                            <span className="text-xl">{singleA.CampusName} {singleA.AreaName}</span>
                            </td>
                            <td className="w-1/2">
                            <span className="text-xl">{singleA.Custodian}</span>
                            </td>
                        </tr>
                        <tr className="">
                            <td className="w-96 bg-blue-300 rounded p-3 mx-2">
                            <span className="w-96 text-xl">{singleA.Score}%</span>
                            </td>
                            <td className="w-96  bg-green-300 rounded p-3 mx-2">
                            <span className="w-96 text-xl">{singleA.AvgATP} RLU</span>
                            </td>
                        </tr>
                        </tbody>
                        </table>    
                        </Link>
                    
                    </td>
                    
                </tr>
                
            )
            )}
            </tbody>
        </table>
    );
}

