import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import {Link} from 'react-router-dom'
import  {UserContext} from "../../providers/UserProvider";
import { useSnackbar } from 'notistack'
import {useParams} from 'react-router-dom'

export default function AuditSettingsAreaEdit (){
    const user = useContext(UserContext);
    const { enqueueSnackbar} = useSnackbar()
    const {areaid} = useParams()
    const [areaName, setAreaName] = useState('');
    const [campusID, setCampusID] = useState(0);

    const onChangeHandler = (event) => {
      const {name, value} = event.currentTarget;
      if(name === 'AreaName') {
          setAreaName(value);
      }
  };
  const [cards, setCards]=useState([])
    const updateArea = async(event) =>
      {
          event.preventDefault();  
          const options =   
          {
            method: 'post',
            data: {'areaName':areaName,
                'areaID':areaid,
                'campusID':campusID,
                  'clientID':user.CustID},
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: 'https://gbmercury.com/audit/area/update',
          }
          
          const response = await axios(options)
          
          setCards(existingCards=>[...existingCards,{AreaID:response.data.insertId,AreaName:areaName, Active:1, ATPAvg: 0, ATPStDev: 0, Score: 0}])
          enqueueSnackbar('Area Name Updated',{variant: 'success',});
          
          
      }
    useEffect(()=>{
      
      const loadData = async() =>
      {
        
        const response = await axios(
          {
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/audit/area/${areaid}`
        }
        )
        //setCampusDetail(responseC.data[0])
        setAreaName(response.data[0].AreaName)
        setCampusID(response.data[0].CampusID)
      }
      loadData()   
      
    },[])

    return (
        <>
        <Link to={`/auditsettings-area/${campusID}`}>back to campus view</Link>
        <form >
            <label>Area Name</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="AreaName"
            value = {areaName}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!areaName}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {updateArea(event)}}
            >
                Update Area
            </button>
            </form>
        
        </>
    );
}
