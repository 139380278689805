import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import './chartstyles.scss'

const TemperatureFChart=({airthinxData})=>{

var TemperatureFData=[]
var timestampsData=[]
var timestampDay=[]

airthinxData.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
       {
        if(Item.measurements){
        for(var i=0; i<Item.measurements.length; i++){
 
        if(Item.name==="Temperature" && Item.unit==="°F")
        {
            TemperatureFData.push(Item.measurements[i][1]);
            timestampsData.push(moment(Item.measurements[i][0]).format("h:mm"))
            timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }
       }


       const dataTemperatureF= {
        labels: timestampsData  ,
        datasets: [
          { 
            
            borderColor: TemperatureFData.map(data=>
              {if(data> 83 || data< 55){
                return 'red'
            }else if(data>74 || data>68){
               return '#d4b624'
            }else{
                return 'green'
            }} ) ,
            backgroundColor: TemperatureFData.map(data=>
              {if(data> 83 || data< 55){
                return 'red'
            }else if(data>74 || data>68){
               return '#d4b624'
            }else{
                return 'green'
            }} ) ,
            borderWidth: .5,
            hoverBackgroundColor: 'rgb(91, 86, 113)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:.7,
            pointRadius:1.5,
            pointHoverRadius: 6,
            data: TemperatureFData,
          fill: false    }, 
      ], 
      
        };
        const minData=Math.min(...TemperatureFData);
        const maxData=Math.max(...TemperatureFData)
        const avgData = TemperatureFData.reduce((a,b) => a + b, 0) / TemperatureFData.length
         TemperatureFData.map(function(value){
          var diff = value - avgData;
          return diff;
          
        });
        var squareDiffs = TemperatureFData.map(function(value){
          var diff = value - avgData;
          var sqr = diff * diff;
          return sqr;
        });
        function average(data){
          var sum = data.reduce(function(sum, value){
            return sum + value;
          }, 0);
        
          var avg = sum / data.length;
          return avg;
        }
        
        var avgSquareDiff = average(squareDiffs)
        var stdDev = Math.sqrt(avgSquareDiff);

return(
<div>

{TemperatureFData.length<1 ? (<p className="text-center m-5">No <b>Temperature °F</b> Data for Time Period</p>): (<Line data={dataTemperatureF}
 options={{
    title:{
      display:true,
      text:['Temperature °F'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right' },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: '°F',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min: 10,

            max:  maxData>96? (Math.round(maxData+ maxData*.2)):(100),
           
          }   }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[TemperatureFData.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: false,
        "autoSkipPadding": 5, } }]
  },
  annotation: {
    annotations: [{
       
       id: 'hline3',
       type: 'line',
       mode: 'horizontal',
       scaleID: 'y-axis-0',
       value: 74,
       borderColor: '#d4b624',
       borderWidth: .75,
       color: '#d4b624',
       label: {
          backgroundColor: "#d4b624",
          content: "Moderate >74",
         
          enabled: false
       }
    },{
       
      id: 'hline5',
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: 68,
      borderColor: '#d4b624',
      borderWidth: .75,
      color: '#d4b624',
      label: {
         backgroundColor: "#d4b624",
         content: "Moderate <68",
        
         enabled: false
      }
   }, {
       id: 'hline2',
       type: 'line',
       mode: 'horizontal',
       scaleID: 'y-axis-0',
       value: 83,
       borderColor: 'red',
       borderWidth: .75,
       label: {
          backgroundColor: "red",
          content: "Bad >83",
          enabled: false,
          
       }
    }, {
      id: 'hline4',
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: 55,
      borderColor: 'red',
      borderWidth: .75,
      label: {
         backgroundColor: "red",
         content: "Bad <55",
         enabled: false,
         
      }
   }]
 },
  tooltips:{
    intersect: false }  }}
 />)} 

<div className="d-flex flex-row justify-content-around align-items-center">
{/* <table className="">
  <tbody>
  <tr className="">
    <td className="minmax" > Minimum  </td>
    <td className="minmax text-right">{minData.toFixed(2)}</td>
  </tr>
  <tr className="">
    <td className="minmax"> Average </td>
    <td className="minmax text-right">{avgData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Maximum </td>
    <td className="minmax text-right">{maxData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Standard Deviation </td>
    <td className="minmax text-right">{stdDev.toFixed(2)}</td>
  </tr>
  </tbody>
</table> */}

<div className="legend">
  <table>
    <tbody>
  <tr className="legendRow">
<td>Excessive</td> 
<td className="redBox"> { ` >83 or <55 `}</td>
</tr>
<tr>
<td>Moderate</td> 
<td className="yellowBox">{ `>74 or <68 `}</td>
</tr>
<tr>
<td>Good</td> 
<td className="greenBox">{ ` >68 or <74   `}</td>
</tr>
</tbody>
  </table>
</div>

</div>

</div>

)
  }

  export default TemperatureFChart
