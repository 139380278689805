import React from 'react';
import {Link} from 'react-router-dom'

export default function EmployeeList  (props) {
    
    
    return (
        <ul className="list-disc">
            
            {props.Employees.map((singleE)=>(
                <li className="m-3" key={singleE.CustodianID}><Link to={`/auditsettings-personneledit/${singleE.CustodianID}`}>{singleE.Name}</Link></li>
            )
            )}
            
        </ul>
    );
}

