import React, { useEffect, useState, useContext } from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import MyBarChart from '../../components/charts/am5charts-trend-atp'
import axios from 'axios'
import moment from 'moment'
import { ClientContext } from '../../providers/ClientProvider'
import { CSVLink} from 'react-csv'

function ATPMonth() {
    const [allATP, setAllATP] = useState()
    // eslint-disable-next-line
    const [client, setClient] = useContext(ClientContext)
    const [csvExport, setCSVExport] = useState({})
    var monthData = []
    var monthCount = []
    var monthRaw = []
    var monthFinal = []
    useEffect(() => {
        const options = {
            method: 'get',
            headers: {
                Accept: 'application/json',
                culper: 'culper',
                'Content-Type': 'application/json',
            },
            url: `https://gbmercury.com/atps/gs/indiv/customer/${client}`,
        }
        const loadData = async () => {
            var csvBuilder = []
            var tempVal = 0
            const response = await axios(options)
            var dataResult = response.data
            dataResult.sort((a, b) => (a.ATPDate > b.ATPDate ? 1 : -1))
            dataResult.map((e) => {
                e.ATPDate = e.ATPDate * 1000
                e.formattedDate = moment(e.ATPDate).format(
                    'MM/DD/YYYY hh:mm:ss a'
                )
                e.monthYear = moment(e.ATPDate).format('MM/YYYY')
                e.quarterYear = moment(e.ATPDate).format('Q/YYYY')
                e.Year = moment(e.ATPDate).format('YYYY')
                !isNaN(monthData[e.monthYear])
                    ? (tempVal = monthData[e.monthYear])
                    : (tempVal = 0)
                monthData[e.monthYear] = e.ATPVal + tempVal
                !isNaN(monthCount[e.monthYear])
                    ? (tempVal = monthCount[e.monthYear])
                    : (tempVal = 0)
                monthCount[e.monthYear] = tempVal + 1
                monthRaw[e.monthYear] = e.ATPDate
            })
            Object.entries(monthCount).map(([k, v]) => {
                var average = Math.round(monthData[k] / v)
                const myObj = {
                    Raw: monthRaw[k],
                    Title: k,
                    Average: average,
                    Total: monthData[k],
                    Count: v,
                }
                csvBuilder.push({
                    'Month/Year': k,
                    'ATP Value': average,
                })
                monthFinal.push(myObj)
            })
            //not needed for some reason? reverses the sort
            //monthFinal.sort((a,b)=> a.raw > b.raw ? 1 : -1)
            setCSVExport(csvBuilder)
            setAllATP(monthFinal)
        }
        if(client>0){
            loadData()
        }
    }, [client])

    
        return (
            <>
            { allATP ? 
                <>
                <SectionTitle title='Trend Reports' subtitle='Monthly Trends' />
                <Widget
                    title='Month Chart'
                    description={
                        <span>See a chart with averages by month</span>
                    }
                >
                    <MyBarChart chartData={allATP} />
                    <CSVLink
                        data={csvExport}
                        filename={'atp-average-by-month.csv'}
                    >
                        Download records to CSV
                    </CSVLink>
                </Widget>
                </>
                :
                null
            }
            </>
            
        )
     
}
export default ATPMonth
