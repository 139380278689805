import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { withRouter, useParams } from 'react-router-dom';
import  {UserContext} from '../../providers/UserProvider';
import  {ClientContext} from '../../providers/ClientProvider';
import DeviceCompareScatter from './DeviceCompareScatter'
import './Devicecomparestyles.scss'
import {useHistory} from 'react-router-dom'

const DeviceCompare=(props)=>{
  let history = useHistory()
  const [client,setClient] = useContext(ClientContext);
  const user = useContext(UserContext);
  const{zoneID}=useParams() 
  const [allNodeInfo, setAllNodeInfo]=useState([])
  const [selectedDate, setSelectedDate] = useState(Math.round(new Date().getTime()-86400000));
  const [selectedEndDate, setSelectedEndDate] = useState(Math.round(new Date().getTime()));
  const [deviceName, setDeviceName]=useState([])
  const [location, setLocation]=useState([])
  const [zone, setZone]=useState('')
  const [errorMsg, setErrorMsg]=useState(" ")
  useEffect(  ()=> {
  //zoneID is the useParams from the react-router-dom useParams. FYI I needed to rename the variable from :id to :zoneID so that this would display cleanly. you should rename it in your version in Routes.js
  //GetNodesFromMatrix grabs all nodes for the supplied zone from the matrix MySQL database. It works with 0 or 3000 and doesn't grab more than it needs to
  GetNodesFromMatrix(zoneID)
  GetZoneDescription(user.CustID, zoneID)
  }, [] );

 async function GetNodesFromMatrix (zoneID){
  //options for this call. we are seeking to get the list of meters in a zone from Matrix. 
  const options = {
  method: 'get',
  headers: {
  'culper':'culper', },
  url: `https://gbmercury.com/iaqmeter/meters/${zoneID}`,
  } 
  const response = await axios(options)
  //meterDetails stores the response from the axios call. In here we would have a list of our meter info for the zone we specified (no Airthinx data yet)
  var meterDetails=response.data
  console.log(meterDetails )
  //this .map iterates through the individual meters and gets the airthinx data for each meter. In the end we end up with a measurement set per meter in the array of meters. note: async is important here
  meterDetails.map(async(oneNode)=>
  {
  deviceName.push(oneNode)
  //note: await is important here. This doesn't the actual call for the airThinx data. I separated that out into its own function below so that logic doesn't get too complicated here. 
  const airResults=await GetMesurementsFromAirthinx(oneNode.nodeID) 
  console.log(deviceName)
  //this next line(s) any existing node information in the state variable and adds the newest node as an object ot the end. this state is an array of objects so each node is an object.
  await setAllNodeInfo(
  (existingNodes) => [
  //if any existing data exists from a previous loop in the map, it keeps it - super important since we're mapping through
  ...existingNodes,
  {
  //this combines the matrix (oneNode) data with the airthinx (airResults) data into a single object. 
  ...oneNode, ...airResults
  }
  ]
  )  } )
 }
 
 async function GetMesurementsFromAirthinx(nodeID){
  //get measurements for a single node
  const options = {
    method: 'post',
    headers: {
      'Accept':'application/json',
      'Authorization':'Bearer 301cf2e5-5ed7-40e0-bade-d91f0d2fb7ca',
      'Content-Type':'application/json'
    },
    url: 'https://api.environet.io/search/nodes',
    data: {
      'node_id': nodeID,
      "from": selectedDate, //state
      "to": selectedEndDate, // stat
    } 
  }
  console.log(options)
  const responseMeasure = await axios(options)
  //returns the data_points into an key of measurements. in the final state that is the key for the measurements array in the object 
  return {measurements:responseMeasure.data[0].data_points}
 }

 async function GetZoneDescription(User, zoneID){
  //i took this path and options from the page where we grab individual node data and show the smaller charts. that way we limit our data calls.
  const optionsz = {
    method: 'get',
    headers: {
    'culper':'culper', },
    url: `https://gbmercury.com/iaqmeter/zones/${User}`,
  
  }
  console.log(optionsz)
  const Zones = await axios(optionsz)
const myZone= Zones.data.filter(m=> m.id==zoneID)
setZone(myZone[0].name)
}

var i=0

return(
<div>
  <h4> Zone: {zone}</h4>
  <h5>Air Quality Monitors</h5>
  {deviceName.map(device=>(
  <span key={device.nodeID}>
  <button onClick={() => history.push(`/mainchart/${device.nodeID}/${device.friendlyName.split(" ").join("")}`)}
  className="border border-black p-4 rounded-md m-2">{device.friendlyName} Individual Results
          </button>
  </span>
))}
{allNodeInfo?
<>
  <DeviceCompareScatter dataType="AQ" uom="Quality Score" friendlyName="Overall Air Quality" data={allNodeInfo} maxValue={100} stepSize={5} />
  <DeviceCompareScatter dataType="CO₂" uom='ppm'  friendlyName="Carbon Dioxide" data={allNodeInfo} maxValue={2000} stepSize={100}  />
  <DeviceCompareScatter dataType="Humidity" uom="%"  friendlyName="Humidity" data={allNodeInfo} maxValue={100} stepSize={5} />
  <DeviceCompareScatter dataType="CH₂O" data={allNodeInfo}  friendlyName="Formaldehyde" uom="mg/m³" maxValue={0.1} stepSize={0.01}  />
  <DeviceCompareScatter dataType="PM2.5" uom="µg/m³"  friendlyName="Ultrafine Particulate Matter" data={allNodeInfo} maxValue={10} stepSize={1} />
  </>
  :null}
<h6 style={{color:'red', fontStyle:'italic'}}>{errorMsg}</h6>


</div>

)
}
export default DeviceCompare;