import React, {useContext} from 'react';
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import DeviceLocations from '../DeviceLocations/DeviceLocations'
import  {ClientContext} from '../../providers/ClientProvider';

const IAQDash = () => {
    const [client, setClient]=useContext(ClientContext)
    return (
        <div>
            <SectionTitle title="IAQ" subtitle="IAQ Monitor Locations" />
                <Widget title=" Airthinx Locations" description={<span>List of all the locations with the names of all the zones at those locations. Each zone can have multiple devices.
                    Some zones may have no devices added yet. If humidity, CO₂, or PM 2.5 appear red, readings have reached an excessive level for one or more of the devices in that zone, in the last 24 hours.
                </span>}>
                {client ? <DeviceLocations clientID={client}/> : null}
                </Widget>
            
        </div>
    );
}

export default IAQDash;
