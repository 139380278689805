import React, {useState , useEffect, Fragment,useContext} from 'react'

import axios from 'axios'
import moment from 'moment'
import { useSnackbar } from 'notistack'
//import { UserContext } from '../../providers/UserProvider'
import { withRouter } from 'react-router-dom';


const HygieneFinalize=({auditid, dept, history})=>{
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   
    const finalizeHandAudit = async(event) =>
    {
        event.preventDefault(); 
        const{name, value}=event.currentTarget
        const options={
          method: 'get',
         headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/handhygiene/closeout/${auditid}`,
        }
        console.log(auditid)
        const response = await axios(options)
        enqueueSnackbar('Audit Finalized',{variant: 'success',});
        history.push('/handhygienedash')
        //window.location.reload();
      
    }



useEffect(()=>{



},[])
if(auditid){
    return(
 <> 
<button className="border rounded-sm bg-yellow-700 text-white font-bold p-2 mb-2" 
onClick={(event)=> {finalizeHandAudit(event)}}> Finalize</button>
 


    </>  
    )
}
else {
    return(
<td>blank</td>
    )
    
}

}
export default withRouter( HygieneFinalize)