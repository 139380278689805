import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import {Link} from 'react-router-dom'
import  {UserContext} from "../../providers/UserProvider";

import {useParams} from 'react-router-dom'

export default function AuditSettingsPersonnelEdit (){
    const user = useContext(UserContext);
    const {empid} = useParams()
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [title, setTitle] = useState('');
    const onChangeHandler = (event) => {
      const {name, value} = event.currentTarget;
      if(name === 'FirstName') {
          setFirstName(value);
      }
      else if(name === 'LastName') {
        setLastName(value);
    }
    else if(name === 'Title') {
      setTitle(value);
  }
  };
  const [cards, setCards]=useState([])
    const updatePersonnel = async(event) =>
      {
          event.preventDefault();  
          const options =   
          {
            method: 'post',
            data: {'firstName':firstName,
                  'lastName':lastName,
                  'title':title,
                  'custodianID':empid,
                  'clientID':user.CustID},
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: 'https://gbmercury.com/audit/employee/update',
          }
          
          const response = await axios(options)
          
          console.log(response)
          
          
      }
    useEffect(()=>{
      
      const loadData = async() =>
      {
        
        const response = await axios(
          {
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/audit/employee/${empid}`
        }
        )
        //setCampusDetail(responseC.data[0])
        setFirstName(response.data[0].FirstName)
        setLastName(response.data[0].LastName)
        setTitle(response.data[0].Title)
      }
      loadData()   
      
    },[])

    return (
        <>
        <Link to={`/auditsettings-personnel`}>back to employee list</Link>
        <form >
            <label>First Name</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="FirstName"
            value = {firstName}
            onChange = {(event) => onChangeHandler(event)}
            />
            <label>Last Name</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="LastName"
            value = {lastName}
            onChange = {(event) => onChangeHandler(event)}
            />
            <label>Title</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="Title"
            value = {title}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!firstName || !lastName || !title}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {updatePersonnel(event)}}
            
            >
                Update Employee
            </button>
            

            </form>
            </>
    );
}
