import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import './chartstyles.scss'

const IsobutyleneChart=({airthinxData})=>{

    var IsobutyleneData=[]
var timestampsData=[]
var timestampDay=[]

airthinxData.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
       {
        if(Item.measurements){
        for(var i=0; i<Item.measurements.length; i++){
 
        if(Item.name==="VOC (Isobutylene)")
        {  
            IsobutyleneData.push(Item.measurements[i][1]); 
            timestampsData.push(moment(Item.measurements[i][0]).format("h:mm"))
            timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }
       }


       const dataIsobutylene = {
        labels: timestampsData  ,
        datasets: [
          { 
           
            borderColor: IsobutyleneData.map(data=>
              {if(data>6){
                  return 'red'
              }else if(data>3){
                 return '#d4b624'
              }else{
                  return 'green'
              }} ) ,
              backgroundColor: IsobutyleneData.map(data=>
                {if(data>6){
                  return 'red'
              }else if(data>3){
                 return '#d4b624'
              }else{
                  return 'rgb(39, 156, 97)'
              }} ) ,
            borderWidth: .5,
            hoverBackgroundColor: 'rgb(91, 86, 113)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:.7,
            pointRadius: 1.5,
            pointHoverRadius: 6,
            data: IsobutyleneData    }, 
      ], 
      
        };
        const minData=Math.min(...IsobutyleneData);
        const maxData=Math.max(...IsobutyleneData)
        const avgData = IsobutyleneData.reduce((a,b) => a + b, 0) / IsobutyleneData.length
          IsobutyleneData.map(function(value){
          var diff = value - avgData;
          return diff;
          
        });
        var squareDiffs = IsobutyleneData.map(function(value){
          var diff = value - avgData;
          var sqr = diff * diff;
          return sqr;
        });
        function average(data){
          var sum = data.reduce(function(sum, value){
            return sum + value;
          }, 0);
        
          var avg = sum / data.length;
          return avg;
        }
        
        var avgSquareDiff = average(squareDiffs)
        var stdDev = Math.sqrt(avgSquareDiff);

return(
<div>

{IsobutyleneData.length<1 ? (<p class="text-center m-5">No <b> VOC (Isobutylene)</b> Data for Time Period</p>)
: (<Line data={dataIsobutylene}
 options={{
    title:{
      display:true,
      text:['VOC (Isobutylene) Levels'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right' },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: 'ppm',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min: -2,
            //(Math.min.apply(Math, IsobutyleneData))-Math.round((Math.min.apply(Math, IsobutyleneData)*.01)+1), 
            max: 5
            //(Math.max.apply(Math, IsobutyleneData))+(Math.max.apply(Math, IsobutyleneData)*.01) +1 
          }   }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[IsobutyleneData.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: false,
        "autoSkipPadding": 5, } }]
  },
  tooltips:{
    intersect: false }  }}
 />)}

 {/* <div class="avgBox ">
 <p class="minmax ">{`min: ${minData} `}</p> 
 <p class="minmax ">{`avg: ${avgData.toFixed(2)}`}</p> 
 <p class="minmax ">{`max: ${maxData} `}</p> 
 <p class="minmax ">{`Standard Deviation: ${stdDev.toFixed(2)} `}</p>  
</div> */}

<div className="d-flex flex-row justify-content-around align-items-center">
{/* <table className="">
  <tbody>
  <tr className="">
    <td className="minmax" > Minimum  </td>
    <td className="minmax text-right">{minData.toFixed(2)}</td>
  </tr>
  <tr className="">
    <td className="minmax"> Average </td>
    <td className="minmax text-right">{avgData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Maximum </td>
    <td className="minmax text-right">{maxData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Standard Deviation </td>
    <td className="minmax text-right">{stdDev.toFixed(2)}</td>
  </tr>
  </tbody>
</table> */}

<div className="legend">
  <table>
    <tbody>
  <tr className="legendRow">
<td>Excessive</td> 
<td className="redBox"> { ` > 6 `}</td>
</tr>
<tr>
<td>Moderate</td> 
<td className="yellowBox">{ `> 3`}</td>
</tr>
<tr>
<td>Good</td> 
<td className="greenBox">{ ` < 3  `}</td>
</tr>
</tbody>
  </table>
</div>


</div>

</div>

)
  }

  export default IsobutyleneChart
