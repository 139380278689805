import {combineReducers} from 'redux'
import config from './config'
import leftSidebar from './left-sidebar'
import palettes from './palettes'


const rootReducer = combineReducers({
  config,
  leftSidebar,
  palettes
})

export default rootReducer
