import React, {useState , useEffect, Fragment,useContext} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'
import MyBarChart from '../../components/charts/auditatp'
import { UserContext } from '../../providers/UserProvider'
import { withRouter } from 'react-router-dom';
import {ClientContext} from '../../providers/ClientProvider'

const AuditCampusReport= ({match}) => {
  const [allATP, setAllATP]=useState()
  const user = useContext(UserContext)
  var auditSamples=[]

  function AddATP(array){
var sum=array.reduce(function(a,b){
  return a+b
})
return sum;
  }

  const [client, setClient] = useContext(ClientContext)
  const [campusState, setCampusState]= useState([])
  const [atpAverage, setAtpAverage]= useState()
  const [auditAverage, setAuditAverage]=useState(0)
  const [atpPassed, setAtpPassed]= useState(0)
  const [atpData, setAtpData]=useState([])
  const [chartAtp, setChartAtp]=useState([])
  const [questionstate, setquestionstate]=useState("test")
  const [questionSumState, setQuestionSumState]=useState([])
  const [quesResultDet, setQuesResultDet ]=useState(7)
  const [scoreResults, setScoreResults]=useState([])
  
  const [auditResultsState,setAuditResultsState]=useState()

  const [custodianState, setCustodianState]=useState()
  const [areaState, setAreaState]=useState([])
  const [yearAgo, setYearAgo]=useState(Math.round(new Date().getTime()-31556926000))
var countPassed=0
const campusid=match.params.id
  //const [mainAudit, setMainAudit]= useState()
  useEffect(()=>{
    const options=   
    {
        //coda 257 working // GOOD clebauditatps by campus id
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditatp/campus/${campusid}`, //GOOD
    }

    const options2 =   
    {
        //coda 352  working / from page auditquestions
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/audit/question/all/${client}`,
    }
    const options3 =   
    {
        //coda 10 // worked in postman
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/audit/facility/${campusid}`, //GOOD
    }

    const options4 =   
    {// coda line 21    / now owkring working postman
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditresult/campus/${campusid}`, //GOOD match
    }

    const options6 =    
    // from line 38 coda  // working in postman clebareas
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditarea/campus/${campusid}`, //GOOD
    }
    const options7 =    
    // from line  356 coda //working in postman
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditscorecard/answered/${quesResultDet}`, // GOOD i think...
    }
    const options8 =    
    //  added by Wes, summary
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditcampus/questionsummary/${campusid}`, // GOOD 
    }
    const options9 =    
    //  added by Wes, score by campus from clebauditresults
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditresult/campus/scores/${campusid}`, // GOOD 
    }

    
    const loadData = async() =>
    {
  
     axios.all([
        axios(options),
        axios(options2),
        axios(options3),
        axios(options4),
        axios(options6),
        axios(options7),
        axios(options8),
        axios(options9),
      ]).then(axios.spread(function(campusatps, questions, campus, auditResults, areas, resultQuestions, questionSumary, scoreresults) {
console.log(resultQuestions.data)
console.log(auditResults)
var area=areas.data
setquestionstate(questions)
setQuestionSumState(questionSumary)
setAuditResultsState(auditResults)
setScoreResults(scoreresults)
areaState.push(areas.data)

setCampusState(campus.data)


getAtpAverage(campusatps.data)

console.log(areaState)

console.log(campusState[0])
 campusatps.data.map(CA=>{
    atpData.push({'Raw': (CA.DateTime*1000), 'Date':moment(CA.DateTime*1000).format("MM/D/YYYY"),
    'DateTime': moment(CA.DateTime*1000).format("MM/D/YYYY h:mm"), 'custname': CA.CustodianID, 'ATP': CA.ATPResult,
  'Area': CA.AreaID, 'Campus': CA.CampusID, 'Description': CA.ATPSite, Id: CA.ID}) 
 })
console.log(area)
 Object.entries(atpData).map(([k,v]) => {
  
    const myObj={
      "Raw":v.Raw ,
      "Title":v.Date +" "+  v.Id, 
      "tagTitle": v.Date ,
      "tagTitle2":  area.filter(a=> a.AreaID===v.Area)[0].AreaName + " - " +v.Description,
      "Result":v.ATP,
      "Total":v.ATP,
    }
    chartAtp.push(myObj) // might need to use another variable name

  })
console.log(scoreresults.data)
  
getAuditAverage(scoreresults.data, campus.data)
  
setAllATP(chartAtp)

      })).catch(err=>{
        console.log(err + ' there was an error')
      })
  
    }
    loadData()   
 
  
// i think its just campusstate i need...
  function getAuditAverage(scoreResults, campusstate){
      console.log(scoreResults)
      var scoreTotal=0
      var scoreCount=0
      scoreResults.map(sr=>{
            scoreTotal+=sr.Score
            scoreCount++
        })
       
     var scoreAverage=scoreTotal/scoreCount
     setAuditAverage(scoreAverage)
     console.log(scoreTotal, scoreCount)
  }
  console.log(auditAverage)    

  function getAtpAverage(campusatps) {
      var atpTotal=0
      for(var i=0; i<campusatps.length; i++){
        atpTotal+=campusatps[i].ATPResult
        if(campusatps[i].ATPResult<=200){
                countPassed+=1
        }
      }
      var dividedatp=atpTotal/(campusatps.length+1)
     setAtpAverage(Math.round(dividedatp))
     setAtpPassed(Math.round(countPassed/campusatps.length*100))
     console.log("herererere")
}

  },[atpData])
  
console.log(allATP)
    
  if (allATP ) {
return (
  <>
  {console.log(atpData.data + 'hekjhsdkj')  }    
  {allATP.length>0 && questionSumState.data.length >0 ?
    (<>
    <SectionTitle title="Pages" subtitle="Audit Report - Campus Report " />
    <Widget title="Page title" description={<span>Audit results for facility-{ campusState[0].CampusName} </span>}>
      <p>This is a page is in progress  </p>
      <p style={{fontSize:' 20px'}}>ATP Sampling</p>
      <p>{`Average ATP: ${atpAverage} RLU`}</p>
      <p>{`Percent passing ATP samples: ${atpPassed} %`}</p>
      <p>ATP Individual sample chart</p>
      <MyBarChart chartData={allATP} />
{console.log(campusState)}
      <table className="table-auto" >
          <thead>
              <tr>
              <th style={{ width: "35%", textAlign: "center",
              paddingBottom: "2%"}} >Date/Time</th>
			  <th style={{ width: "35%", textAlign: "center",
              paddingBottom: "2%"}}  >ATP Location</th>
			 <th style={{ width: "35%", textAlign: "center", 
              paddingBottom: "2%"}}  >ATP Value (RLU)</th>
              </tr>
          </thead>
          <tbody>

          
                  {atpData.map(ad=>{
                      return(
                       <tr key={ad.Id}>
                       
                    <td style={{ width: "35%", textAlign: "center",
              paddingBottom: "2%"}} >{ad.DateTime}</td>
                    <td style={{ width: "35%", textAlign: "center",
              paddingBottom: "2%"}} >{ad.Description}{areaState.filter(r=>r.AreaID===ad.Area).AreaName}</td>
                    <td style={{ width: "35%", textAlign: "center",
              paddingBottom: "2%"}} > {ad.ATP}</td>
                    </tr>
                  )})}
                  
             
          </tbody>
      </table>
      <h4 style={{marginTop: '40px'}}>Audit Question History</h4>
      <h6> Average Audit Score: {Math.round(auditAverage)}%</h6>
      
      <table>
          <thead>
              <tr>
              <th style={{ width: "20%", textAlign: "center",
              paddingBottom: "2%"}}>Question</th>
              <th style={{ width: "20%", textAlign: "center",
              paddingBottom: "2%"}}>Pass Rate</th>
              <th style={{ width: "20%", textAlign: "center",
              paddingBottom: "2%"}}> Audit History</th>
              </tr>
          </thead>
          <tbody>

{console.log(questionSumState.data.length)}
    {questionSumState.data.map(qs=>{
        return <tr key={qs.questionID}>
            <td style={{ width: "20%", textAlign: "center",
              paddingBottom: "3%"}}>{qs.question} </td>
            <td style={{ width: "20%", textAlign: "center",
              paddingBottom: "3%"}}>{Math.round((qs.totalRight/qs.totalAsked)*100)}%</td>
            <td style={{ width: "20%", textAlign: "center",
              paddingBottom: "3%"}}>{(Math.round((qs.totalRight/qs.totalAsked)*100)>70) ? 
            "Exceeds Expectations" : "Below Expectations "  }<br></br>
            {qs.totalRight} / {qs.totalAsked} passed</td>
        </tr>
    })}

    {console.log(questionSumState.data)}
    {/* <tr>
    {auditResultsState.data.map(ARS=>{
  return<td key={ARS.ResultsID}>{ARS.QuestionID}</td>
  })} 
    </tr> */}
          </tbody>
      </table>


    </Widget>
    </>)
  : (<h4 style={{marginTop:'25%', textAlign: 'center'}}>No data for this campus </h4>) }
  </>
) }
else {
  return(
    <>
      <SectionTitle title="Pages" subtitle="Audit Report - Facility" />
      <Widget title="Page Title" description={<span>Audit results </span>}>
        
        Loading Chart Data... 
      </Widget>
    </>
)}}
export default withRouter(AuditCampusReport)
