import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Index from './pages/index'
import EmptyPage from './pages/empty-page'
import Landing from './pages/Landing/index.jsx'
import ATPAll from './pages/ATPAll/ATPAll'
import ATPFacility from './pages/ATPFacility/ATPFacility'
import ATPClient from './pages/ATPClient/ATPClient'
import ATPMonth from './pages/ATPMonth/ATPMonth'
import ATPQuarter from './pages/ATPQuarter/ATPQuarter'
import ATPYear from './pages/ATPYear/ATPYear'
import SchoolAttendance from './pages/SchoolAttendance/SchoolAttendance'
import AuditDashboard from './pages/AuditDashboard/AuditDashboard'
import AuditHistory from './pages/AuditHistory'
import AuditReportATP from './pages/AuditReportATP/AuditReportATP'
import AuditReportFacility from './pages/AuditReportFacility/AuditReportFacility'
import AuditReportPersonnel from './pages/AuditReportPersonnel/AuditReportPersonnel'
import AuditReportPersonnelDetail from './pages/AuditReportPersonnelDetail/AuditReportPersonnelDetail'
import AuditSettingsFacility from './pages/AuditSettingsFacility/AuditSettingsFacility'
import AuditSettingsPersonnel from './pages/AuditSettingsPersonnel'
import AuditSettingsQuestion from './pages/AuditSettingsQuestion'
import AuditSettingsTemplate from './pages/AuditSettingsTemplate'
import ReportEquipment from './pages/ReportEquipment'
import ReportService from './pages/ReportService'
import ResourcesLibrary from './pages/ResourcesLibrary'
import ResourcesVideo from './pages/ResourcesVideo'
import ResourcesVideoReport from './pages/ResourcesVideoReport'
import ServiceSummary from './pages/ServiceSummary'
import ServiceReport from './pages/ServiceReport'
import ReportServiceInvoice from './pages/ReportServiceInvoice'
import SignInGS from './pages/SignInGS'
import SignUp from './pages/SignUp'
import IAQDash from './pages/IAQ-dash'
import DeviceCompare from './pages/DeviceCompare/Devicecompare'
import AirThinxChart from './pages/airthinxchart'
import { UserContext } from './providers/UserProvider'
import AuditSettingsArea from './pages/AuditSettingsArea'
import AuditSettingsAreaEdit from './pages/AuditSettingsAreaEdit'
import AuditSettingsPersonnelEdit from './pages/AuditSettingsPersonnelEdit'
import ChangeOffice from './pages/ChangeOffice/ChangeOffice'
import ChangeClient from './pages/ChangeClient/ChangeClient'
import AuditSettingsQuestionEdit from './pages/AuditSettingsQuestionEdit'
import AuditSettingsTemplateEdit from './pages/AuditSettingsTemplateEdit'
import AuditReportCard from './pages/AuditReportCard/AuditReportCard'
import AuditScorecard from './pages/AuditScorecard/AuditScorecard'
import AuditCampusReport from './pages/AuditCampusReport/AuditCampusReport'
import AuditAreasReport from './pages/AuditAreasReport/AuditAreasReport'
import AuditFacilityScorecard from './pages/AuditSettingsFacility/AuditSettingsFacility'
import AuditAreasScorecard from './pages/AuditAreasScorecard/AuditAreasScorecard'
import HandHygieneDash from './pages/HandHygiene/HandHygieneDash'
import HandHygieneAudit from './pages/HandHygiene/HandHygieneAudit'
import HandHygieneReport from './pages/HandHygiene/HandHygieneReport'
import HandHygieneChart from './pages/HandHygiene/HandHygieneChart'

const Routes = () => {
    const user = useContext(UserContext)
    return (
        <Switch>
            <Route path='/service-summary/:orderID'>
                <ServiceSummary />
            </Route>
            <Route path='/service-report/:orderID'>
                <ServiceReport />
            </Route>
            <Route path='/atp-all'>
                <ATPAll />
            </Route>
            <Route path='/atp-facility'>
                <ATPFacility />
            </Route>
            <Route path='/atp-client'>
                <ATPClient />
            </Route>

            <Route path='/atp-month'>
                <ATPMonth />
            </Route>
            <Route path='/atp-quarter'>
                <ATPQuarter />
            </Route>
            <Route path='/atp-year'>
                <ATPYear />
            </Route>
            <Route path='/school-attendance'>
                <SchoolAttendance />
            </Route>
            <Route path='/audit-dashboard'>
                <AuditDashboard />
            </Route>
            <Route path='/audit-history'>
                <AuditHistory />
            </Route>
            <Route path='/auditreport-atp'>
                <AuditReportATP />
            </Route>
            <Route path='/auditreport-facility'>
                <AuditReportFacility />
            </Route>
            <Route path='/reportservice-invoice/:invNum'>
                <ReportServiceInvoice />
            </Route>
            <Route path='/auditreport-personnel-detail/:custodianID'>
                <AuditReportPersonnelDetail />
            </Route>
            <Route path='/auditreport-personnel'>
                <AuditReportPersonnel />
            </Route>
            <Route path='/auditsettings-facility'>
                <AuditSettingsFacility />
            </Route>
            <Route path='/auditsettings-personnel'>
                <AuditSettingsPersonnel />
            </Route>
            <Route path='/auditsettings-personneledit/:empid'>
                <AuditSettingsPersonnelEdit />
            </Route>
            <Route path='/auditsettings-template'>
                <AuditSettingsTemplate />
            </Route>
            <Route path='/auditsettings-area/:campusid'>
                <AuditSettingsArea />
            </Route>
            <Route path='/auditscorecard/:auditid'>
                <AuditScorecard />
            </Route>
            <Route path='/auditreportcard/:auditid'>
                <AuditReportCard />
            </Route>
            <Route path='/change-office'>
                <ChangeOffice />
            </Route>
            <Route path='/change-account'>
                <ChangeClient />
            </Route>
            <Route path='/auditsettings-areadetail/:areaid'>
                <AuditSettingsAreaEdit />
            </Route>
            <Route path='/reports-equipment'>
                <ReportEquipment />
            </Route>
            <Route path='/reports-services'>
                <ReportService />
            </Route>
            <Route path='/resources-library'>
                <ResourcesLibrary />
            </Route>
            <Route path='/resources-video'>
                <ResourcesVideo />
            </Route>
            <Route path='/video-report'>
                <ResourcesVideoReport />
            </Route>
            <Route path='/auditsettings-question'>
                <AuditSettingsQuestion />
            </Route>
            <Route path='/auditsettings-questionedit/:qid'>
                <AuditSettingsQuestionEdit />
            </Route>
            <Route path='/auditsettings-templateedit/:templateid'>
                <AuditSettingsTemplateEdit />
            </Route>
            <Route path='/landing'>
                <Landing />
            </Route>
            <Route path='/iaq-dash'>
                <IAQDash />
            </Route>
            <Route path='/devicecompare/:zoneID'>
                <DeviceCompare />
            </Route>
            <Route path='/mainchart/:id/:name'>
                <AirThinxChart />
            </Route>
            <Route path='/campusreport/:id'>
                <AuditCampusReport />
            </Route>
            <Route path='/auditareasreport/:id'>
                <AuditAreasReport />
            </Route>
            <Route path='/auditfacilityscorecard/:id/:campusid'>
                <AuditFacilityScorecard />
            </Route>
            <Route path='/auditareascorecard/:id/:campusid'>
                <AuditAreasScorecard />
            </Route>
            <Route path='/handhygienedash'>
                <HandHygieneDash />
            </Route>
            <Route path='/handhygieneaudit/:id/:deptid'>
                <HandHygieneAudit />
            </Route>
            <Route path='/handhygienereport/:id'>
                <HandHygieneReport />
            </Route>
            {/* <Route path='/handhygienechart/'>
                <HandHygieneChart />
            </Route> */}
            <Route path='/empty-page'>
                <EmptyPage />
            </Route>
            <Route exact path='/'>
                {user.uid ? <Redirect to='/landing' /> : <SignInGS />}
            </Route>
            <Route component={Index} />
        </Switch>
    )
}
export default Routes
