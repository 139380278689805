import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import './chartstyles.scss'

const AirQualityChart=({airthinxData})=>{

var AQData=[]
var timestampsData=[]
var timestampDay=[]

airthinxData.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
       {
        if(Item.measurements){ 
        for(var i=0; i<Item.measurements.length; i++){
 
        if(Item.name==="AQ")
        {
            AQData.push(Item.measurements[i][1]);
            timestampsData.push(moment(Item.measurements[i][0]).format(" h:mm"))
            timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }
       }


       const dataAQ = {
        labels: timestampsData ,
        datasets: [
          { 
           
            borderColor: 'black',
            backgroundColor: 'white',
            borderWidth: .5,
            hoverBackgroundColor: 'rgb(91, 86, 113)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:.7,
            pointRadius:1.5,
            pointHoverRadius: 6,
            data: AQData,
          fill: false    }, 
      ], 
      
        };

const minData=Math.min(...AQData);
const maxData=Math.max(...AQData)
const avgData = AQData.reduce((a,b) => a + b, 0) / AQData.length
var diffs = AQData.map(function(value){
  var diff = value - avgData;
  return diff;
  
});
var squareDiffs = AQData.map(function(value){
  var diff = value - avgData;
  var sqr = diff * diff;
  return sqr;
});
function average(data){
  var sum = data.reduce(function(sum, value){
    return sum + value;
  }, 0);

  var avg = sum / data.length;
  return avg;
}

var avgSquareDiff = average(squareDiffs)
var stdDev = Math.sqrt(avgSquareDiff);


console.log(diffs)
console.log(squareDiffs)
return(
<div>

{AQData.length<1 ?(<p class="text-center m-5">No <b> Air Quality</b> Data for Time Period</p>): ( <Line data={dataAQ}
 options={{
    title:{
      display:true,
      text:['Air Quality Levels'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right' },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: '',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min: 0,
            
            max: Math.round(avgData*2)
           
           }  }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[AQData.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: false,
        "autoSkipPadding": 5,
       },

    
    }]
  },
  tooltips:{
    intersect:false }  }}
 />)}



 {/* <div class="d-flex flex-row justify-content-around align-items-center">
<table class="">
  <tr class="">
    <td class="minmax" > Minimum  </td>
    <td class="minmax text-right">{minData.toFixed(2)}</td>
  </tr>
  <tr class="">
    <td class="minmax"> Average </td>
    <td class="minmax text-right">{avgData.toFixed(2)}</td>
  </tr>
  <tr>
    <td class="minmax"> Maximum </td>
    <td class="minmax text-right">{maxData.toFixed(2)}</td>
  </tr>
  <tr>
    <td class="minmax"> Standard Deviation </td>
    <td class="minmax text-right">{stdDev.toFixed(2)}</td>
  </tr>
</table>

</div> */}

</div>

)
  }

  export default AirQualityChart
