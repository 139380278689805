import React, {useState, useEffect} from 'react';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes'

export default function PolarChart (props) {
  const [videoData, setVideoData]=useState([])
  const [videoLabels, setVideoLabels]=useState([])
  const [barData, setBarData]=useState([])
    
    useEffect(()=>{
    var countArr=[]
    var labelArr=[]
    var barBuilder=[]
    console.log(props.data)
    props.data.map((e)=>{
        countArr.push(e.playCount)
        labelArr.push(e.videoName)
        barBuilder.push({
          label: e.videoName,
          backgroundColor: '#2ecc71',
          borderColor: '#2ecc71',
          borderWidth: 1,
          data: e.playCount,
        })
      })
      setBarData(barBuilder)
    setVideoData(countArr)
    setVideoLabels(labelArr)
    },[])
    
    var BarData = {
  // datasets:[{
  // labels:videoLabels,
  // backgroundColor: [
  //   "#2ecc71",
  //   "#3498db",
  //   "#95a5a6",
  //   "#9b59b6",
  //    "#f1c40f",
  //    "#e74c3c",
  //    "#34495e"
  // ],
  //   data:videoData
  // }],
  
}
var BarOptions= {
  title: {
    display: true,
    text: 'Watch Count By Video',
    fontSize: 20, 
    fontColor: 'rgb(55, 61, 64)'
    },
    legend: {
      position:'bottom',
    },
  responsive: true,
  maintainAspectRatio:false,
  scales: {      
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Video',
        fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
      // type: 'time',
      // time: {
      //     unit: 'day'
      // }
  }],
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Play Count',
        fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
      ticks: {
        stepSize: 1,
        

      }
    }],
    plugins: {
      
      
      colorschemes: {
          scheme: 'brewer.Paired12'
      }
  }
  }
}
    return <div style={{height:'400px'}} className='mx-8'>
      {BarData? <Bar options={BarOptions} data={BarData} /> :null}
      </div>
}
