import React, { useEffect, useState, useContext } from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import MyBarChart from '../../components/charts/am5charts-trend-atp'
import axios from 'axios'
import moment from 'moment'
import { ClientContext } from '../../providers/ClientProvider'
import { CSVLink} from 'react-csv'

function ATPQuarter() {
    // eslint-disable-next-line
    const [client, setClient] = useContext(ClientContext)
    const [allATP, setAllATP] = useState()
    const [csvExport, setCSVExport] = useState({})
    var quarterData = []
    var quarterCount = []
    var quarterRaw = []
    var quarterFinal = []
    var dataResult = []
    useEffect(() => {
        const options = {
            method: 'get',
            headers: {
                Accept: 'application/json',
                culper: 'culper',
                'Content-Type': 'application/json',
            },
            url: `https://gbmercury.com/atps/gs/indiv/customer/${client}`,
        }
        const loadData = async () => {
            var csvBuilder = []
            var tempVal = 0
            const response = await axios(options)
            dataResult = response.data
            dataResult.sort((a, b) => (a.ATPDate > b.ATPDate ? 1 : -1))
            dataResult.map((e) => {
                e.ATPDate = e.ATPDate * 1000
                e.formattedDate = moment(e.ATPDate).format(
                    'MM/DD/YYYY hh:mm:ss a'
                )
                e.monthYear = moment(e.ATPDate).format('MM/YYYY')
                e.quarterYear = moment(e.ATPDate).format('Q/YYYY')
                e.Year = moment(e.ATPDate).format('YYYY')
                !isNaN(quarterData[e.quarterYear])
                    ? (tempVal = quarterData[e.quarterYear])
                    : (tempVal = 0)
                quarterData[e.quarterYear] = e.ATPVal + tempVal
                !isNaN(quarterCount[e.quarterYear])
                    ? (tempVal = quarterCount[e.quarterYear])
                    : (tempVal = 0)
                quarterCount[e.quarterYear] = tempVal + 1
                quarterRaw[e.quarterYear] = e.ATPDate
            })
            Object.entries(quarterCount).map(([k, v]) => {
                var average = Math.round(quarterData[k] / v)
                const myObj = {
                    Raw: quarterRaw[k],
                    Title: k,
                    Average: average,
                    Total: quarterData[k],
                    Count: v,
                }
                csvBuilder.push({
                    'Quarter/Year': k,
                    'ATP Value': average,
                })
                quarterFinal.push(myObj)
            })
            //not needed for some reason? reverses the sort
            //quarterFinal.sort((a,b)=> a.raw > b.raw ? 1 : -1)
            setCSVExport(csvBuilder)
            setAllATP(quarterFinal)
        }
        if(client>0){
        loadData()
        }
    }, [client])
    return (
        <>
        { allATP ? 
            <>
            <SectionTitle title='Trend Reports' subtitle='Monthly Trends' />
            <Widget
                title='Month Chart'
                description={
                    <span>See a chart with averages by quarter</span>
                }
            >
                <MyBarChart chartData={allATP} />
                <CSVLink
                    data={csvExport}
                    filename={'atp-average-by-quarter.csv'}
                >
                    Download records to CSV
                </CSVLink>
            </Widget>
            </>
            :
            null
        }
        </>
        
    )
}
export default ATPQuarter
