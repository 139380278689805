import React, {useContext} from 'react'
import Section from '../../components/dashboard/section'
import ReactPlayer from 'react-player'
import moment from 'moment'
import axios from 'axios'

export default function VideoComponent  (props) {
  const RecordVideoInfo = (video, user)=>{
    const options = {
      method: 'post',
      data: {'userID':user.userid,
            'username':user.username,
            'dateTime':moment().unix(),
            'videoName':video.videoName,
            'clientID':user.clientID},
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: 'https://gbmercury.com/video/log',
    }
    if(user.isGBEmployee<1)
    {
      const response = axios(options)
      console.log(response)
    }
  }
  return(
  <>
    <div className="w-full md:w-1/3">
          <Section
            title={props.video.videoName}
            description={props.video.videoDescription}
            >
            <div className="flex flex-row w-full">
               <ReactPlayer url={props.video.videoUrl} controls={true}
               onStart = {() => RecordVideoInfo(props.video, props.user)}
               /> 
            </div>
          </Section>
        </div>
        

  </>
)}


