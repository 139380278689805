import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import './chartstyles.scss'

const PmTenChart=({airthinxData})=>{

var PMTenData=[]
var timestampsData=[]
var timestampDay=[]

airthinxData.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
       {
        
        if(Item.measurements){
        for(var i=0; i<Item.measurements.length; i++){
 

        if(Item.name==="PM10")
        {
            PMTenData.push(Item.measurements[i][1]);
            timestampsData.push(moment(Item.measurements[i][0]).format("h:mm"))
            timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }
       }


       const dataPMTen = {
        labels: timestampsData  ,
        datasets: [
          { 
            
            borderColor: 'black',
            backgroundColor: 'white',
            borderWidth: .5,
            hoverBackgroundColor: 'rgb(91, 86, 113)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:.7,
            pointRadius:1.5,
            pointHoverRadius: 6,
            data: PMTenData,
          fill:false    }, 
      ], 
      
        };
        const minData=Math.min(...PMTenData);
        const maxData=Math.max(...PMTenData)
        const avgData = PMTenData.reduce((a,b) => a + b, 0) / PMTenData.length
        PMTenData.map(function(value){
          var diff = value - avgData;
          return diff;
          
        });
        var squareDiffs = PMTenData.map(function(value){
          var diff = value - avgData;
          var sqr = diff * diff;
          return sqr;
        });
        function average(data){
          var sum = data.reduce(function(sum, value){
            return sum + value;
          }, 0);
        
          var avg = sum / data.length;
          return avg;
        }
        
        var avgSquareDiff = average(squareDiffs)
        var stdDev = Math.sqrt(avgSquareDiff);


return(
<div>

{PMTenData.length<1 ? (<p className="text-center m-5">No <b> PM10 </b> Data for Time Period</p>): ( <Line data={dataPMTen}
 options={{
    title:{
      display:true,
      text:['PM10 Levels'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right' },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: 'µg/m³',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min: 0,

            max: maxData>30? (Math.round(maxData+ maxData*.5)):(35)
           
          }   }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[PMTenData.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: false,
        "autoSkipPadding": 5, } }]
  },
  tooltips:{
    intersect: false }  }}
 />)}


 {/* <div class="avgBox ">
 <p class=" minmax">{`min: ${minData} `}</p> 
 <p class=" minmax">{`avg: ${avgData.toFixed(2)}`}</p> 
 <p class="minmax ">{`max: ${maxData} `}</p> 
 <p class="minmax ">{`Standard Deviation: ${stdDev.toFixed(2)} `}</p>  
</div> */}

<div className="d-flex flex-row justify-content-around align-items-center">
{/* <table className="">
  <tbody>
  <tr className="">
    <td className="minmax" > Minimum  </td>
    <td className="minmax text-right">{minData.toFixed(2)}</td>
  </tr>
  <tr className="">
    <td className="minmax"> Average </td>
    <td className="minmax text-right">{avgData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Maximum </td>
    <td className="minmax text-right">{maxData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Standard Deviation </td>
    <td className="minmax text-right">{stdDev.toFixed(2)}</td>
  </tr>
  </tbody>
</table> */}



</div>

</div>

)
  }

  export default PmTenChart
