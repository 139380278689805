import React, {useState, useContext, useEffect} from 'react'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import { FiMenu} from 'react-icons/fi'
import Dropdown1 from './dropdown-1'
import axios from 'axios'
// import Dropdown2 from './dropdown-2'
// import Dropdown3 from './dropdown-3'
// import Dropdown4 from './dropdown-4'
import Dropdown5 from './dropdown-5'
// import Dropdown6 from './dropdown-6'
// import Search from './search'
import '../../css/components/navbar.css'
import  {ClientContext} from "../../providers/ClientProvider"

const Navbar = () => {
  const [client, setClient] = useContext(ClientContext)
  const [clientDetail, setClientDetail]=useState()
  const {config} = useSelector(
    state => ({
      config: state.config
    }),
    shallowEqual
  )
  useEffect(()=>{
    const loadData = async() =>
    {
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/customer/id/${client}`
      }
      )
      setClientDetail(response.data[0])
    }
    loadData()   
    }
    ,[client])
  let {/*rightSidebar,*/ collapsed} = {...config}
  const dispatch = useDispatch()
  return (
    <div className="navbar navbar-1 border-b">
      <div className="navbar-inner w-full flex items-center justify-start">
        <button
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed
            })
          }
          className="mx-4">
          <FiMenu size={20} />
        </button>
        {/* <Search /> */ /*search bar*/}

        {/* <Dropdown6 /> this is the explore tab from the template*/}
        {/* <span className="ml-auto"></span> */}
        
        {/* <Dropdown2 /> this is the country selector */}
        {clientDetail?
        clientDetail.CustomerName:<>nooo</>}
        <Dropdown1 /> {/*apps selector*/ }
        {/* <Dropdown4 /> */}{/*project status */ }
        {/* <Dropdown3 /> */}{/*notifications*/ }
        <Dropdown5 />{/*user dropdown*/ }

        {/* <button
          className="btn-transparent flex items-center justify-center h-16 w-8 mx-4"
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'rightSidebar',
              value: !rightSidebar
            })
          }>
          <FiSettings size={18} />
        </button> */}
        {/*layout selector*/ }
        
      </div>
    </div>
  )
}

export default Navbar
