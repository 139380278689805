import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom';

export default function Question (props){
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(UserContext)
    const [questionResult, setQuestionResult]=useState({})
    const [score, setScore]=useState(0)
    const [notes, setNotes]=useState("")
    const [answered, setAnswered]=useState(0)
    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'Notes') {
            setNotes(value);
        }
        else if(name === 'Score') {
          if(event.target.checked)
          {
            setScore(value);
          }
        }
    }           
    const createScore = async(event) =>
    {
        event.preventDefault();  
        const rightNow=moment().unix()
        console.log(rightNow)
        const options={
          method: 'post',
          data: {
              'areaid':props.areaid,    
              'auditresultsid':props.auditid,
              'campusid':props.campusid,
              'clientid':user.CustID,
              'custodianid':props.custodianid,
              'datetime':rightNow,
              'notes':notes,
              'passfail':score,
              'questionid':props.question.QuestionID
              },
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/auditscorecard/answered/create',
        }
        console.log(options)
        const response = await axios(options)
        setAnswered(response.data.insertId)
        console.log(response)
        enqueueSnackbar('Score Updated',{variant: 'success',});
    }
    const updateScore = async(event) =>
    {
        event.preventDefault();  
        const rightNow=moment().unix()
        console.log(rightNow)
        const options={
          method: 'post',
          data: {'resultsid':answered,
                'datetime':rightNow,
                'passfail':score,
                'notes':notes},
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/auditscorecard/answered/update',
        }
        console.log(options)
        const response = await axios(options)
        console.log(response)
        enqueueSnackbar('Score Updated',{variant: 'success',});
    }
   
    
    useEffect(()=>{
    
        const loadData = async() =>
        {
          const options={
            method: 'post',
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
              data: {'questionid':props.question.QuestionID,
                'auditid':props.auditid,
                },
            url: `https://gbmercury.com/auditscorecard/answered/question`
          }
            const responseAns = await axios(options)
            
          setQuestionResult(responseAns.data[0])
         if(responseAns.data[0]){
             setAnswered(responseAns.data[0].ResultsID)
             setNotes(responseAns.data[0].Notes)
             if(responseAns.data[0].PassFail>0){
                setScore(responseAns.data[0].PassFail)
             }
        }
         
         
      }
      loadData() 
    }
    
    ,[])
    return (
        <div className="p-4">
            {props.question.QuestionID}
        <div className="text-lg">{props.question.Question}</div>
        <div className="text-sm">{props.question.Detail}</div>
        <form>
     
            <label class="inline-flex items-center bg-blue-100 p-3">
      <input type="radio" className="form-radio" name="Score" value="0" onChange = {(event) => onChangeHandler(event)} checked={score == 0}/>
      <span className="ml-2">Not Scored</span>
    </label>
    <label className="inline-flex items-center bg-red-100 p-3">
      <input type="radio" className="form-radio" name="Score" value="2" onChange = {(event) => onChangeHandler(event)} checked={score == 2}/>
      <span className="ml-2">Needs Improvement</span>
    </label>
    <label className="inline-flex items-center bg-green-100 p-3">
      <input type="radio" className="form-radio" name="Score" value="1" onChange = {(event) => onChangeHandler(event)} checked={score == 1}/>
      <span className="ml-2">Meets Expectations</span>
    </label>
    <label className="inline-flex items-center bg-green-400 p-3">
      <input type="radio" className="form-radio" name="Score" value="3" onChange = {(event) => onChangeHandler(event)} checked={score == 3}/>
      <span className="ml-2">Exceeds Expectations</span>
    </label>
            <input 
            className="w-full text-lg p-3 mb-2 bg-grey-200"
            placeholder="type notes here"
            name="Notes" type="text" 
            value={notes}
            onChange = {(event) => onChangeHandler(event)}
            />
            {
            answered>0 ?
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {updateScore(event)}}
            >
                Update Scoring</button>
            :
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {createScore(event)}}
            >
                Create Scoring</button>
            }
        </form>
<hr className="my-4"/>


        </div>
    );
}
