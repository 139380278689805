import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'
//import * as ChartAnnotation from 'chartjs-plugin-annotation';
import './chartstyles.scss'
import { red } from '@material-ui/core/colors';
import {
  ma, dma, ema, sma, wma
} from 'moving-averages'

const Co2Chart=({airthinxData})=>{

var CO2Data=[]
var CO2Label=[]
var timestampsData=[]
var timestampDay=[]

airthinxData.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
          {
       if(Item.measurements){
        for(var i=0; i<Item.measurements.length; i++){

        if(Item.name==="CO₂")
        {
           CO2Data.push(Item.measurements[i][1]);
           CO2Label.push(Item.measurements[i][1] +"ppm");
        timestampsData.push(moment(Item.measurements[i][0]).format("h:mm")) 
       timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }                
       }
    
    



       const dataCo2= {
        labels: timestampsData  ,
        datasets: [
          { 
            borderColor: CO2Data.map(data=>
              {if(Math.round(data)>1100){
                return 'red'
            }else if(data>850 && data<1099){
               return '#d4b624'
            }else if(data>0 && data<850){
              return 'green'
            }
            else{
                return 'green'
               
            }} )
               ,
            backgroundColor: CO2Data.map(data=>
              {if(Math.round(data)>1100){
                return 'red'
            }else if(data>850 && data<1099){
               return '#d4b624'
            }else if(data>0 && data<850){
              return 'green'
            }
            else{
                return 'green'
               
            }} ) , 
            borderWidth: .5,
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:7,
            pointRadius:1.5,
            pointHoverRadius: 6,
           
            data: CO2Data  ,
            fill:false
          }, 
            
      ], 
      
        };
console.log(CO2Data)
        const minData=Math.min(...CO2Data);
        const maxData=Math.max(...CO2Data)
        const avgData = CO2Data.reduce((a,b) => a + b, 0) / CO2Data.length
         CO2Data.map(function(value){
          var diff = value - avgData;
          return diff;
          
        });
        var squareDiffs = CO2Data.map(function(value){
          var diff = value - avgData;
          var sqr = diff * diff;
          return sqr;
        });
        function average(data){
          var sum = data.reduce(function(sum, value){
            return sum + value;
          }, 0);
        
          var avg = sum / data.length;
          return avg;
        }
        
        var avgSquareDiff = average(squareDiffs)
        var stdDev = Math.sqrt(avgSquareDiff);

return(
<div>
{CO2Data.length<1 ?  (<p className="text-center m-5">No <b>CO₂ </b> Data for Time Period</p>) 
:(<Line data={dataCo2}
 options={{
    title:{
      display:true,
      text:['CO₂ Levels'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right',
  labels:{ 
    fontColor: 'green', fontSize: 16, text: 'Moderate'
  } },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: 'ppm',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min:0,
            // (Math.min.apply(Math, CO2Data))-Math.round((Math.min.apply(Math, CO2Data)*.01)+1), 
            max: maxData>1200? (Math.round(maxData+ maxData*.1)):(1200)  },
            // (Math.max.apply(Math, CO2Data))+(Math.max.apply(Math, CO2Data)*.01) +1 },
          beginAtZero:true   }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[CO2Data.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: true,
        "autoSkipPadding": 5, } }],
      
  },



  tooltips:{
    intersect: false }  }   }
 />)}



<div className="d-flex flex-row justify-content-around align-items-center">


<div className="legend">
  <table>
  <tbody>
  <tr className="legendRow">
<td>Excessive</td> 
<td className="redBox"> { ` > 1100  `}</td>
</tr>
<tr>
<td>Moderate</td> 
<td className="yellowBox">{ ` > 850  `}</td>
</tr>
<tr>
<td>Good</td> 
<td className="greenBox">{ ` < 850  `}</td>
</tr>
</tbody>
</table>
</div>

</div>

</div>

)
  }

  export default Co2Chart
