import React, {useState, useContext, useEffect} from 'react'


export default function AtpSingle (props){
    
    return (
        <div className="flex flex-row my-2">
        <div className="flex-1 text-lg">Sample Location: {props.atp.Location}</div>
        <div className="flex-1 text-md">{props.atp.formattedDate}</div>
        <div className="flex-1 text-sm">Sample Value: {props.atp.ATPVal} RLU</div>
        
        
        <button className="bg-red-300 text-white font-bold p-3 rounded-md" onClick={()=>props.toRemoveIt(props.atp.ATPID)}>Remove</button>
        
        </div>
    );
}
