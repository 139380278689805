import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import './chartstyles.scss'

const HumidityChart=({airthinxData})=>{

var HumidityData=[]
var timestampsData=[]
var timestampDay=[]

airthinxData.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
       {
        if(Item.measurements){
        for(var i=0; i<Item.measurements.length; i++){
 

        if(Item.name==="Humidity")
        {
            HumidityData.push(Item.measurements[i][1]);
            timestampsData.push(moment(Item.measurements[i][0]).format(" h:mm"))
            timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }
       }


       const dataHumidity= {
        labels: timestampsData  ,
        datasets: [
          { 
           
            borderColor: HumidityData.map(data=>
              {if(data>65 || data< 30){
                return 'red'
            }else if(data<60 || data>45){
               return '#d4b624'
            }else{
                return 'green'
            }} ) ,
            backgroundColor: HumidityData.map(data=>
              {if(data>65 || data< 30){
                return 'red'
            }else if(data<60 || data>45){
               return '#d4b624'
            }else{
                return 'green'
            }} ) ,
            borderWidth: .5,
            hoverBackgroundColor: 'rgb(91, 86, 113)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:.7,
            pointRadius: 1.5,
            pointHoverRadius: 6,
            data: HumidityData,
          fill: false    }, 
      ], 
      
        };

        const minData=Math.min(...HumidityData);
        const maxData=Math.max(...HumidityData)
        const avgData = HumidityData.reduce((a,b) => a + b, 0) / HumidityData.length
          HumidityData.map(function(value){
          var diff = value - avgData;
          return diff;
          
        });
        var squareDiffs = HumidityData.map(function(value){
          var diff = value - avgData;
          var sqr = diff * diff;
          return sqr;
        });
        function average(data){
          var sum = data.reduce(function(sum, value){
            return sum + value;
          }, 0);
        
          var avg = sum / data.length;
          return avg;
        }
        
        var avgSquareDiff = average(squareDiffs)
        var stdDev = Math.sqrt(avgSquareDiff);

return(
<div>
{console.log(dataHumidity.datasets.data)}
{HumidityData.length<1? (<p className="text-center m-5">No <b>Humidity</b> Data for Time Period</p>) 
: (<Line data={dataHumidity}
 options={{
    title:{
      display:true,
      text:['Humidity'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right' },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: '%',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min: 0,
            //(Math.min.apply(Math, HumidityData))-Math.round((Math.min.apply(Math, HumidityData)*.01)+1), 
            max: 75,
            //(Math.max.apply(Math, HumidityData))+(Math.max.apply(Math, HumidityData)*.01) +1 }  
          }}],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[HumidityData.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: false,
        "autoSkipPadding": 5, } }]
  },
  annotation: {
    annotations: [{
       
       id: 'hline3',
       type: 'line',
       mode: 'horizontal',
       scaleID: 'y-axis-0',
       value: 45,
       borderColor: '#d4b624',
       borderWidth: .75,
       color: '#d4b624',
       label: {
          backgroundColor: "#d4b624",
          content: "Moderate <45",
         
          enabled: false
       }
    },{
       
      id: 'hline5',
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: 60,
      borderColor: '#d4b624',
      borderWidth: .75,
      color: '#d4b624',
      label: {
         backgroundColor: "#d4b624",
         content: "Moderate >60",
        
         enabled: false
      }
   }, {
       id: 'hline2',
       type: 'line',
       mode: 'horizontal',
       scaleID: 'y-axis-0',
       value: 30,
       borderColor: 'red',
       borderWidth: .75,
       label: {
          backgroundColor: "red",
          content: "Bad <30",
          enabled: false,
          
       }
    }, {
      id: 'hline4',
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: 65,
      borderColor: 'red',
      borderWidth: .75,
      label: {
         backgroundColor: "red",
         content: "Bad >65",
         enabled: false,
         
      }
   }]
 },
  
  tooltips:{
    intersect: false }  }}
 />)}



<div className="d-flex flex-row justify-content-around align-items-center">
{/* <table className="">
  <tbody>
  <tr className="">
    <td className="minmax" > Minimum  </td>
    <td className="minmax text-right">{minData.toFixed(2)}</td>
  </tr>
  <tr className="">
    <td className="minmax"> Average </td>
    <td className="minmax text-right">{avgData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Maximum </td>
    <td className="minmax text-right">{maxData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Standard Deviation </td>
    <td className="minmax text-right">{stdDev.toFixed(2)}</td>
  </tr>
  </tbody>
</table> */}

<div className="legend">
  <table>
    <tbody>
  <tr className="legendRow">
<td>Excessive</td> 
<td className="redBox"> { ` >65 or <30  `}</td>
</tr>
<tr>
<td>Moderate</td> 
<td className="yellowBox">{ ` >45 or <60 `}</td>
</tr>
<tr>
<td>Good</td> 
<td className="greenBox">{ ` >45 or <60  `}</td>
</tr>
</tbody>
  </table>
</div>

</div>

</div>

)
  }

  export default HumidityChart
