import React, {useState, useContext} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import moment from 'moment';
import { CSVLink } from 'react-csv'
import { useSnackbar } from 'notistack'

export default function ReportEquipment(){
  // eslint-disable-next-line
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(UserContext);
  const serviceType=['none','GermBlast Service', 'GermBlast Wash']
  const [searchTerm, setSearchTerm] = useState('');
  const [cards, setCards] = useState('');
  const [equipCSV, setEquipCSV] = useState();
  const [equip, setEquip] = useState('');
  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
  
    if(name === 'SearchTerm') {
        setSearchTerm(value);
    }
};
const searchEquip = async(event) =>
    {
        var equipBuilder=[]
        event.preventDefault();  
        const options =   
        {
          method: 'post',
          data: {'searchterm':searchTerm,
          clientid:user.CustID
                  },
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/equipment/search',
        }
        
        const response = await axios(options)
        if(response.data.length>0){
          
        response.data.map((e) => {
          var singleE={}
          singleE.Barcode = e.Barcode
          singleE.Serial = e.SerialNumber
          singleE.Equipment = e.ItemDescription
          singleE.Location = e.Location
          if(e.SvcType==2){
               singleE.service = "GermBlast Wash"
          }
          else
          {
            singleE.service = "GermBlast Service"  
          }
          singleE.Date=moment(e.DateTime*1000).format("M/D/YYYY")
          equipBuilder.push(singleE)
          
        })
        setEquipCSV(equipBuilder)
        setCards(response.data)
        setEquip(response.data[0])
        enqueueSnackbar('Equipment Search Complete',{variant: 'info',});
      }
      else{
        enqueueSnackbar('Zero Search Results',{variant: 'error',});
      }
        
    }
  
return(
  <>
    <SectionTitle title="Reports" subtitle="Equipment History" />
    <Widget title="Page title" description={<span>Search a GermBlast barcode number to see the service history</span>}>
    <form >
            <label>Search Barcode</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="SearchTerm"
            value = {searchTerm}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!searchTerm}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {searchEquip(event)}}
            
            >
                Run Search
            </button>
            

    </form>
            {
              cards?
              <>
            <span className="text-xl">Equipment Information</span>
            <table>
                <thead>
                  <tr>
                    <th className="p-3 bg-grey-200">Barcode</th>
                    <th className="p-3 bg-grey-200">Item</th>
                    <th className="p-3 bg-grey-200">Serial Number</th>
                  </tr>
                </thead>
                <tbody>
                  <td className="p-3">{equip.Barcode}</td>
                  <td className="p-3">{equip.ItemDescription}</td>
                  <td className="p-3">{equip.SerialNumber}</td>
                </tbody>
                </table>
              <span className="text-xl">Service History</span>

              <table>
                <thead>
                  <tr>
                    <th  className="p-3 bg-grey-200">Date of Service</th>
                    <th className="p-3 bg-grey-200">Service Type</th>
                  </tr>
                </thead>
                <tbody>
              {cards.map((e, index)=>(
                <tr key={index}>
                  <td className="p-3">{moment(e.DateTime*1000).format("M/D/YYYY")}</td>
                  <td className="p-3">{serviceType[e.SvcType]}</td>
                </tr>
                ))}
                </tbody>
              </table>
              
              <CSVLink data={equipCSV} filename={'equipment-service-export.csv'}>
              Download records to CSV
              </CSVLink>
              </>
              :
              <span className="text-xl">Service History</span>
            }
    </Widget>
  </>
)}
