import React, {useEffect, useState, useContext} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import Datatable from '../../components/datatableservices'
import moment from 'moment'
import { useTable } from 'react-table'
import ServiceDates from '../../components/servicedates'
import ServiceInvoices from '../../components/serviceinvoices'
import { Link } from 'react-router-dom'
import {ClientContext} from '../../providers/ClientProvider'
function ReportService () {
  const[serviceData, setServiceData]=useState([])
  const [client,setClient]=useContext(ClientContext)
  const columns = React.useMemo(
      () => [
        {
          Header: 'OrderID',
          accessor: 'OrderID'
        },
        
        {
          Header: 'Service Type',
          accessor: 'TypeOfService'
        },
        {
          Header: 'Service Date',
          accessor: 'FinishDate',
          cell: props => <span>{moment(props.value).format('MM/DD/YYYY')}</span>
        },
        {
          Header: 'Service Status',
          accessor: 'OrderStatus',
        },
        
      ]
    )
  useEffect(()=>{
    const options =   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/order/complete/${client}`,
    }
    const loadData = async() =>
    {
      const response = await axios(options)
      var formatter = []
      var tempVal=0
      var dataResult=response.data
      dataResult.map((e)=>{
        e.CompDate >0 ? e.FinishDate=e.CompDate*1000 : e.FinishDate=e.LastClockStop*1000 
        e.serviceType == 3 ? e.TypeOfService = "Response" : e.TypeOfService="GermBlast"
      }
      )
      console.log(dataResult)
      setServiceData(dataResult)
    }
    loadData()   
    
  },[])
  if (serviceData) {
    return(
      <>
        <SectionTitle title="Reports" subtitle="Service History" />
        <Widget title="Completed Services" description={<span>A List of All Completed Services</span>}>
         
          
          <table className="w-full border">
          <thead>
            <tr className="bg-grey-100">
              <th className="text-left border p-3">Order ID</th>
              <th className="text-left border p-3">Invoices</th>
              <th className="text-left border p-3">Service Type</th>
              <th className="text-left border p-3">Service Date(s)</th>
              <th className="text-left border p-3">Service Status</th>
              <th className="text-left border p-3">Service Summary</th>
              <th className="text-left border p-3">Data Reports</th>
            </tr>
          </thead>
          <tbody>
            {serviceData.map((sData)=>(
              <tr className="border p-3" key={sData.OrderID}>
              <td className="m-4">
                {sData.OrderID}
              </td>
              <td>
              <ServiceInvoices OrderNum={sData.OrderID}/>
              </td>
              <td>
                {sData.TypeOfService}
              </td>
              <td>
                {
                  <ServiceDates OrderNum={sData.OrderID}/>
                }
              </td>
              <td>
                {sData.OrderStatus}
              </td>
              <td>
                <Link to={`/service-summary/${sData.OrderID}`}>Summary</Link>
              </td>
              <td>
              <Link to={`/service-report/${sData.OrderID}`}>Report</Link>
              </td>
            </tr>
            )
            )}
            
          </tbody>
          </table>
        </Widget>
      </>
    )
} else {
  return(
    <>
      <SectionTitle title="Reports" subtitle="Service History" />
      <Widget title="Completed Services" description={<span>Lists all completed services</span>}>
        
        Loading Service History
      </Widget>
    </>
  )
}
}
export default ReportService
