import React, {useState , useEffect, Fragment,useContext} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'
import MyBarChart from '../../components/charts/auditatp'
import { UserContext } from '../../providers/UserProvider'
import { withRouter } from 'react-router-dom';
import AuditAreasQuestionDetail from '../AuditAreasQuestionDetail'
import {ClientContext} from '../../providers/ClientProvider'

const AuditAreasScorecard= ({match}) => {
  const [allATP, setAllATP]=useState()
  const user = useContext(UserContext)
  var auditSamples=[]

  function AddATP(array){
var sum=array.reduce(function(a,b){
  return a+b
})
return sum;
  }
  const [client, setClient] = useContext(ClientContext)
  const [areaState, setAreaState]=useState([])
  const [campusState, setCampusState]= useState([])
  const [ResultDetState, setResultDetState ]=useState([])
  const [auditResultsState, setAuditResultsState]= useState([])
const [auditAtpState, setAusitAtpState]=useState([])
  const [atpbyAreaState, setAtpbyAreaState]= useState([])
  const [questionState, setQuestionState]=useState([])
  const [auditDetailState, setAuditDetailState]=useState([])

  const [atpAverage, setAtpAverage]= useState()
  const [auditAverage, setAuditAverage]=useState(0)
const[atpPassed, setAtpPassed]=useState(0)

const [atpData, setAtpData]=useState([])
const [chartAtp, setChartAtp]=useState([])
const [ DetailScoreCount, setDetailScoreCount]=useState(0)
const [ DetailScoreTotal, setDetailScoreTotal]=useState(0)
const [ DetailScoreAverage, setDetailScoreAverage ]=useState(0)
const [areaSumState, setAreaSumState]=useState([])
const [questionListState, setQuestionListState]=useState([])
//above good


var countPassed=0
const areaid=match.params.id
const campusid=match.params.campusid
  //const [mainAudit, setMainAudit]= useState()
  useEffect(()=>{
    const options =    
    // clebareaas where areaid===
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditarea/area/${areaid}`, // GOOD i think...
    }
    const options2 =   
    {
        // will need clebcampus where campusid==
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/audit/facility/${campusid}`, //GOOD
    }
    const options3 =   
    {
        // will need clebauditresultsdetail where areaid==
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditresult/area/detail/${areaid}`, // issue
    }

    const options4 =   
    {
        //clebauditresults where areaid==
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditresult/area/atpaverage/${areaid}`,
    }

    const options5=   
    {
        //coda 257 working // GOOD clebauditatps by campus id
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditatp/campus/${campusid}`, //GOOD
    }




    const options6 =   
    {// cclebauditatps where areaid==
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditatp/area/${areaid}`, //GOOD match
    }
// clebquestions ALL
    const options7 =    
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/audit/question/all/${client}`, //  
    }
    //url: `https://gbmercury.com/audit/question/1`, // old one by questionid^

    const options8 =    
    // clebauditresultsdetail where campusid== area
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditresult/campus/${areaid}`, 
    }
    //added by wes question summary
    //clebauditresultsdetail summary
    const options9=
    {
        method: 'post',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
          data:{
              areaID: areaid,
              questionID: 8
          },
        url: `https://gbmercury.com/auditresult/area/questionsummary`, 
      }
      //added by wes, all questions by area no repeats
      //from clebauditresultsdetail where areaid==
      const options10=
      {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
     
        url: `https://gbmercury.com/auditresult/questionlist/area/${areaid}`, 
      }



    const loadData = async() =>
    {
  
     axios.all([
        axios(options),
        axios(options2),
        axios(options3),
        axios(options4),
        axios(options5),
        axios(options6),
        axios(options7),
        axios(options8),
        axios(options9),
        axios(options10)
     
      ]).then(axios.spread(function(areas, campus2, resultsdetail3, auditResults4, auditatp5, 
        atpbyarea6, question7, auditdetail8, areaquesSum9, questionlist10) {

console.log(areaquesSum9.data)
            atpbyAreaState.push(atpbyarea6.data)
            getAtpAverage(auditResults4.data)
            setCampusState(campus2.data)
            areaState.push(areas.data)
            //auditResultsState(auditResults4.data)
            questionState.push(question7.data)
            ResultDetState.push(resultsdetail3.data)
            auditDetailState.push(auditdetail8.data)
            areaSumState.push(areaquesSum9.data)
            questionListState.push(questionlist10.data)
            auditResultsState.push(auditResults4.data)
            var q2=[]
       
         console.log(auditResults4.data)
console.log(atpbyAreaState)
console.log(areaState)
function createchartobject(atp, area){
atp.map(aa=>{
    atpData.push({'Raw': (aa.DateTime*1000), 'Date':moment(aa.DateTime*1000).format("MM/D/YYYY"),
    'ATP': aa.ATPResult, 'Site':aa.ATPSite,
  'Area': aa.AreaID, 'Campus': aa.CampusID, 'Id': aa.ID}) 
 })
console.log(atpData)
 Object.entries(atpData).map(([k,v]) => {
  
    const myObj={
      "Raw":v.Raw ,
      "Title":v.Date +" "+  v.Id, 
      "tagTitle": v.Date ,
      "tagTitle2":  area.length>0 ? (area[0].AreaName + " - " + v.Site): ("unknown"),
      "Result":v.ATP,
      "Total":v.ATP,
    }
    chartAtp.push(myObj) // might need to use another variable name

  })

setAllATP(chartAtp)
}
createchartobject(atpbyarea6.data, areas.data)
console.log(auditResults4.data)


      })).catch(err=>{
        console.log(err + ' there was an error')
      })
  
    }
    loadData()  
    const ATPTotal=0;
    var ATPCount=0;
    const ATPPassed=0;
 const maxValue=1000


 function getAtpAverage(atps) {

    for(var i=0; i<atps.length; i++){
  ATPTotal=1

    }
 }

    function getAtpAverage(atpbyArea) {
        console.log(atpbyArea.length) 
        var atpTotal=0
        for(var i=0; i<atpbyArea.length; i++){
            console.log(atpbyArea[i].ATPResult)
            ATPCount++
          
          if(atpbyArea[i].ATPResult> maxValue)
          {  maxValue=atpbyArea[i].ATPResult; }

          if(atpbyArea[i].ATPResult<=200){
                  countPassed++
                  console.log('something passed here')
          }
          atpTotal+=atpbyArea[i].ATPResult
        }
      
        if(ATPCount>0){
        var dividedatp=atpTotal/(ATPCount)
       setAtpAverage(Math.round(dividedatp))
       setAtpPassed(Math.round(countPassed/ATPCount*100))
        }
     
  }


   var DetailATPCount=0;
   var DetailATPTotal=0;
   function passRate(results){
    DetailScoreCount=0
    DetailScoreTotal=0
     if(results> 0){
        DetailScoreCount++
     
if(results== 1 || results== 3){
    DetailScoreTotal++
}
     }
     setDetailScoreAverage(Math.round((DetailScoreTotal/DetailScoreCount)*100))
 return DetailScoreAverage
   }


  },[])
  

  if (atpbyAreaState.length>0 && campusState.length>0 && areaState.length>0 ) {
return (
  <>
 
 {console.log(ResultDetState)}
   
    <SectionTitle title="Pages" subtitle="Audit Report by Area "  />
    <Widget title="Page title" description={<span>Audit Results for Facility {campusState[0].CampusName}- {areaState[0][0].AreaName} </span>}>
        <p>{campusState.CampusName}</p>
     
      <p style={{fontSize:' 20px'}}>ATP Sampling</p>
      <p>{`Average ATP: ${auditResultsState[0].atpAverage}`}</p>
      <p>{`Percent passing ATP samples:  ${atpPassed}%`}</p>
      <p>ATP Individual sample chart</p>
      <MyBarChart chartData={allATP} />

  <table>
      <thead>
          <tr>
              <td style={{ width: "20%", textAlign: "center",
              paddingBottom: "3%", fontWeight: 'bold'}}>Date/Time </td>
              <td style={{ width: "36%", textAlign: "center",
              paddingBottom: "3%", fontWeight: 'bold'}}>ATP Location </td>
              <td style={{ width: "20%", textAlign: "center",
              paddingBottom: "3%", fontWeight: 'bold'}}>  ATP Value (RLU)</td>
          </tr>
      </thead>
      <tbody>

     {atpbyAreaState[0].map((atp,indx)=>{
   return <tr key={indx}>
       {console.log(atp.ID)}
       <td style={{ width: "20%", textAlign: "center",
              paddingBottom: "3%"}}>{moment(atp.DateTime*1000).format("MM/DD/YYYY")} </td>
   <td style={{ width: "36%", textAlign: "center",
              paddingBottom: "3%"}} >{areaState[0][0].AreaName} -{atp.ATPSite} </td>
   <td style={{ width: "20%", textAlign: "center",
              paddingBottom: "3%"}}>{atp.ATPResult} </td>
 
        </tr>
          })}
       {console.log(auditResultsState)}  
      </tbody>                          
  </table>
<h4> Audit Question History</h4>
<h6>Average Audit Score: {auditResultsState[0].atpPassRate> 60 }%</h6>
  <table>
      <thead>
          <tr>
              <td style={{ width: "20%", textAlign: "center",
              paddingBottom: "3%", fontWeight: 'bold'}}> Question</td>
              <td style={{ width: "10%", textAlign: "center",
              paddingBottom: "3%", fontWeight: 'bold'}}>Pass Rate </td>
              <td style={{ width: "30%", textAlign: "center",
              paddingBottom: "3%", fontWeight: 'bold'}}>Audit History </td>
          </tr>
      </thead>
      <tbody>
        {console.log(questionListState)}
  {questionListState[0].map((q, indx)=>{
      return(
          <tr style={{borderBottom:'.2px solid #bbbbbd' }}key={indx}>
              <td style={{textAlign: 'center'}}>{q.question}</td>
              <AuditAreasQuestionDetail quesID={q.theID} area={areaid}/>
              {console.log(areaid)}
             
          </tr>
          
      )
  })}

        
          {console.log(auditDetailState)}
      </tbody>
  </table>

    </Widget>
   
  </>
) }
else {
  return(
    <>
      <SectionTitle title="Pages" subtitle="Audit Report - Facility" />
      <Widget title="Page Title" description={<span>Audit results </span>}>
        
        Loading Chart Data... audit areas scorecard
      </Widget>
    </>
)}}
export default withRouter(AuditAreasScorecard)
