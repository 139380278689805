import React from 'react';
import {Link} from 'react-router-dom'
export default function QuestionList  (props) {
    
    
    return (
        <ul className="list-disc">
            
            {props.Questions.map((singleQ)=>(
                <li className="m-3" key={singleQ.QuestionID}><Link to={`/auditsettings-questionedit/${singleQ.QuestionID}`}>{singleQ.Question}</Link></li>
                // <IndivQuestion Question={question}/>
            )
            )}
            
        </ul>
    );
}

