import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import AuditList from './AuditList'
import Datetime from 'react-datetime'
import {ClientContext} from '../../providers/ClientProvider'
import { useSnackbar } from 'notistack'
import moment from 'moment'
export default function AuditDashboard () {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [client, setClient] = useContext(ClientContext)
  const user = useContext(UserContext)
  const [campus, setCampus] = useState('')
  const [custodian, setCustodian] = useState('')
  const [custodianName, setCustodianName]=useState('')
  const [dateTime, setDateTime ] = useState()
  const [custodianList, setCustodianList] = useState()
  const [templateList, setTemplateList] = useState('')
  const [areaList, setAreaList] = useState('')
  const [campusList, setCampusList] = useState('')
  const [area, setArea] = useState('')
  const [template, setTemplate] = useState('')
  const [templateName, setTemplateName]=useState('')
  const [areaName, setAreaName]=useState('')
  const [campusName, setCampusName]=useState('')
  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'Campus') {
        setCampus(value);
        setCampusName(campusList.filter(subcampus=>subcampus.CampusID == value))
    }
    else if(name === 'Template') {
      setTemplate(value);
      setTemplateName(templateList.filter(templ=>templ.AuditID == value))
    }
    else if(name === 'Custodian') {
      setCustodian(value);
      setCustodianName(custodianList.filter(custod=>custod.CustodianID == value))
    }
    else if(name === 'DateTime') {
      setDateTime(value);
    }
    else if(name === 'Area') {
      setArea(value);
      setAreaName(areaList.filter(subarea=>subarea.AreaID == value))
    }
};
const [cards, setCards]=useState([])
  const createAudit = async(event) =>
    {
        event.preventDefault();  
        const options = {
          method: 'post',
          data: {'campus':campus,
                'area':area,
                'custodian':custodian,
                'dateTime':moment(dateTime).unix(),
                'template':template,
                'clientID':user.CustID},
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/auditresult/new',
        }
        console.log(options)
        const response = await axios(options)
        setCards(existingCards=>[{AuditResultsID:response.data.insertId,CampusName:campusName[0].CampusName, AreaName:areaName[0].AreaName , ScheduledOn:moment(dateTime).unix(), AuditName:templateName[0].Name, Custodian: custodianName[0].Name}, ...existingCards])
        console.log(response)
        setCampus("")
        enqueueSnackbar('Audit Created',{variant: 'success',});
    }
  useEffect(()=>{
    
    const loadData = async() =>
    {
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/auditresult/upcoming/${client}`
        
      }
      )
      setCards(response.data)
      const responseCL = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/auditcustodian/active/${client}`
      }
      )
      setCustodianList(responseCL.data)
      const responseCampus = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/auditcampus/all/${client}`
      }
      )
      setCampusList(responseCampus.data)
      const responseArea = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/auditarea/all/${client}`
      }
      )
      setAreaList(responseArea.data)
      const responseTemplate = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/audittemplate/all/${client}`
      }
      )
      setTemplateList(responseTemplate.data)
      
    }
    loadData()   
    
  },[client])
  return(
  <>
    <SectionTitle title="Pages" subtitle="Settings - Facilities" />
    
    <Widget title="Audit List">
    <form >
            <label>Campus</label>
            <select className="w-full p-3 mb-2 bg-grey-200" type="text" name="Campus"
            value = {campus}
            onChange = {(event) => onChangeHandler(event)}
            >
            <option value="-9">Please Select</option>
            {
              campusList?
              campusList.map((camp)=>(
                <option key={camp.CampusID} value={camp.CampusID}>{camp.CampusName}</option>

              ))
              :
              null
            }
            </select>
            <label>Area</label>
            <select className="w-full p-3 mb-2 bg-grey-200" type="text" name="Area"
            value = {area}
            onChange = {(event) => onChangeHandler(event)}
            >
            <option value="-9">Please Select</option>
            {
              areaList?
              areaList.filter(areaf=>areaf.CampusID==campus).map((oneArea)=>(
                <option key={oneArea.AreaID} value={oneArea.AreaID}>{oneArea.AreaName}</option>

              ))
              :
              null
            }
            </select>
            <label>Custodian</label>
            <select className="w-full p-3 mb-2 bg-grey-200" type="text" name="Custodian"
            value = {custodian}
            onChange = {(event) => onChangeHandler(event)}
            >
            <option value="-9">Please Select</option>
            {
              custodianList?
              custodianList.map((cust)=>(
                <option key={cust.CustodianID} value={cust.CustodianID}>{cust.Name}</option>

              ))
              :
              null
            }
            </select>
            <label>Template</label>
            <select className="w-full p-3 mb-2 bg-grey-200" type="text" name="Template"
            value = {template}
            onChange = {(event) => onChangeHandler(event)}
            >
            <option value="-9">Please Select</option>
            {
              templateList?
              templateList.map((templ)=>(
                <option key={templ.AuditID} value={templ.AuditID}>{templ.Name}</option>

              ))
              :
              null
            }
            </select>
            <label>Date</label>
            
            <Datetime
              name="DateTime" 
              value={dateTime} 
              closeOnSelect={true} 
              onChange={(date) =>setDateTime(date.format("MM/DD/YYYY"))}
              defaultValue={new Date()}
              dateFormat="MM/DD/YYYY"
              timeFormat={false}
              input={true}
              inputProps={{
                className: 'form-input',
                placeholder: 'Select date'
              }}
              viewMode={'days'}
            />
                       
            <button 
            disabled={!campus}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {createAudit(event)}}
            >
            Create Audit
            </button>
            

            </form>
    </Widget>
    <Widget title="Scheduled Audits" >
    <AuditList Audits={cards}/>
    
    </Widget>
  </>
)}


