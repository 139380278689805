import React, {useState , useEffect, Fragment,useContext} from 'react'

import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'
import HandHygieneDash from './HandHygieneDash'
import { Bar } from 'react-chartjs-2'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import { withRouter } from 'react-router-dom';
  import DateFnsUtils from '@date-io/date-fns';
  import { Grid, Select} from '@material-ui/core';

import { ClientContext } from '../../providers/ClientProvider'
import GBRingLoader from '../../components/GBRingLoader/GBRingLoader'

import  {UserContext} from "../../providers/UserProvider";




const HandHygieneReport=({match})=>{
    const [client, setClient] = useContext(ClientContext)
const [profession, setProfession]=useState([])
const [observation, setObservation]=useState([])
const [allObservations, setAllObservations]=useState([])
// const momentArray=[{Name:'Before patient contact', id: 1},{Name: 'Before Aseptic technique', id:2 },
//     {Name: 'after body fluid exposure/risk', id:3 }, {Name:'After patient contact', id: 4},
//     {Name:'After contact with environment', id: 5}]
    const[momentArray, setMomentArray]=useState([{Name:'Before patient contact', id: 1},{Name: 'Before Aseptic technique', id:2 },
    {Name: 'after body fluid exposure/risk', id:3 }, {Name:'After patient contact', id: 4},
    {Name:'After contact with environment', id: 5}])
    const [momentData, setMomentData]=useState([])
const [allDataLabel, setAllDataLabel]=useState([])
const [ obsData, setObsData]=useState([])
const [obsFail, setObsFail]=useState([])
const [obsPass, setObsPass]=useState([])
const [open, setOpen] =useState(false);// form added
const [selectedDate, setSelectedDate] = useState(  new Date());
const [selectedEndDate, setSelectedEndDate] = useState((new Date()+1728000));// form added
const [professionData, setProfessionData]=useState([])
const [masterInfo, setMasterInfo]=useState()
const auditID=match.params.id
    var datasetObjs=[]
    const user = useContext(UserContext);
   


var mstr = 0;

useEffect(()=>{
   mstr++
    const options=   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/handhygiene/observation/${auditID}`,
    }

    const options2=   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/handhygiene/professions/${client}`,
    }

    const options3=   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/handhygiene/observation/client/${client}`,
    }
    const options4=   
    {
        // from hhmaster ==id
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/handhygiene/master/${auditID}`, 
    }
    

    const loadData=async ()=>{

        axios.all([
axios(options),
axios(options2),
axios(options3),
axios(options4)
        ]).then(axios.spread(function(op1,op2,op3,op4){
         
                setSelectedDate(moment(op4.data[0].InitDate*1000).format("L"))
                setSelectedEndDate(moment(op4.data[0].CompDate*1000).format("L"))
                console.log(op4.data[0])
    

            for(var i=1; i<=op2.data.length; i++){
                var gmath=0
             var passMath=op1.data.filter(e=>e.Profession==i&& e.HHActivity>0 ).length
             var allData=op1.data.filter(e=>e.Profession==i ).length
              gmath=Math.round((passMath/(allData))*100)
        
            // console.log(gmath)
          if(!isNaN(gmath)){
            professionData.push(gmath)
          }else {
            professionData.push(0)
          }
     
             }

if(momentData.length<2){
//added in here
for(var i=1; i<=momentArray.length; i++){
    var momentMath=0
 var passMoment=op1.data.filter(e=>e.HHMoment==i&& e.HHActivity>0 ).length
 var allMomentData=op1.data.filter(e=>e.HHMoment==i ).length
 momentMath=Math.round((passMoment/(allMomentData))*100)
console.log(momentMath)

if(!isNaN(momentMath)){
    momentData.push(momentMath)
}else {
momentData.push(0)
}

 }
 console.log(momentData)
}// end of if
//aaded in ending
        setObservation(op1.data)
        setProfession(op2.data)
        setMasterInfo(op4.data)
     
        
        
  

    console.log(op4.data[0])
        setAllObservations(op3.data)
        setObsPass(op1.data.filter(f=>f.HHActivity>0))
        setObsFail(op1.data.filter(f=>f.HHActivity==0))
       
       
        console.log(op1.data)
        
        op2.data.map(single=>{
            
            allDataLabel.push(single.ProfessionName)
            datasetObjs.push({label:single.ProfessionName,  data:'measureArr', showLine: true, fill:false,
                borderWidth: 1,})
        })

        
            //setSelectedDate(masterInfo[0].InitDate)
          console.log(masterInfo)
         
     
          
        })).catch(err=>{
            console.log(err)
        })
    
    }//end of loadData

    console.log(obsFail.length)
    console.log(obsPass.length)


     loadData( ) 
  
    
   // setTimeout(()=>{ loadData( updateDate) ()},4000)

},[client])



console.log(masterInfo)


  function onADDaction(e) {
    e.preventDefault()

} 
const handleDateChange = (date) => {setSelectedDate(date);};
const handleEndDateChange = (date) => { setSelectedEndDate(date);};

const dataAll2= {
    labels: profession.map(m=>{
        return m.ProfessionName
    }),
    datasets: [
      { 
          label: 'Compliance %',
      data: professionData,
        borderColor: 'black',
        backgroundColor: new Array(profession.length).fill('blue'), 
        borderWidth: .8,
      } ] };
var cutoff
      const momentDataAll2= {
        labels: momentArray.map(m=>{
            return  cutoff=m.Name.indexOf(' ',12),
            [m.Name.slice(0,cutoff), m.Name.slice(cutoff,30)]
        }),
 
        datasets: [
          { 
              label: 'Compliance %',
          data: momentData,
            borderColor: 'black',
            backgroundColor: new Array(momentArray.length).fill('purple'), 
            borderWidth: .8,
          } ] };

     
if(profession.length>0 && observation.length>1 && obsFail && obsPass.length>0   ){


    return(
        <Widget>

{console.log(masterInfo[0].InitDate)}
<h2>Hand Hygiene Report</h2>
        <div className='border border-10 p-5 mb-5'>
        
        
        <form  noValidate autoComplete="off" onSubmit= {onADDaction}>
          <h5>All Hand Hygiene audits for <br></br>
          {`${moment(selectedDate).format("MMM Do YYYY")}-${moment(selectedEndDate).format("MMM Do YYYY")}`}</h5>
            <h6 className="mb-5">(All audits for this report will be between {moment(masterInfo[0].InitDate*1000).format("ll")} and {moment(masterInfo[0].CompDate*1000).format("ll")})</h6>
      
 <br></br>
       
 <table> 
    <thead>
        <tr className='font-bold'>
            <td className="pr-4" >Compliance by Professions </td>
            <td className="pr-4"> Successes</td>
            <td className= "pr-4">Total Observations </td>
            <td >Compliance % </td>
        </tr>
    </thead>
    {console.log(allObservations , parseInt(auditID))}
{console.log(Date.parse(selectedEndDate))}
    <tbody>
        {profession.map(pro=>{
            return <tr key={pro.id}>
                <td>{pro.ProfessionName}</td>
                <td>{allObservations.filter(o=>o.Profession==pro.id).filter(b=>b.HHActivity>0).filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate) && parseInt(auditID)===s.AuditID ).length}</td>
                <td>{allObservations.filter(o=>o.Profession==pro.id).filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate)  && parseInt(auditID)===s.AuditID).length}</td>
                <td>{(allObservations.filter(o=>o.Profession==pro.id).filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate)  && parseInt(auditID)===s.AuditID ).length!=0) ?
                (Math.round((allObservations.filter(o=>o.Profession==pro.id).filter(b=>b.HHActivity>0).filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate) && parseInt(auditID)===s.AuditID ).length)/
                (allObservations.filter(o=>o.Profession==pro.id).filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate) && parseInt(auditID)===s.AuditID ).length)*100))
            : ('0')}%</td>
            </tr>
        })}

       
        <tr className='font-bold'> 
            <td> Total</td>
            <td>{allObservations.filter(o=> o.HHActivity>0 && o.Profession>0 ).filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate) && parseInt(auditID)===s.AuditID  ).length}</td>
            <td>{allObservations.filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate) && s.Profession>0 && parseInt(auditID)===s.AuditID  ).length}</td>
            <td>{ (allObservations.filter(o=> o.HHActivity>0 && o.Profession>0 ).filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate) && parseInt(auditID)===s.AuditID  ).length !=0) ?
             Math.round((allObservations.filter(o=> o.HHActivity>0 && o.Profession>0 ).filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate) && parseInt(auditID)===s.AuditID  ).length)/
            (allObservations.filter(s=>s.DateTime*1000<Date.parse(selectedEndDate)&& s.DateTime*1000>Date.parse(selectedDate) && s.Profession>0 && parseInt(auditID)===s.AuditID  ).length)*100) : ('0')}%</td>
        </tr>


    </tbody>
</table>
<Grid container spacing={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <Grid item xs={5} lg={4}>
         
        <KeyboardDatePicker disableToolbar
          variant="inline" format="MM/dd/yyyy"
          margin="normal" id="Start-Date"
          label="Start Date" value={selectedDate}
          onChange={handleDateChange}
           KeyboardButtonProps={{ 'aria-label': 'change date', }} className="mr-5" /> 
     </Grid>
     <Grid item xs={5} lg={4}>
        <KeyboardDatePicker
          disableToolbar variant="inline"
          format="MM/dd/yyyy"  margin="normal"
          id="End-Date" label="End Date"
          value={selectedEndDate} onChange={handleEndDateChange}
          KeyboardButtonProps={{ 'aria-label': 'change date',}}/> 
          </Grid>
        </MuiPickersUtilsProvider>
        </Grid>


        <br></br>
      
          </form>
       

        
          </div>
       
     {/* added in filter code */}















<table> 
    <thead>
        <tr className='font-bold'>
            <td className="pr-4" >Compliance by Professions </td>
            <td className="pr-4"> Successes</td>
            <td className= "pr-4">Total Observations </td>
            <td >Compliance % </td>
        </tr>
    </thead>

    <tbody>
        {profession.map(pro=>{
            return <tr key={pro.id}>
                <td>{pro.ProfessionName}</td>
                <td>{observation.filter(o=>o.Profession==pro.id).filter(b=>b.HHActivity>0).length}</td>
                <td>{observation.filter(o=>o.Profession==pro.id).length}</td>
                <td>{(observation.filter(o=>o.Profession==pro.id).length!=0 ) ?
                (Math.round((observation.filter(o=>o.Profession==pro.id).filter(b=>b.HHActivity>0).length)/(observation.filter(o=>o.Profession==pro.id).length)*100))
            : ('0')}%</td>
            </tr>
        })}

       
        <tr className='font-bold'> 
            <td> Total</td>
            <td>{observation.filter(o=> o.HHActivity>0).length}</td>
            <td>{observation.length}</td>
            <td>{Math.round((observation.filter(o=> o.HHActivity>0).length)/(observation.length)*100)}%</td>
        </tr>


    </tbody>
</table>

{professionData?
 

       ( <Bar data={dataAll2}
        options={{
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: '%',
                        fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
                    ticks: {   min:0, 
                      max: 100  },
                    beginAtZero:true   }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Professions',
                   fontSize: 15 },
                ticks: {  beginAtZero: true,
                   } }],
                
           },
        }}
        /> ) : (null)
    

}


---------------------------------------------
<table> 
    <thead>
        <tr className='font-bold'>
            <td className="pr-4" >Compliance by Hand Hygiene<br></br>  Moment - All Professions </td>
            <td className="pr-4" > Successes</td>
            <td className="pr-4" >Total Observations </td>
            <td className="pr-4" >Compliance % </td>
        </tr>
    </thead>

    <tbody>



        {momentArray.map(mo=>{
            return <tr key={mo.id}>
                <td>{mo.Name}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).length}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).length}</td>
                <td>{(observation.filter(o=>o.HHMoment==mo.id).length!=0) ?
                (Math.round((observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).length)/(observation.filter(o=>o.HHMoment==mo.id).length)*100))
            : ('0')}%</td>
            </tr>
        })}

        <tr className='font-bold'> 
            <td> Total</td>
            <td>{observation.filter(o=> o.HHActivity>0).length}</td>
            <td>{observation.length}</td>
            <td>{Math.round((observation.filter(o=> o.HHActivity>0).length)/(observation.length)*100)}%</td>
        </tr>

    </tbody>
</table>


<Bar data={momentDataAll2}
        options={{
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: '%',
                        fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
                    ticks: {   min:0, 
                      max: 100  },
                    beginAtZero:true   }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Moments',
                   fontSize: 15 },
                ticks: {  beginAtZero: true,
                   } }],
                
           },
        }}
        /> 

<h4 className='text-center mt-8'> Audit Statistics Divided by Profession and Hand Hygiene Moment</h4>


---------------------------------------------

    
    <table>
        <thead>

            <tr className="font-bold"> 
                <td className="pr-4" >{ profession[0].ProfessionName}</td>
                <td className="pr-4" >Successes</td>
                <td className="pr-4" > Total Observations</td>
                <td className="pr-4" >Compliance %</td>
            </tr>
        </thead>

        <tbody >
        {momentArray.map(mo=>{
            return( <tr key={mo.id} >
                <td>{mo.Name}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[0].id).length}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[0].id).length}</td>

                <td>{(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[0].id).length!=0) ?
                (Math.round((observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[0].id).length)/(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[0].id).length)*100))
            : ('0')}%</td>
           
            </tr>
        )})}
                <tr className='font-bold'> 
            <td> Total</td>
            <td>{observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[0].id).length}</td>
            <td>{observation.filter(s=>s.Profession==profession[0].id).length}</td>
            {console.log(observation.filter(s=>s.Profession==profession[0].id).length +'sdgsgs')}
            <td>{(observation.filter(s=>s.Profession==profession[0].id).length!=0)?
               ( Math.round((observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[0].id).length)/(observation.filter(s=>s.Profession==profession[0].id).length)*100))
            :('0')}%</td>
        </tr>

    
        </tbody>
    </table>

    ---------------------------------------------
        
    <table>
        <thead>

            <tr  className="font-bold"> 
                <td className="pr-4" >{ profession[1].ProfessionName}</td>
                <td className="pr-4" >Successes</td>
                <td className="pr-4" > Total Observations</td>
                <td className="pr-4" >Compliance %</td>
            </tr>
        </thead>

        <tbody >
        {momentArray.map(mo=>{
            return( <tr key={mo.id} >
                <td>{mo.Name}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[1].id).length}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[1].id).length}</td>
                <td>{(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[1].id).length!=0) ?
                (Math.round((observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[1].id).length)/(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[1].id).length)*100))
            : ('0')}%</td>
           
            </tr>
        )})}

<tr className='font-bold'> 
            <td> Total</td>
            <td>{observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[1].id).length}</td>
            <td>{observation.filter(s=>s.Profession==profession[1].id).length}</td>
            <td>{Math.round((observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[1].id).length)/(observation.filter(s=>s.Profession==profession[1].id).length)*100)}%</td>
        </tr>
    
        </tbody>
    </table>

    ---------------------------------------------
        
    <table>
        <thead>

            <tr  className="font-bold"> 
                <td className="pr-4" >{ profession[2].ProfessionName}</td>
                <td className="pr-4" >Successes</td>
                <td className="pr-4" > Total Observations</td>
                <td className="pr-4" >Compliance %</td>
            </tr>
        </thead>

        <tbody >
        {momentArray.map(mo=>{
            return( <tr key={mo.id} >
                <td>{mo.Name}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[2].id).length}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[2].id).length}</td>
                <td>{(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[2].id).length!=0) ?
                (Math.round((observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[2].id).length)/(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[2].id).length)*100))
            : ('0')}%</td>
           
            </tr>
        )})}

<tr className='font-bold'> 
            <td> Total</td>
            <td>{observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[2].id).length}</td>
            <td>{observation.filter(s=>s.Profession==profession[2].id).length}</td>
            <td>{Math.round((observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[2].id).length)/(observation.filter(s=>s.Profession==profession[2].id).length)*100)}%</td>
        </tr>
    
        </tbody>
    </table>

    ---------------------------------------------

    <table>
        <thead>

            <tr  className="font-bold"> 
                <td className="pr-4" >{ profession[3].ProfessionName}</td>
                <td className="pr-4" >Successes</td>
                <td className="pr-4" > Total Observations</td>
                <td className="pr-4" >Compliance %</td>
            </tr>
        </thead>

        <tbody >
        {momentArray.map(mo=>{
            return( <tr key={mo.id} >
                <td>{mo.Name}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[3].id).length}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[3].id).length}</td>
                <td>{(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[3].id).length!=0) ?
                (Math.round((observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[3].id).length)/(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[3].id).length)*100))
            : ('0')}%</td>
           
            </tr>
        )})}

<tr className='font-bold'> 
            <td> Total</td>
            <td>{observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[3].id).length}</td>
            <td>{observation.filter(s=>s.Profession==profession[3].id).length}</td>
            <td>{Math.round((observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[3].id).length)/(observation.filter(s=>s.Profession==profession[3].id).length)*100)}%</td>
        </tr>
    
        </tbody>
    </table>


-------------------------------------
<table>
        <thead>

            <tr  className="font-bold"> 
                <td className="pr-4" >{ profession[4].ProfessionName}</td>
                <td className="pr-4" >Successes</td>
                <td className="pr-4" > Total Observations</td>
                <td className="pr-4" >Compliance %</td>
            </tr>
        </thead>

        <tbody >
        {momentArray.map(mo=>{
            return( <tr key={mo.id} >
                <td>{mo.Name}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[4].id).length}</td>
                <td>{observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[4].id).length}</td>
                <td>{(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[4].id).length!=0) ?
                (Math.round((observation.filter(o=>o.HHMoment==mo.id).filter(b=>b.HHActivity>0).filter(s=>s.Profession==profession[4].id).length)/(observation.filter(o=>o.HHMoment==mo.id).filter(s=>s.Profession==profession[4].id).length)*100))
            : ('0')}%</td>
           
            </tr>
        )})}

<tr className='font-bold'> 
            <td> Total</td>
            <td>{observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[4].id).length}</td>
            <td>{observation.filter(s=>s.Profession==profession[4].id).length}</td>
            <td>{Math.round((observation.filter(o=> o.HHActivity>0).filter(s=>s.Profession==profession[4].id).length)/(observation.filter(s=>s.Profession==profession[4].id).length)*100)}%</td>
        </tr>
    
        </tbody>
    </table>



    



        </Widget>
    )
                }
                else  {
                    return(
                        <GBRingLoader/>
                    )
                }
}
export default withRouter(HandHygieneReport)