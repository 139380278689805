import React, {useEffect, useState} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'
import logo from '../../images/gblogo.png'
import {formatCurrency} from '../../functions/numbers'
import {useParams} from 'react-router-dom'
import CampusList from './CampusList'
import ucwords from 'ucwords'
import '../../styles/Printer-Friendly.css'
export default function ServiceSummary () {
  const[serviceData, setServiceData]=useState({})
  const [customerData, setCustomerData]=useState()
  const [franchiseData, setFranchiseData]=useState()
  const [equipment, setEquipment]=useState()
  const [serviceDates, setServiceDates]=useState()
  const [notes, setNotes]=useState()
  const [preATP, setPreATP]=useState()
  const [postATP, setPostATP]=useState()
  const {orderID} =useParams() 
  const [lastDate, setLastDate]=useState(0)
  const items = [
    {name: 'Creative Design', hours: 80, rate: 40},
    {name: 'Front end development', hours: 120, rate: 60},
    {name: 'API development', hours: 80, rate: 40}
  ]
  const columns = React.useMemo(
      () => [
        {
          Header: 'OrderID',
          accessor: 'OrderID'
        },
        
        {
          Header: 'Service Type',
          accessor: 'TypeOfService'
        },
        {
          Header: 'Service Date',
          accessor: 'FinishDate',
          cell: props => <span>{moment(props.value).format('MM/DD/YYYY')}</span>
        },
        {
          Header: 'Service Status',
          accessor: 'OrderStatus',
        },
        
      ]
    )
   

  

  useEffect(()=>{
    const loadData = async() =>
    {
      const responseCal = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/order/dates/${orderID}`,
      })
        var dataResult=responseCal.data
        dataResult.map((e)=>{
          e.StartTime >0 ? e.Start=e.StartTime*1000 : e.Start="Missing Info"
          e.StartTime >0 ? e.PrettyDate=moment(e.Start).format("MM/DD/YYYY"):e.PrettyDate="Missing Date Info"
        })
        setServiceDates(dataResult)
     console.log(dataResult)


      const response = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
        url: `https://gbmercury.com/order/single/${orderID}`,
      })
      setServiceData(response.data[0])      
      
      const responseCust = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/customer/id/${response.data[0].CustID}`,
      })
      setCustomerData(responseCust.data[0])
      
      const responsePost = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/atps/post/orderid/${orderID}`,
      })
        setPostATP(responsePost.data)

        const responsePre = await axios({
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/atps/pre/orderid/${orderID}`,
        })
          setPreATP(responsePre.data)
          const responseEquip = await axios({
            method: 'get',
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: `https://gbmercury.com/equipment/order/${orderID}`,
          })
            setEquipment(responseEquip.data)

            const responseNotes = await axios({
              method: 'get',
              headers: {'Accept':'application/json',
                'culper':'culper',
                'Content-Type':'application/json'},
              url: `https://gbmercury.com/order/notes/${orderID}`,
            })
              setNotes(responseNotes.data)
            
     
    }

    loadData()   
    
  },[])
  if (serviceData) {
    return(
      <>
      <Widget>
        <div className="p-4 max-w-screen-lg">
          <div className="flex flex-row items-center justify-between mb-2 ">
            <div className="flex flex-col ">
              <span className="text-blue-500 text-4xl uppercase font-bold">
                Service Summary
              </span>
              <span className="text-grey-700 text-lg">Order #{orderID}</span>
              <span className="text-grey-700 text-lg">
                Date Completed {serviceData ? moment(serviceData.LastClockStop*1000).format("MM/DD/YYYY") : null}
              </span>
            </div>
            <div className="uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start whitespace-no-wrap">
              <div className="flex flex-row items-center justify-start space-x-2 text-blue-500">
                <img style={{height:90+ 'px'}} src={logo}/>
                
              </div>
            </div>
          </div>
          <div className="p-4 max-w-screen-lg">
          <div className="flex flex-row items-center mb-2 ">
            <div className="flex flex-col">
              <span className="text-blue-500 text-xl uppercase font-bold mb-2">
               Service Dates & Facilities Serviced
              </span>
              <ul>
            {
            serviceDates ? 
            serviceDates.map((f)=>(
              <li key={f.CalID} className=" break-clean"><span className="text-md font-bold">{f.PrettyDate}</span>
                <CampusList CalID={f.CalID}/>
              </li>
            ))
            :
            null
          }
          </ul>
            </div>
            
          </div>
        </div>
        




        <div className="p-4 max-w-screen-lg">
              <span className="text-blue-500 text-xl uppercase font-bold mb-2">
               Pre-Service ATPs
              </span>
              <table className="w-full">
                <thead>
                  <tr className="break-clean"  >
                    <th className="text-left">Location</th>
                    <th className="text-left">Facility</th>
                    <th className="text-right">Value</th>
                  </tr>
                </thead>
                <tbody>
            {
            preATP ? 
            preATP.map((f)=>(
              <tr    key={f.ATPID} className="border-2 border-grey-400 hover:bg-grey-100 break-clean" >
                <td ><span className="text-md">{ucwords(f.ATPloc)}</span></td>
                <td><span className="text-md">{f.LocName}</span></td>
                <td className="text-right"><span className="text-md text-right">{f.ATPVal} RLU</span></td>
                </tr>
            ))
            :
            null
          }
          </tbody>
          </table>
          
        </div>

        <div  className="p-4 max-w-screen-lg">
              <span className="text-blue-500 text-xl uppercase font-bold mb-2">
               Post-Service ATPs
              </span>
              <table className="w-full">
                <thead>
                  <tr className=" break-clean"  >
                    <th className="text-left">Location</th>
                    <th className="text-left">Facility</th>
                    <th className="text-right">Value</th>
                  </tr>
                </thead>
                <tbody>
            {
            postATP ? 
            postATP.map((f)=>(
              <tr key={f.ATPID} className="border-2 border-grey-400 hover:bg-grey-100 break-clean">
                <td ><span className="text-md">{ucwords(f.ATPloc)}</span></td>
                <td><span className="text-md">{f.LocName}</span></td>
                <td className="text-right"><span className="text-right text-md">{f.ATPVal} RLU</span></td>
                </tr>
            ))
            :
            null
          }
          </tbody>
          </table>
        </div>
        <div className="text-lg font-bold break-clean">ATP Explained</div>
          <span>
            Adenosine triphosphate (ATP) is a multifunctional nucleotide used in cells as a coenzyme. ATP transports chemical energy within cells for metabolism. ATP is sometimes referred to as the currency, or fingerprint of living matter. The ATP meter can detect the presence of ATP. ATP data is collected at the beginning of each service in order to detect areas with high levels of bioburden as well as long-term trends.
          </span>
        </div>

        <div className="p-4 max-w-screen-lg">
              <span className="text-blue-500 text-xl uppercase font-bold mb-2">
               Equipment Service Log
              </span>
              <table className="w-full">
                <thead>
                  <tr className=" break-clean">
                    <th className="text-left ">Service Type</th>
                    <th className="text-left ">Barcode</th>
                    <th className="text-left ">Date</th>
                    <th className="text-left ">Item Description</th>
                  </tr>
                </thead>
                <tbody>
            {
            equipment ? 
            equipment.map((f)=>(
              <tr key={f.EquipSvcID} className="border-2 border-grey-400 hover:bg-grey-100 break-clean">
                <td ><span className="text-md ">{f.SvcType == 2 ? <span>GermBlast Wash</span> : <span>GermBlast Service</span>}</span></td>
                <td><span className="text-md ">{f.Barcode}</span></td>
                <td><span className="text-md ">{moment(f.DateTime*1000).format("M/D/YYYY")}</span></td>
                <td><span className="text-md ">{f.ItemDescription}</span></td>
                </tr>
            ))
            :
            null
          }
          </tbody>
          </table>
           </div>



        <div  className="p-4 max-w-screen-lg">
          <div className="flex flex-row items-center  mb-2 ">
            <div className="flex flex-col">
              <span className="text-blue-500 text-xl uppercase font-bold mb-2">
               Service Notes
              </span>
              <ul>
            {
            notes ? 
            notes.map((f)=>(
              <li key={f.EquipSvcID} className=" break-clean">
                  <>
                  <span className="p-4 text-md font-bold">
                    {f.deptTag} 
                  </span>
                  <span className="p-4 text-md pl-4 font-bold">
                    {f.percentage}%
                  </span><br/>
                  <span className="p-4 text-md">{f.note}</span>
                </>
              </li>
            ))
            :
            null
          }
          </ul>
            </div>
          </div>
        </div>
      </Widget>
      
    </>
    )
} else {
  return(
    <>
      <Widget>
        <div className="p-4 max-w-screen-lg">
          <div className="flex flex-row items-center  mb-2 ">
            <div className="flex flex-col">
              <span className="text-blue-500 text-4xl uppercase font-bold">
                Service Summary
              </span>
              <span className="text-grey-700 text-lg">Order #{orderID}</span>
            </div>
            <div className="uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start whitespace-no-wrap">
              <div className="flex flex-row items-center justify-start space-x-2 text-blue-500">
                <img style={{height:90+ 'px'}} src={logo}/>
              </div>
            </div>
          </div>
        </div>
      </Widget>
      
    </>
  )
}
}
