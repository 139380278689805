import React from 'react';
import {Link} from 'react-router-dom'

export default function FacilityList  (props) {
    
    
    return (
        <ul className="list-disc">
            
            {props.Facilitys.map((singleF)=>(
                <li className="m-3" key={singleF.CampusID}><Link to={`/auditsettings-area/${singleF.CampusID}`}>{singleF.CampusName}</Link></li>
            )
            )}
            
        </ul>
    );
}

