import React from 'react';
import {Link} from 'react-router-dom'

export default function AreaList  (props) {
    
    
    return (
        <ul className="list-disc">
            
            {props.Areas.map((singleA)=>(
                <li className="m-3" key={singleA.AreaID}><Link to={`/auditsettings-areadetail/${singleA.AreaID}`}>{singleA.AreaName}</Link></li>
            )
            )}
            
        </ul>
    );
}

