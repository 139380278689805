import React from 'react'
import {useLocation} from 'react-router-dom'
import Centered from './centered'
import Empty from './empty'
import Layout1 from './layout-1'

const Layouts = ({children}) => {
  let location = useLocation()
  let {pathname} = {...location}
  console.log(pathname)
  if (
    [
      '/login-1',
      '/contact-us-1',
      '/create-account',
      '/email-confirmation',
      '/logout',
      '/reset-password',
      '/forgot-password',
      '/lock-screen',
      '/subscribe',
      '/',
      '/error-page',
      '/coming-soon',
    ].includes(pathname)
  ) {
    return <Centered>{children}</Centered>
  } else if (['/login-2', '/login-3','/reportservice-invoice'].includes(pathname)) {
    return <Empty>{children}</Empty>
  }
  else if (pathname.includes('service-report')) {
    return <Empty>{children}</Empty>
  }
  else if (pathname.includes('service-summary')) {
    return <Empty>{children}</Empty>
  }
  else if (pathname.includes('reportservice-invoice')) {
    return <Empty>{children}</Empty>
  }
  else {
    return <Layout1>{children}</Layout1>
  }
}

export default Layouts
