import React, {Component} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
class MyBarChart extends Component {
    componentDidMount() {
      let chart = am4core.create("chartdiv", am4charts.XYChart);
      chart.dateFormatter.dateFormat = "MM/DD/YYYY"; //  part  to help to remove bad labels
      chart.paddingRight = 20;

      let data = this.props.chartData
      chart.data = data;
console.log(data)
    
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category="Title"
      //don't show the labels!!!! they don't look good.  dates are too long
      categoryAxis.tooltip.disabled = true;
      categoryAxis.fontSize="0px"; // part  to help to remove bad labels
      //let label = categoryAxis.renderer.labels.template;
      //label.disabled=true;
    //   dateAxis.renderer.grid.template.location = 0;
    //   dateAxis.groupData = false;
    //   dateAxis.baseInterval={
    //       "timeUnit":"second",
    //       "count":1
    //   }
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
      
      let title = chart.titles.create();
      title.text="ATP Audit samples";
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "Title"
      console.log(series.dataFields.categoryX)
    
      series.dataFields.valueY = "Result";
      series.tooltipText = ` {tagTitle} 
      {tagTitle2}
      ----
      Amount: {Result} RLU
      `;

      chart.cursor = new am4charts.XYCursor();
  
      let scrollbarX = new am4charts.XYChartScrollbar();
      //scrollbarX.renderer.labels.template.disabled=true;

      scrollbarX.series.push(series);
      
      chart.scrollbarX = scrollbarX;
    
      this.chart = chart;
    }
  
    componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }
  
    render() {
      return (
        <div id="chartdiv" style={{ width: "100%", height: "600px" }}></div>
      );
    }
  }
  export default MyBarChart