import React, {useEffect, useState} from 'react'
import axios from 'axios'
import moment from 'moment'
import {Link} from 'react-router-dom'

function ServiceInvoices (props) {
  const[serviceInv, setServiceInv]=useState([])
  
  useEffect(()=>{
    const options =   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/order/invoices/${props.OrderNum}`,
    }
    const loadData = async() =>
    {
      const response = await axios(options)
      var dataResult=response.data
      
      setServiceInv(dataResult)
    }
    loadData()   
    
  },[])
  if (serviceInv) {
    return(
      <>
            {serviceInv.map((invData)=>(
                <span key={invData.InvoiceID}><Link to={`/reportservice-invoice/${invData.InvoiceID}`}>{invData.InvoiceID}</Link>,  </span> 
            )
            )}
      </>
    )
} else {
  return(
    <>
      
        Loading Invoices
      
    </>
  )
}
}
export default ServiceInvoices
