import React, {useEffect, useState} from 'react'
import Widget from '../../components/widget'
import axios from 'axios'
import moment, { max } from 'moment'
import logo from '../../images/gblogo.png'
import {useParams} from 'react-router-dom'
import ucwords from 'ucwords'
import ATPPreVertical from '../../components/charts/amcharts-atp-vertical-bar'
import ATPAvgRpt from '../../components/charts/amcharts-svcrpt-avg'
import ATPStDev from '../../components/charts/amcharts-svcrpt-stdev'
import '../../styles/Printer-Friendly.css'

export default function ServiceReport () {
  const[serviceData, setServiceData]=useState({})
  const [customerData, setCustomerData]=useState()
  const [outliers, setOutliers]=useState()
  const [stats, setStats] = useState()
  const [preATP, setPreATP]=useState()
  const {orderID} =useParams() 
  
    

  useEffect(()=>{
    const loadData = async() =>
    {
      const response = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
        url: `https://gbmercury.com/order/single/${orderID}`,
      })
      setServiceData(response.data[0])      
      console.log(response.data[0])
      const responseCust = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/customer/id/${response.data[0].CustID}`,
      })
      setCustomerData(responseCust.data[0])

      const responseOutlier = await axios({
        method: 'post',
        data: {
        'dateTime':response.data[0].CompDate,
        'outlier':500,
        'custid':response.data[0].CustID},
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/atps/outliers`,
      })
      setOutliers(responseOutlier.data)
      const responseStats = await axios({
        method: 'post',
        data: {
        'dateTime':response.data[0].CompDate,
        'clientid':response.data[0].CustID},
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/clientstats/gsreport`,
      })
      var dataResultStat=responseStats.data
        dataResultStat.map((e)=>{
          e.timeStamp >0 ? e.PrettyDate=moment(e.timeStamp*1000).format("MM/DD/YYYY"):e.PrettyDate="Missing Date Info"
          e.threshold=200
          e.ATPVal=e.PreATPAvg.toFixed(0)
          e.ATPStDev=e.PreATPStDev.toFixed(0)
          
        })
        setStats(responseStats.data)
        console.log(responseStats.data)

      const responsePre = await axios({
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/atps/pre/orderid/${orderID}`,
        })
        var dataResult=responsePre.data
        setPreATP(responsePre.data)


      
            
     
    }

    loadData()   
    
  },[])
  if (serviceData) {
    return(
      <>

        <div className="p-4 max-w-screen-lg">
          <div className="flex flex-row mb-2 justify-between">
          <div className="justify-start space-x-2 text-blue-500">
                <span className="text-2xl uppercase text-blue-500 font-bold">Environmental Data Trends Report</span>
              </div>
              <div className="items-center justify-start space-x-2 text-blue-500">
                <img style={{height:90+ 'px'}} src={logo}/>
              </div>
            </div>
            <div className="">
              <div className="text-blue-500 text-xl uppercase font-bold mb-2">
              Introduction
              </div>
              <div className="pb-4">
              The following report analyzes data collected during GermBlast service at customerData.CustomerCName. GermBlast swabs areas in the environment and uses an adenosine triphosphate (ATP) meter to collect quantitative data from the environment. ATP is a coenzyme contained in all living cells and by measuring the ATP level on a surface, GermBlast technicians can evaluate the amount of living matter present in the environment. ATP samples are collected over time and analyzed to detect trends and potential hazards in the environment. This report contains ATP sample information collected before each GermBlast service.


              </div>
              
            </div>

            <div className="">
              <div className="text-blue-500 text-xl uppercase font-bold mb-2">
              Pre-Service ATP Samples
              </div>
              <div className="pb-4">
              GermBlast Service Technicians collect ATP samples before and after each service. The pre-service sample Average (Mean) is {serviceData ? serviceData.PreATPAvg : null} RLU and the Standard Deviation is {serviceData ? serviceData.PreATPStDev : null}. The bar chart below outlines each sample collected.
              { preATP ? 
              <>
              <ATPPreVertical chartData={preATP}/> 
              <div className="p-4 max-w-screen-lg">
                <table className="w-full">
                  <thead>
                    <tr className="bg-grey-200 p-2 break-clean">
                      <th className="text-left pl-2">Date</th>
                      <th className="text-left pl-2">Location</th>
                      <th className="text-right pl-2">ATP Value</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                  preATP ? 
                  preATP.map((f)=>(
                    <tr key={f.ATPID} className="border-2 border-grey-400 hover:bg-grey-100 break-clean">
                      <td className="pl-2"><span className="text-md">{moment(f.ATPDate*1000).format("MM/DD/YYYY")}</span></td>
                      <td className="pl-2"><span className="text-md">{f.LocName} {ucwords(f.ATPloc)}</span></td>
                      <td className="text-right"><span className="text-md text-right">{f.ATPVal} RLU</span></td>
                      </tr>
                  ))
                  :
                  null
                }
                </tbody>
                </table>
                
              </div>
              </>
              : 
              <span>Loading</span>}
              </div>
              
            </div>


            <div className="">
              <div className="text-blue-500 text-xl uppercase font-bold mb-2">
              Trend Analysis
              </div>
              <div className="pb-4">
              Trend analysis allows GermBlast to look at data over the course of several services and determine the direction of the average and standard deviation. These values help GermBlast technicians detect trends over time and address challenge areas. Trend analysis utilizes site survey and pre-service ATP data only because these values represent the point in time in which the highest samples will be present.

              </div>
              { stats ? 
              <div className="p-4 max-w-screen-lg">
                <table className="w-full">
                  <thead>
                    <tr className="bg-grey-200 break-clean p-2">
                      <th className="text-left pl-2">Date</th>
                      <th className="text-right pl-2">ATP Average</th>
                      <th className="text-right pl-2">ATP Standard Deviation</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                  stats.map((f)=>(
                    <tr key={f.ATPID} className="border-2 break-clean border-grey-400 hover:bg-grey-100">
                      <td className="pl-2"><span className="text-md">{f.PrettyDate}</span></td>
                      <td className="text-right"><span className="text-md text-right">{f.ATPVal} RLU</span></td>
                      <td className="text-right"><span className="text-md text-right">{f.ATPStDev}</span></td>
                      </tr>
                  ))
                 }
                </tbody>
                </table>
                
              </div>
              : 
              null}
              <div className="text-blue-500 text-xl uppercase font-bold mb-2 break-clean">
              Pre-Service Average
              </div>
              { stats ? 
              <div className="pb-4 break-clean">
              <ATPAvgRpt chartData={stats} /> 
              
              </div>
              : 
              null}
              <div className="pb-4 break-clean">
              ATP average is a good indicator of the overall cleanliness of the environment.  Tracking this over time helps detect changes and their root cause.  GermBlast recommends that clients strive for an average ATP of 200 RLU or less.  However, all environments are unique and might not achieve this.  Ultimately, a lower value is always better as it indicates less bioburden present on environmental surfaces.
              </div>
              <div className="text-blue-500 text-xl uppercase font-bold mb-2">
              Pre-Service Standard Deviation
              </div>
              { stats ? 
              <>
              <ATPStDev chartData={stats} /> 
              
              </>
              : 
              <span>Loading</span>}
              <div className="pb-4">
              Standard deviation is another key statistic used in ATP analysis, measuring variability and revealing inconsistencies in the environment. While there is no target number for standard deviation, lower is always better.  If changes are detected in either statistic, average or standard deviation, it is best to analyze both statistics to better understand the root cause.  The following are scenarios to review:
              <ul>
                <li className="p-3"><span className="font-bold">High Average, Low Standard Deviation -</span> This scenario indicates that samples all clustered very closely to the average value, meaning a less clean environment throughout.  Systemic or seasonal changes should be examined in this scenario.</li>
                <li className="p-3"><span className="font-bold">High Average, High Standard Deviation -</span> This scenario indicates that vary greatly, and suggests an inconsistent level of cleanliness throughout the environment.  In this scenario, the outliers table should be reviewed to determine if a specific area or item is responsible for the change.</li>
                <li className="p-3"><span className="font-bold">Low Average, High Standard Deviation -</span> This scenario indicates that the environment is mostly clean but a few trouble areas are present that increase the standard deviation.  In this case, review the outliers table for areas or items of concern.</li>
                <li className="p-3"><span className="font-bold">Low Average, Low Standard Deviation -</span> This is the optimal scenario.  Consistent averages and standard deviations indicate that environmental controls are effective.  Low is a relative term, and is judged based on the history of the data over time.</li>
                </ul> 
              </div>
              
              
                  {
                  outliers ? 
                  <>
                  <div className="text-blue-500 text-xl uppercase font-bold mb-2">
                  ATP Outliers
                  </div>
                  <div className="pb-4">
                  Outliers are ATP samples that reveal a high level of bioburden present and highlight potential areas of infection for patients and staff. Separating these samples for analysis helps determine areas for improvement and helps in root cause analysis investigations. The table below provides a list of all outliers detected during recent services.
                  </div>
                  <div className="p-4 max-w-screen-lg">
                    <table className="w-full">
                      <thead>
                        <tr className="bg-grey-200 p-2 break-clean">
                          <th className="text-left pl-2">Date</th>
                          <th className="text-left pl-2">Location</th>
                          <th className="text-right pl-2">ATP Value</th>
                        </tr>
                      </thead>
                      <tbody>
                  {outliers.map((f)=>(
                    <tr key={f.ATPID} className="border-2 border-grey-400 hover:bg-grey-100 break-clean">
                      <td className="pl-2"><span className="text-md">{moment(f.ATPDate*1000).format("MM/DD/YYYY")}</span></td>
                      <td className="pl-2"><span className="text-md">{f.LocName} {ucwords(f.ATPloc)}</span></td>
                      <td className="text-right"><span className="text-md text-right">{f.ATPPreCount} RLU</span></td>
                      </tr>
                  ))}
                    </tbody>
                    </table>
                    </div>
                  </>
                  :
                  null
                }
                
                
              

         
            </div>
            
    
          




        
        </div>
    </>
    )
} else {
  return(
    <>
      <Widget>
        <div className="p-4 max-w-screen-lg">
          <div className="flex flex-row items-center  mb-2 ">
            <div className="flex flex-col">
              <span className="text-blue-500 text-4xl uppercase font-bold">
                Service Summary
              </span>
              <span className="text-grey-700 text-lg">Order #{orderID}</span>
            </div>
            <div className="uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start whitespace-no-wrap">
              <div className="flex flex-row items-center justify-start space-x-2 text-blue-500">
                <img style={{height:90+ 'px'}} src={logo}/>
              </div>
            </div>
          </div>
        </div>
      </Widget>
      
    </>
  )
}
}