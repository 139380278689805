import React, {useState, useEffect} from 'react';
import axios from 'axios'
export default function EmployeeList  (props) {
    const [locationData, setLocationData]=useState()
    useEffect(()=>{
        const loadData = async() =>
        {
          const responseLocs = await axios({
            method: 'get',
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: `https://gbmercury.com/order/locations/${props.CalID}`,
          })
          setLocationData(responseLocs.data)
          console.log(responseLocs.data)
        }
        loadData()   
      },[])
      if (locationData) {
        
            return (
                <ul className="list-none">
                    
                    {locationData.map((singleL, index)=>(
                        <li className="m-3" key={index}><span className="font-bold">{singleL.LocationName}</span>   {singleL.Address}  {singleL.Address2}   {singleL.City}, {singleL.State} {singleL.Zip}
                        </li>
                    )
                    )}
                    
                </ul>
            )
    } else {
        return (
           <span>Loading...</span>
      )
    }
    
     
}

