import React from 'react';
import {Link} from 'react-router-dom'

export default function TemplateList  (props) {
    
    
    return (
        <ul className="list-disc">
            
            {props.Templates.map((singleT)=>(
                <li className="m-3" key={singleT.AuditID}><Link to={`/auditsettings-templateedit/${singleT.AuditID}`}>{singleT.Name}</Link></li>
            )
            )}
            
        </ul>
    );
}

