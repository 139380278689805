import React, { useEffect, useState, useContext } from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import MyBarChart from '../../components/charts/am5charts-trend-atp'
import axios from 'axios'
import moment from 'moment'
import { ClientContext } from '../../providers/ClientProvider'
import { CSVLink} from 'react-csv'

function ATPYear() {
    // eslint-disable-next-line
    const [client, setClient] = useContext(ClientContext)
    const [allATP, setAllATP] = useState()
    const [csvExport, setCSVExport] = useState({})
    var yearData = []
    var yearCount = []
    var yearRaw = []
    var yearFinal = []
    var dataResult = []
    useEffect(() => {
        const options = {
            method: 'get',
            headers: {
                Accept: 'application/json',
                culper: 'culper',
                'Content-Type': 'application/json',
            },
            url: `https://gbmercury.com/atps/gs/indiv/customer/${client}`,
        }
        const loadData = async () => {
            var csvBuilder = []
            var tempVal = 0
            const response = await axios(options)
            dataResult = response.data
            dataResult.sort((a, b) => (a.ATPDate > b.ATPDate ? 1 : -1))
            dataResult.map((e) => {
                e.ATPDate = e.ATPDate * 1000
                e.formattedDate = moment(e.ATPDate).format(
                    'MM/DD/YYYY hh:mm:ss a'
                )
                e.monthYear = moment(e.ATPDate).format('MM/YYYY')
                e.quarterYear = moment(e.ATPDate).format('Q/YYYY')
                e.Year = moment(e.ATPDate).format('YYYY')
                !isNaN(yearData[e.Year])
                    ? (tempVal = yearData[e.Year])
                    : (tempVal = 0)
                yearData[e.Year] = e.ATPVal + tempVal
                !isNaN(yearCount[e.Year])
                    ? (tempVal = yearCount[e.Year])
                    : (tempVal = 0)
                yearCount[e.Year] = tempVal + 1
                yearRaw[e.Year] = e.ATPDate
            })
            Object.entries(yearCount).map(([k, v]) => {
                var average = Math.round(yearData[k] / v)
                const myObj = {
                    Raw: yearRaw[k],
                    Title: k,
                    Average: average,
                    Total: yearData[k],
                    Count: v,
                }
                csvBuilder.push({
                    Year: k,
                    'ATP Average': average,
                })
                yearFinal.push(myObj)
            })
            //not needed for some reason? reverses the sort
            //yearFinal.sort((a,b)=> a.raw > b.raw ? 1 : -1)
            setCSVExport(csvBuilder)
            setAllATP(yearFinal)
        }
        if(client>0){    
            loadData()
        }
    }, [client])
    return (
        <>
        { allATP ? 
            <>
            <SectionTitle title='Trend Reports' subtitle='Monthly Trends' />
            <Widget
                title='Month Chart'
                description={
                    <span>See a chart with averages by year</span>
                }
            >
                <MyBarChart chartData={allATP} />
                <CSVLink
                    data={csvExport}
                    filename={'atp-average-by-year.csv'}
                >
                    Download records to CSV
                </CSVLink>
            </Widget>
            </>
            :
            null
        }
        </>
        
    )
}
export default ATPYear
