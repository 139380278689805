import React from 'react';
import { RingLoader } from 'react-spinners'
const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function GBRingLoader () {
    return (
      <div>
        <RingLoader color="#fdb913" loading={true} css={override} size={150}/>
        <h3 className="text-center">Loading...</h3>
        </div>
    );
}
