import React from 'react'
import {
  FiGlobe,
  FiTwitter,
  FiFacebook,
  FiInstagram,
  FiLinkedin
} from 'react-icons/fi'

const Apps = () => {
  const items = [
    {
      title: 'GermBlast',
      icon: <FiGlobe className="stroke-current text-xl text-web" />,
      site: "https://www.germblast.com",
    },
    {
      title: 'Twitter',
      icon: <FiTwitter className="stroke-current text-xl text-twitter" />,
      site: "https://www.twitter.com/germblast",
    },
    {
      title: 'Facebook',
      icon: <FiFacebook className="stroke-current text-xl text-facebook" />,
      site: "https://www.facebook.com/germblast",

    },
    {
      title: 'Instagram',
      icon: <FiInstagram className="stroke-current text-xl text-instagram" />,
      site: "https://www.instagram.com/germblast",

    },
    {
      title: 'LinkedIn',
      icon: <FiLinkedin className="stroke-current text-xl text-linkedin" />,
      site: "https://www.linkedin.com/germblast",
    }
  ]
  return (
    <>
      <div className="dropdown-title">Web & Socials</div>
      <div className="flex flex-wrap text-center">
        {items.map((item, i) => (
          
            <div
            key={i}
            className="w-1/3 flex flex-col items-center justify-center h-20 space-y-1 dropdown-item">
            <a href={item.site} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center justify-center h-20 space-y-1 dropdown-item">  
            {item.icon}
            
            <span className="text-xs">{item.title}</span>
            </a>
          </div>
          
        ))}
      </div>
    </>
  )
}

export default Apps
