import React, {useContext} from 'react'
import Section from '../../components/dashboard/section'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import  {UserContext} from "../../providers/UserProvider"
import ReactPlayer from 'react-player'
import VideoComponent from './VideoComponent'
import axios from 'axios'
import moment from 'moment'

export default function ResourcesVideo  () {
  const user = useContext(UserContext)
  const userFeed={userid: user.userid,
  username:user.esigname,clientID: user.CustID }
  const videoLibrary=[{
    videoName:'Safe@School Wear Your Mask',
    videoDescription:'Learn how masks stop the spread of germs',
    videoUrl:'https://vimeo.com/444681745/e9586188cd',
  },
  {
    videoName:'Safe@School Social Distancing',
    videoDescription:'How do you social distance?  What does 6 feet look like?',
    videoUrl:'https://vimeo.com/460234298/d0949db8e9',
  },
  {
    videoName:"Safe@School Don't Touch Your Face",
    videoDescription:'Learn why touching your face increases your risk to get sick.',
    videoUrl:'https://vimeo.com/449421174/a9b8c83436',
  },
  {
    videoName:'Safe@School Cover Your Cough',
    videoDescription:'Learn why it is important to cover your cough',
    videoUrl:'https://vimeo.com/449353417/c69969af5c',
  },
  {
    videoName:'Safe@School How to Wash Your Hands',
    videoDescription:'Learn how to wash your hands the best way to prevent the spread of germs',
    videoUrl:'https://vimeo.com/449421237/639f9abd7f',
  },
  {
    videoName:'Cleaning & Disinfection Best Practices',
    videoDescription:'Dr. Truitt provides the current best practice approaches to cleaning & disinfection.',
    videoUrl:'https://vimeo.com/356980584/cac5b3cc42',
  },
  {
    videoName:'MDRO Training',
    videoDescription:'Dr. Truitt reviews multi-drug resistant organisms and how to protect your team from them.',
    videoUrl:'https://vimeo.com/356978698/7a5d9faa6a',
  },
  {
    videoName:'Cleaning & Disinfecting in a COVID-19 World - English',
    videoDescription:'Dr. Truitt reviews discusses the best approaches to protecting your environment from coronavirus',
    videoUrl:'https://vimeo.com/444684444/d3c63844e9',
  },
  {
    videoName:'Cleaning & Disinfecting in a COVID-19 World - Spanish',
    videoDescription:'Yassiel Ibarra discusses the best approaches to protecting your environment from coronavirus.',
    videoUrl:'https://vimeo.com/473857371/29ef73e608',
  },
]
  return(
  <>
    <SectionTitle title="Resources" subtitle="Video Library" />
    
    <div className="flex flex-row flex-wrap">
        {
          videoLibrary.map((v)=> <VideoComponent video={v} user={userFeed}/>
          )
        }
  
  </div>

  
    


  </>
)}
