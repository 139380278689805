import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import AreaList from './AreaList'
import {Link, useParams} from 'react-router-dom'
import { useSnackbar } from 'notistack'
export default function AuditSettingsArea (props) {
  const { enqueueSnackbar} = useSnackbar()
    const user = useContext(UserContext);
    const {campusid} = useParams()
    const [campusDetail, setCampusDetail] = useState({})
    const [areaName, setAreaName] = useState('');
    const onChangeHandler = (event) => {
      const {name, value} = event.currentTarget;
      if(name === 'AreaName') {
          setAreaName(value);
      }
  };
  const [cards, setCards]=useState([])
    const addArea = async(event) =>
      {
          event.preventDefault();  
          const options =   
          {
            method: 'post',
            data: {'areaName':areaName,
                'campusID':campusid,
                  'clientID':user.CustID},
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: 'https://gbmercury.com/audit/area/new',
          }
          
          const response = await axios(options)
  
          setCards(existingCards=>[...existingCards,{AreaID:response.data.insertId,AreaName:areaName, Active:1, ATPAvg: 0, ATPStDev: 0, Score: 0}])
          console.log(response)
          setAreaName("")
          enqueueSnackbar('Area Added',{variant: 'success',});
      }
    useEffect(()=>{
      
      const loadData = async() =>
      {
        
        const response = await axios(
          {
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/audit/area/all/${campusid}`
        })
        setCards(response.data)
        const responseC = await axios(
          {
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/audit/facility/${campusid}`
        }
        )
        setCampusDetail(responseC.data[0])
      }
      loadData()   
      
    },[campusid])


    return (
        <>
        <Link to={`/auditsettings-facility`}>back to campus list</Link>
        <SectionTitle title="Campus Areas" subtitle={campusDetail.CampusName} />
    <Widget title="Area List" description={<span>Add/Edit Areas Here</span>}>
    <form >
            <label>Area Name</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="AreaName"
            value = {areaName}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!areaName}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {addArea(event)}}
            
            >
                Add New Area
            </button>
            

            </form>
    </Widget>
    <Widget title="Area List" description={<span>Manage the Areas for this Campus Here</span>}>
    
    <AreaList Areas={cards}/>
    </Widget>
        </>
    );
}