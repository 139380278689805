import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import {UserContext} from "../../providers/UserProvider";
import TemplateList from './TemplateList'
import {ClientContext} from '../../providers/ClientProvider'

export default function AuditSettingsTemplate() {
  const [client, setClient] = useContext(ClientContext)
  const user = useContext(UserContext);
  const [templateName, setTemplateName] = useState('');
  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
      if(name === 'TemplateName') {
          setTemplateName(value);
      }
    };
const [cards, setCards]=useState([])
  const addTemplate = async(event) =>
    {
        event.preventDefault();  
        const options =   
        {
          method: 'post',
          data: {'templateName':templateName,
                'clientID':user.CustID},
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/audit/audit/new',
        }
        
        const response = await axios(options)

        setCards(existingCards=>[...existingCards,{AuditID:response.data.insertId,Name:templateName, Active:1}])
        console.log(response)
        setTemplateName("")
        
    }
  useEffect(()=>{
    
    const loadData = async() =>
    {
      
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/audit/audit/all/${client}`
      }
      )
      setCards(response.data)
    }
    loadData()   
    
  },[])
  return (
  <>
    <SectionTitle title="Pages" subtitle="Settings - Audit Templates" />
    <Widget title="New Audit Template" description={<span>Add An Audit Templates Here</span>}>
    <form >
            <label>Template Name</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="TemplateName"
            value = {templateName}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!templateName}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {addTemplate(event)}}
            
            >
                Add New Template
            </button>
            

            </form>
    </Widget>
    <Widget title="Audit Template List" description={<span>Manage Your Audit Templates Here</span>}>
    
    <TemplateList Templates={cards}/>
    </Widget>
  </>
)}
