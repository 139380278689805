import React, { useContext, useEffect, useState } from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import MyBarChart from '../../components/charts/am5charts-all-atp'
import axios from 'axios'
import moment from 'moment'
import { ClientContext } from '../../providers/ClientProvider'
import { CSVLink} from 'react-csv'
import GBRingLoader from '../../components/GBRingLoader/GBRingLoader'

// import ATPHorizBar from '../../components/charts/Airthinx/ATPHorizBar/ATPHorizBar'

function ATPAll() {
    // eslint-disable-next-line
    const [client, setClient] = useContext(ClientContext)
    const [allATP, setAllATP] = useState()
    const [csvExport, setCSVExport] = useState({})
    var dataResult = []
    useEffect(() => {
        if (client > 1) {
            const options = {
                method: 'get',
                headers: {
                    Accept: 'application/json',
                    culper: 'culper',
                    'Content-Type': 'application/json',
                },
                url: `https://gbmercury.com/atps/gs/indiv/customerzone/${client}`,
            }
            //console.log(options)
            const loadData = async () => {
                var csvBuilder = []
                const response = await axios(options)
                dataResult = response.data
                dataResult.sort((a, b) => (a.ATPDate > b.ATPDate ? 1 : -1))
                dataResult.map((e) => {
                    e.ATPDate = e.ATPDate * 1000
                    e.formattedDate = moment(e.ATPDate).format(
                        'MM/DD/YYYY hh:mm:ss a'
                    )
                    csvBuilder.push({
                        'Sample Date': e.formattedDate,
                        'Sample Location': e.Location,
                        'ATP Value': e.ATPVal,
                        'Facility/Area': e.Facility
                    })
                })
                setCSVExport(csvBuilder)
                setAllATP(dataResult)
                console.log("data loaded")
            }
            if(client>0)
            {
                loadData()
            }
        }
    }, [client])
    if (allATP) {
        return (
            <>
                <SectionTitle title='Pages' subtitle='All ATP Samples' />
                <Widget
                    title='Page title'
                    description={<span>See a chart with all samples here</span>}
                >
                    {/* <ATPHorizBar chartData={allATP} title="All ATP Data"/> */}
                    <MyBarChart chartData={allATP} />
                    <CSVLink data={csvExport} filename={'all-atp-samples.csv'}>
                        Download records to CSV
                    </CSVLink>
                    
                </Widget>
            </>
        )
    } else {
        return (
            <>
                <SectionTitle title='Pages' subtitle='All ATP Samples' />
                <Widget
                    title='Page title'
                    description={<span>See a chart with all samples here</span>}
                >
                    <GBRingLoader/>
                </Widget>
            </>
        )
    }
}
export default ATPAll
