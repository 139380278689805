import React from 'react'
import {Link} from 'react-router-dom'
import {FiUser, FiLogIn} from 'react-icons/fi'
import {auth } from '../../firebase'
const AccountLinks = () => {

  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
          <li key="0" className="dropdown-item">
            <Link
              to='/extras/user-profile'
              className="flex flex-row items-center justify-start h-10 w-full px-2">
              <FiUser size={18} className="stroke-current" />
              <span className="mx-2">Profile</span>
            </Link>
          </li>
          <li key="1" className="dropdown-item" onClick = {() => {auth.signOut()}}>
          <span className="flex flex-row items-center justify-start h-10 w-full px-2">
            <FiLogIn size={18} className="stroke-current" />
            <span className="mx-2">Logout</span>
            
          </span>
        </li>
      </ul>
    </div>
  )
}

export default AccountLinks
