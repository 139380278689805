
import React, {useState, useEffect} from 'react';
import axios from 'axios';


//import { FormGroup } from '@material-ui/core';

//import {CheckIt} from '../useAirthinx';
import {Grid, Button} from '@material-ui/core';
import { withRouter } from 'react-router-dom';


import Co2Chart from '../components/charts/Airthinx/Co2Chart'
import EtohChart from '../components/charts/Airthinx/etohchart'
import IsobutyleneChart from '../components/charts/Airthinx/IsobutyleneChart';
import PM25Chart from '../components/charts/Airthinx/Pm25Chart'
import PmTenChart from '../components/charts/Airthinx/PMTenChart'
import CH20Chart from '../components/charts/Airthinx/CH20Chart'
import TemperatureFChart from '../components/charts/Airthinx/TempFChart'
import TemperatureCChart from '../components/charts/Airthinx/TempCChart'
import DewPointCChart from '../components/charts/Airthinx/DewpointCChart'
import DewPointFChart from '../components/charts/Airthinx/DewpointFChart'
import HumidityChart from '../components/charts/Airthinx/HumidityChart'
import PressureChart from '../components/charts/Airthinx/PressureChart'
import AirQualityChart from '../components/charts/Airthinx/AirQualityChart'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const AirThinxChart=(props)=>{

    const [measurements, setMeasurements]=useState([])
    const [showAQ, setShowAq]=useState(false);
    const [showEtoh, setShowEtoh]=useState(false);
    const [showCo2, setShowCo2]=useState(true);
    const [showIsobtyn, setShowIsobtyn]=useState(false);
    const [showPM25, setShowPM25]=useState(true);
    const [showPM10, setShowPM10]=useState(true);
    const [showCH20, setShowCH20]=useState(false);
    const [showTempF, setShowTempF]=useState(true);
    const [showTempC, setShowTempC]=useState(false);
    const [showDpF, setShowDpF]=useState(false);
    const [showDpC, setShowDpC]=useState(false);
    const [showHumidity, setShowHumidity]=useState(false);
    const [showPressure, setShowPressure]=useState(false);
    const [selectedDate, setSelectedDate] = useState(Math.round(new Date().getTime()-86400000));
    const [selectedEndDate, setSelectedEndDate] = useState(Math.round(new Date().getTime()));
    const [nodeId, setNodeId]=useState('')
    const [descriptionData, setDescriptionData]= useState('')
   const nodeid=props.match.params.id
   console.log(props.match)

    const handleSubmit = (selectedDate, selectedEndDate, nodeid) => {
        const options =   {
         method: 'post',
         headers: {'Accept':'application/json',
           'Authorization':'Bearer 301cf2e5-5ed7-40e0-bade-d91f0d2fb7ca',
           'Content-Type':'application/json'},
         url: 'https://api.environet.io/search/nodes',
         data: {
          
           'node_id': nodeid,
           "from": selectedDate, //state
           "to": selectedEndDate, // stat
        
         } 

       }




        axios(options ).then(response => { 
         console.log(response.data[0])
         console.log(response.data[0].description)
        setMeasurements(response.data[0].data_points)
        setDescriptionData(props.match.params.name)

          return measurements
       }).catch(err=>{
         console.log(err + ' there was an error')
       })
     }
    console.log(measurements) 


var addressData=[]
var CO2Data=[]

var timestampsData=[]   

useEffect( () => {
  handleSubmit(selectedDate, selectedEndDate, nodeid);
 
  }, [selectedDate, selectedEndDate ]
 );


measurements.map((s) => (
        CheckIt(s)
        
        ))
        
     function CheckIt(Item)
       {
        if(Item.name==="Location Description")
        {
            
            addressData=Item.measurements[0][1];
        }
         
 
       }
console.log(timestampsData)


  const dataCo2 = {
    labels: timestampsData  ,
    datasets: [
      { 
        backgroundColor: ['rgba(75, 192, 102, 0.6)', 'rgba(255, 99, 132, 0.6)', 
        'rgba(255, 206, 86,0.6)', 'rgba(75, 192, 102, 0.6)',  'rgba(153, 102, 255, 0.6)'],
        borderColor: 'black',
        borderWidth: 3,
        hoverBackgroundColor: 'rgb(91, 86, 113)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        barPercentage:.7,
        data: CO2Data    }, 
  ], 
  
    };
    const handleTemp = () => {
      setShowTempF(!showTempF);
      setShowTempC(!showTempC)
    };
    const handleDp = () => {
      setShowDpF(!showDpF);
      setShowDpC(!showDpC)
    };

    const handleDateChange = (date) => {
      setSelectedDate(date.getTime());
    console.log(date.getTime() + ' bottle')};
    const handleEndDateChange = (date) => { setSelectedEndDate(date.getTime());};
    console.log(timestampsData)

    return (
        <div>

<Grid   justify="center" container spacing={1} >
<Grid  
      item xl={8} lg={8} md={6} sm={6} xs={12}> 
<h3>{descriptionData}</h3>
</Grid>
<br></br>
<Grid  
      item xl={8} lg={8} md={8} sm={10} xs={12}> 
  {/* {showTempF ? 
  (<Button variant="outlined" color="primary" onClick={()=> handleTemp()} >View Temperature C</Button>) :
(<Button variant="outlined" color="primary" onClick={e => handleTemp()} >View Temperature F</Button>)} */}

{showTempF ? (<Button variant="outlined" color="secondary" onClick={e => setShowTempF(!showTempF)} >Hide Temperature F</Button>) :
(<Button variant="outlined" color="primary" onClick={e => setShowTempF(!showTempF)} >View Temperature F</Button>)}



{showEtoh ? (<Button variant="outlined" color="secondary" onClick={e => setShowEtoh(!showEtoh)} >Hide VOC(Etoh)</Button>) :
(<Button variant="outlined" color="primary" onClick={e => setShowEtoh(!showEtoh)} >View VOC(Etoh)</Button>)}


{showAQ? (<Button variant="outlined" color="secondary" onClick={e => setShowAq(!showAQ)} >Hide Air quality</Button>) :
 (<Button variant="outlined" color="primary" onClick={e => setShowAq(!showAQ)} >View Air quality</Button>)}



{showDpF? (<Button variant="outlined" color="secondary" onClick={e => setShowDpF(!showDpF)} >Hide DewPoint F</Button>) :
(<Button variant="outlined" color="primary" onClick={e => setShowDpF(!showDpF)} >View DewPoint F</Button>)}


{showPM10? (<Button variant="outlined" color="secondary" onClick={e => setShowPM10(!showPM10)} >Hide PM 10</Button>) :
(<Button variant="outlined" color="primary" onClick={e => setShowPM10(!showPM10)} >View PM 10</Button>)}

{showIsobtyn? (<Button variant="outlined" color="secondary" onClick={e => setShowIsobtyn(!showIsobtyn)} >Hide Isobutylene</Button>):
 (<Button variant="outlined" color="primary" onClick={e => setShowIsobtyn(!showIsobtyn)} >View Isobutylene</Button>)}



{showCH20 ? (<Button variant="outlined" color="secondary" onClick={e => setShowCH20(!showCH20)} >Hide CH₂O</Button>) :
 (<Button variant="outlined" color="primary" onClick={e => setShowCH20(!showCH20)} >View CH₂O</Button>)}


{showHumidity ? (<Button variant="outlined" color="secondary" onClick={e => setShowHumidity(!showHumidity)} >Hide Humidity</Button>)  : 
(<Button variant="outlined" color="primary" onClick={e => setShowHumidity(!showHumidity)} >View Humidity</Button>)}

{showPressure? (<Button variant="outlined" color="secondary" onClick={e => setShowPressure(!showPressure)} >Hide Pressure</Button>)  :
 (<Button variant="outlined" color="primary" onClick={e => setShowPressure(!showPressure)} >View Pressure</Button>)}


</Grid>

<Grid  item xl={10} lg={10} md={12} sm={12} xs={12}>
{/* <Button variant="outlined" color="primary"
 onClick={e => setNumerData(20)} >View amount</Button> */}

<MuiPickersUtilsProvider 
utils={DateFnsUtils}>
      <Grid container justify="space-evenly">
        <KeyboardDatePicker disableToolbar
          variant="inline" format="MM/dd/yyyy"
          margin="normal" id="Start-Date"
          label="Start Date" value={selectedDate}
          onChange={handleDateChange}
           KeyboardButtonProps={{ 'aria-label': 'change date', }} /> 
        
        <KeyboardDatePicker
          disableToolbar variant="inline"
          format="MM/dd/yyyy"  margin="normal"
          id="End-Date" label="End Date"
          value={selectedEndDate} onChange={handleEndDateChange}
          KeyboardButtonProps={{ 'aria-label': 'change date',}}/> 
          </Grid>
        </MuiPickersUtilsProvider>

{selectedEndDate > selectedDate ? (null) : (<p class="text-center" style={{'color': '#b82525', 'font-weight': 'bold'}}>End date must be greater than start date</p>)}

</Grid>



<br></br>
<br></br>

<Grid  item xl={4} lg={6} md={6} sm={10} xs={12}>
<Co2Chart  airthinxData={measurements}/>
</Grid>


<Grid  item xl={4} lg={6} md={6} sm={10} xs={12}>
<PM25Chart  airthinxData={measurements}/>
</Grid>





{showTempF ?  (<Grid item xl={4} lg={6} md={6} sm={12} xs={12}> 
<TemperatureFChart  airthinxData={measurements}/>
</Grid> ) : (null)}






{showPM10 ?  (<Grid item xl={4} lg={6} md={6} sm={12} xs={12}> 
<PmTenChart  airthinxData={measurements}/>
</Grid> ) : (null)}




{showAQ ?  (<Grid item xl={4} lg={6} md={6} sm={12} xs={12}> 
<AirQualityChart  airthinxData={measurements}/>
</Grid> ) : (null)}
  


<br></br>

{showEtoh ?  (<Grid  item xl={4} lg={6} md={6} sm={12} xs={12}> 
<EtohChart  m={measurements}/>
</Grid> ) : (null)}



{showIsobtyn ?  (<Grid  item xl={4} lg={6} md={6} sm={12} xs={12}> 
<IsobutyleneChart  airthinxData={measurements}/>
</Grid> ) : (null)}



{showCH20 ?  (<Grid item xl={4} lg={6} md={6} sm={12} xs={12}> 
<CH20Chart  airthinxData={measurements}/>
</Grid> ) : (null)}




{showDpF ?  (<Grid  item xl={4} lg={6} md={6} sm={12} xs={12}> 
<DewPointFChart  airthinxData={measurements}/>
</Grid> ) : (null)}



{showHumidity ?  (<Grid item xl={4} lg={6} md={6} sm={12} xs={12}> 
<HumidityChart  airthinxData={measurements}/>
</Grid> ) : (null)}


{showPressure ?  (<Grid item xl={4} lg={6} md={6} sm={12} xs={12}> 
<PressureChart  airthinxData={measurements}/>
</Grid> ) : (null)}

{/* 
if I wanted to add back in
onClick={() => props.history.push(`/T24hours/Pressure`)} */}


{/* only need pm2.5 & pm10 for now
 <Grid item  xl={4} lg={6} md={10} sm={6} xs={12}>
<PM1Chart  airthinxData={measurements}/>
</Grid>

<Grid item  xl={4} lg={6} md={6} sm={12} xs={12}>
<PMChart airthinxData={measurements} />
</Grid> */}


{/*  PC charts, not needed for now
<Grid item  xl={4} lg={6} md={6} sm={10} xs={12}>
<PC03Chart  airthinxData={measurements}/>
</Grid>

<Grid  item  xl={4} lg={6} md={6} sm={6} xs={12}>
<PC05Chart  airthinxData={measurements}/>
</Grid>

<Grid  item  xl={4} lg={6} md={6} sm={6} xs={12}>
<PC10Chart  airthinxData={measurements}/>
</Grid>

<Grid item  xl={4} lg={6} md={6} sm={6} xs={12}>
<PC25umChart  airthinxData={measurements}/>
</Grid>

<Grid item  xl={4} lg={6} md={6} sm={12} xs={12}>
<PC50umChart  airthinxData={measurements}/>
</Grid>

<Grid item  xl={4} lg={6} md={6} sm={12} xs={12}>
<PC100umChart  airthinxData={measurements}/>
</Grid> */}




</Grid>

        </div>
    )



}
export default withRouter(AirThinxChart);