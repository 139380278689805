import React from 'react';
import moment from 'moment'
import {Bar  } from 'react-chartjs-2'


export default function PersonnelHorizBar(props){
  var labels=[]
  var scores=[]
  props.data.map((e)=>
  {
    var dateFormatter=moment(e.DateTime*1000).format("MM/DD/YYYY")
    var labelMaker=`${dateFormatter} - ${e.ATPSite}`
    labels.push(labelMaker)
    scores.push(e.ATPResult)
  }
  )
  console.log(props)
  const data = {
    labels: labels,
    datasets: [
      {
        label: props.title,
        backgroundColor: "rgba(220,220,220,0.9)",
        pointBackgroundColor: "rgba(220,220,220,1)",
        data: scores
      }
    ]
  }
  const options = {
    legend: {
      position: "bottom",
      align: "start"
    },
  }
  
return(
  <>
  <Bar data={data} options={options} />
  </>
)
}