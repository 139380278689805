import React, {useState , useEffect, Fragment,useContext} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'
import HandHygieneDash from './HandHygieneDash'
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import { ClientContext } from '../../providers/ClientProvider'
import {Grid, Button} from '@material-ui/core';

const HandHygieneAudit=({match, history})=>{
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [client, setClient] = useContext(ClientContext)
    const AuditID=match.params.id
    const DeptID=match.params.deptid
    const [allProfession, setAllProfession]=useState()
    const [masterinfo, setMasterInfo ]=useState()
    const [profession, setProfession]=useState()
    const [activity, setActivity]=useState()
    const [moment, setMoment]=useState()
    const [glove, setGlove]=useState()
    const [options1, setOptions1]=useState()
    const [obsCount, setObsCount]=useState()
    const activityArray=[{Name:'Failure', id: 0},{Name: 'Handwash', id:1 },{Name:'Hand Sanitizer', id: 2}]
    const momentArray=[{Name:'Before patient contact', id: 1},{Name: 'Before Aseptic technique', id:2 },
    {Name: 'after body fluid exposure/risk', id:3 }, {Name:'After patient contact', id: 4},
    {Name:'After contact with environment', id: 5}]
    const gloveArray=[{Name:'Not applicable', id: 0},{Name: 'Incorrect', id:1 },{Name:'Correct', id: 2}]


    const addObservation = async(event) =>
    {
        event.preventDefault();  
        //const{name, value}=event.currentTarget
        console.log('part1')
        const options =   
        {
          method: 'post',
          data: {'auditID': AuditID,
                'clientID': 129,
                'userID': 1,
                'hhActivity': activity,
                'profession': profession,
                'hhMoment': moment,
                'hhGlove': glove,
                'deptID': DeptID
            
            },
                
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/handhygiene/observation/record',

        }
    
        console.log('part2')
        const response = await axios(options)
        const options2={
            method: 'get',
        
                  
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: `https://gbmercury.com/handhygiene/master/countone/${AuditID}`,
  
          }
          const response2 = await axios(options2)
setObsCount(response2.data.observationCount)
        console.log(response2.data.observationCount)
        console.log('part3')
        enqueueSnackbar('Observation Added',{variant: 'success',});
        setProfession()
        setActivity()
        setMoment()
        setGlove()
        
    }

console.log(AuditID + 'audit  ' + activity + ' activity ' + profession +' prof ' + 
moment + ' moment ' + glove + ' glove ' + DeptID + ' dept')

useEffect(()=>{

    const options=   
        {
        // from hhprofessions ==clientID
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/handhygiene/professions/129`, 
    }

    const options2=   
    {
        // from hhmaster ==id
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/handhygiene/master/${AuditID}`, 
    }

    const loadData=async()=>{


        axios.all([
            axios(options),
            axios(options2),
        ]).then(axios.spread(function(op1, op2){
            console.log(client)
        setAllProfession(op1.data)
        setMasterInfo(op2.data)
        setObsCount(op2.data[0].ObservationCount)
        console.log(op2.data[0])
        // setOpenAudit(op2.data)
        // setClosedAudit(op3.data)
       
        })).catch(err=>{
            console.log(err)
        })
        
        }// end of load data
        
loadData()


},[])

const saveProf=(id)=>{
    setProfession(id)
}
const saveActivity=(id)=>{
    setActivity(id)
}
const saveMoment=(id)=>{
    setMoment(id)
}
const saveGlove=(id)=>{
    setGlove(id)
}

console.log(allProfession )
if(allProfession && masterinfo){
    return(
        <Widget>
 <Button className="mb-4" variant="contained" color="primary" onClick={() => history.push(`/handhygienedash`)}> Back to Dashboard    </Button> 

<h4>Welcome to the hand hygiene audit page</h4>
<h5>Observation # {obsCount +1}</h5>
<hr></hr>
<Grid>
   
<div className="container text-center mx-auto p-7">
    
<p  className= "text-left ml-10">Profession</p>

<div className=" flex justify-center rounded-lg text-lg mb-4" >
<Grid item md={8} item xs={12 }>
    {allProfession.map(pro=>{
        return <span key={pro.id} >
            {(profession !=pro.id) ? (<button className=" flex-grow bg-blue-500 text-white hover:bg-green-700 border border-black  px-4 py-2 mx-0 outline-none focus:bg-green-700 "
        onClick={() => saveProf(pro.id)} > {pro.ProfessionName}</button>) :
        (<button  className=" flex-grow bg-green-700 text-white hover:bg-green-700 border border-black  px-4 py-2 mx-0 outline-none focus:bg-green-700 "
        onClick={() => saveProf(pro.id)} > {pro.ProfessionName}</button>)}
        </span>
    })}
    </Grid>
     </div>
</div>



<div className="container text-center mx-auto p-4">
<p  className= "text-left ml-10 ">Hand Hygiene Activity</p>
<div className=" flex justify-center rounded-lg text-lg mb-4" >
<Grid   item md={8} item xs={12 }>
    {activityArray.map(act=>{
        return  < span  key={act.id} >
      {(activity!=act.id) ?  (<button onClick={() => saveActivity(act.id)} 
         className="flex-grow bg-blue-500 text-white hover:bg-green-700 border border-black  px-4 py-2 mx-0 outline-none focus:bg-green-700 ">
            {act.Name} </button>) :
            (<button  onClick={() => saveActivity(act.id)} 
            className="flex-grow bg-green-700 text-white hover:bg-green-700 border border-black  px-4 py-2 mx-0 outline-none focus:bg-green-700 ">
               {act.Name} </button>)}
            </span> })}
            </Grid>
</div>
</div>



<div className="container text-center mx-auto p-4">
<p  className= "text-left ml-10 ">Hand Hygiene Moment</p>
<div className="flex justify-center rounded-lg text-md mb-4" >
<Grid  item md={8} item xs={12 }>
    {momentArray.map(mom=>{
        return <span key={mom.id}> 
        {(moment!=mom.id) ? (<button  onClick={() => saveMoment(mom.id)} 
            className="flex-grow bg-blue-400 text-white hover:bg-green-700 border border-black  px-4 py-2 mx-0 outline-none focus:bg-green-700 ">
                {mom.Name} </button>) :
         (<button  onClick={() => saveMoment(mom.id)} 
         className="flex-grow bg-green-700 text-white hover:bg-green-700 border border-black  px-4 py-2 mx-0 outline-none focus:bg-green-700 "> {mom.Name} </button>)}
        </span>
    })}
    </Grid>
</div>
</div>



<div className="container text-center mx-auto p-4">
<p className= "text-left ml-10">Glove Usage</p>
<div className="flex justify-center rounded-lg text-lg mb-4" >
<Grid  item md={8} item xs={12 }>
    {gloveArray.map(gl=>{
        return <span   key={gl.id}>
        {(glove!=gl.id)? (<button onClick={()=> saveGlove(gl.id)} 
        className="flex-grow bg-blue-400 text-white hover:bg-green-700 border border-black  px-4 py-2 mx-0 outline-none focus:bg-green-700 "> {gl.Name}</button>)
        :
    (<button onClick={() => saveGlove(gl.id)} 
    className="flex-grow bg-green-700 text-white hover:bg-green-700 border border-black  px-4 py-2 mx-0 outline-none focus:bg-green-700 "> 
    {gl.Name} </button>)}

        </span>
    })}
</Grid>
</div>


<button onClick={(event)=> {addObservation(event)}}  className="w-36 rounded bg-green-700 text-white hover:bg-green-300 border border-black my-6 px-4 py-2 mx-0 outline-none "
>Add Observation</button>

</div>

</Grid>
        </Widget>
    )
} else {
    return(
        <h3> Loading....</h3>
    )
}
}
export default withRouter(HandHygieneAudit)