import React from 'react';

export default function PersonnelCard  (props)  {
    let formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');
        
        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };
      
        return null
      };
    return (
        <>
            <img className="rounded-full h-32 w-32 mx-auto" src={`https://www.icimatrix.com/uploads/avatars/${props.person.userImage}`} alt={`Photo of ${props.person.esigname}`}/>
            <div className="text-xl mx-auto">{props.person.esigname}</div>
            <div className="text-l"><a href={`mailto:${props.person.email}`}>{props.person.email}</a></div>
            <div className="text-l"><a href={`tel:${props.person.cellPhone}`}>{formatPhoneNumber(props.person.cellPhone)}</a></div>
            
        </>
        );
}
