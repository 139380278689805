import React, {useContext, useEffect} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Layouts from '../../layouts'
import Routes from '../../Routes'
import '../../css/tailwind.css'
import '../../css/main.css'
import '../../css/animate.css'
import '../../css/_components.css'
import  {UserContext} from "../../providers/UserProvider";
import SignInGS from '../SignInGS'
import  {ClientContext} from "../../providers/ClientProvider";
import {SnackbarProvider} from 'notistack'
import Slide from '@material-ui/core/Slide';
const Wrapper = ({children}) => {
  return <Layouts>{children}</Layouts>
}

const Application = () => {
  const user = useContext(UserContext);
  const [client, setClient] = useContext(ClientContext)
  useEffect(()=>{
    if(user)
    {
      setClient(user.CustID)
    }
  },[user])
  return (
    !user ? 
        <SignInGS/> 
        :
        
    
      <Wrapper>
        <SnackbarProvider maxSnack={3} 
          autoHideDuration={1500}
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
          }
        }
        TransitionComponent={Slide}>
          <Routes />
        </SnackbarProvider>
      </Wrapper>
    
 )
}
export default Application
