import React, {useState, useEffect} from 'react';
import {Scatter} from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes'

export default function AttendanceScatter (props) {
    const [attendanceData, setAttendanceData]=useState([{}])
    useEffect(()=>{
    var datasetObjs =[]
    var measureArr=[]
    props.data.map((e)=>{
        e.measurements.map((subCollection)=>
      {
        var dateTime=subCollection.datetime*1000
        measureArr.push({ x:dateTime, y:subCollection.pia})
      })
      datasetObjs.push({label:e.CampusName,  data:measureArr, showLine: true, fill:false,
      borderWidth: 1,})
      measureArr=[]
    })
    setAttendanceData(datasetObjs)
    },[props.data])
var scatterData = {datasets:attendanceData}
var scatterOptions= {
  title: {
    display: true,
    text: 'Percent In Attendance by Campus',
    fontSize: 20, 
    fontColor: 'rgb(55, 61, 64)'
    },
    legend: {
      position:'bottom',
    },
  responsive: true,
  maintainAspectRatio:false,
  scales: {      
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Report Date',
        fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
      type: 'time',
      time: {
          unit: 'day'
      }
  }],
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Percent In Attendance (%)',
        fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
      ticks: {
        stepSize: 1,
        max:100,
        callback:function(value,index,values){
          return value + '%'
        }

      }
    }],
    plugins: {
      
      
      colorschemes: {
          scheme: 'brewer.Paired12'
      }
  }
  }
}
    return <div style={{height:'800px'}} className='mx-8'><Scatter options={scatterOptions} data={scatterData} className='h-3/4' /></div>
}
