import React, {useContext, useEffect, useState} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import {ClientContext} from '../../providers/ClientProvider'
import AttendanceScatter from './AttendanceScatter'
import Test from '../ResourcesVideoReport/test'
function SchoolAttendance () {
  // eslint-disable-next-line
  const [client, setClient]=useContext(ClientContext)
  const [campusComplete, setCampusComplete]=useState([])
  var dataResult=[]
  var dataResultAtt=[]
  useEffect(()=>{
    const loadData = async() =>
    {
      const options =   
      {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/attendance/campuses/${client}`,
      }
      const response = await axios(options)
      dataResult=response.data
      dataResult.map(async (e)=>{
        const optionsAtt =   
        {
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/attendance/campus/detail/${e.CampusID}`,
        }
        const responseAtt = await axios(optionsAtt)
        dataResultAtt=responseAtt.data
        await setCampusComplete((existingRecords)=>[
          ...existingRecords,
          {...e, measurements:dataResultAtt}
        ])
      })
    }
    loadData()   
  },[client])
  if (campusComplete) {
    return(
      <>
        <SectionTitle title="Trend Reports" subtitle="Attendance Trends" />
        <Widget  title="Attendance Analysis" description={<span>See a chart with attendance data.</span>}>
        <AttendanceScatter  data={campusComplete} ></AttendanceScatter>
        <Test/>
         </Widget>
      </>
    )
} else {
  return(
    <>
      <SectionTitle title="Trend Reports" subtitle="Attendance Trends" />
        <Widget className='h-screen' title="Attendance Analysis" description={<span>See a chart with attendance data.</span>}>
          
        Loading Chart Data...
      </Widget>
    </>
  )
}
  }
export default SchoolAttendance
