import React, {useState, useEffect, useContext} from 'react';
import Widget from '../../components/widget'
import PersonnelCard from './PersonnelCard';
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import {ClientContext} from '../../providers/ClientProvider'

function Landing () {
    const user = useContext(UserContext)
    const [accExec, setAccExec]=useState([])
    const [cio, setCio]=useState([])
    const [cso, setCso]=useState([])
    const [sales, setSales]=useState([])
    const [client, setClient] = useContext(ClientContext)
    useEffect(()=>{
        const loadData = async() =>
        {
          const response = await axios(
            {
                method: 'get',
                headers: {'Accept':'application/json',
                'culper':'culper',
                'Content-Type':'application/json'},
                url: `https://gbmercury.com/reps/gs/sales/${client}`,
            })
          var dataResult=response.data
          setSales(dataResult)
          const responseAM = await axios(
            {
                method: 'get',
                headers: {'Accept':'application/json',
                'culper':'culper',
                'Content-Type':'application/json'},
                url: `https://gbmercury.com/reps/gs/ams/${client}`,
            })
          var dataResultAM=responseAM.data
          setAccExec(dataResultAM)
          const responseCIO = await axios(
            {
                method: 'get',
                headers: {'Accept':'application/json',
                'culper':'culper',
                'Content-Type':'application/json'},
                url: `https://gbmercury.com/reps/gs/onerep/1`,
            })
          var dataResultCIO=responseCIO.data
          responseCIO.data[0].cellPhone=8777713558
          setCio(dataResultCIO)
          const responseCSO = await axios(
            {
                method: 'get',
                headers: {'Accept':'application/json',
                'culper':'culper',
                'Content-Type':'application/json'},
                url: `https://gbmercury.com/reps/gs/onerep/153`,
            })
          var dataResultCSO=responseCSO.data
          responseCSO.data[0].cellPhone=8777713558
          setCso(dataResultCSO)
        }
        
        loadData()   
        
      },[client])
    return (
        <Widget classNane="">
            <div className="text-4xl text-bold uppercase text-center text-grey-700" >Your GermBlast Team</div>
            
            <div className="flex flex-row flex-wrap">
            
            <div className="w-full  lg:w-1/2 my-6 text-bold uppercase text-center text-grey-700">
                <div className="text-2xl">Sales Team</div>
                {
                    sales?
                    sales.map((sale)=>(
                        <PersonnelCard person={sale} />
                    ))
                    :null
                }
            </div>
            <div className="w-full  lg:w-1/2 my-6  text-bold uppercase text-center text-grey-700">
                <div className="text-2xl">Account Management Team</div>
                {
                    accExec?
                    accExec.map((accOne)=>(
                        <PersonnelCard person={accOne} />
                    ))
                    :null
                }
            </div>
            <div className="w-full  lg:w-1/2 my-6  text-bold uppercase text-center text-grey-700">
                <div className="text-2xl">Chief Information Officer</div>
                {
                    cio?
                    cio.map((cioOne)=>(
                        <PersonnelCard person={cioOne} />
                    ))
                    :null
                }
            </div>
            <div className="text-bold uppercase text-center text-grey-700 w-full my-6 lg:w-1/2 ">
                <div className="text-2xl">Chief Science Officer</div>
                {
                    cso?
                    cso.map((csoOne)=>(
                        <PersonnelCard person={csoOne} />
                    ))
                    :null
                }
            </div>
            </div>
        </Widget>
    );
}

export default Landing;
