import React, {useContext, useEffect, useState} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import {ClientContext} from '../../providers/ClientProvider'
import axios from 'axios'
import PolarChart from './PolarChart'

import moment from 'moment'

export default function ResourcesVideoReport  () {
    // eslint-disable-next-line
    const [client, setClient]=useContext(ClientContext)
  const [videoHistory, setVideoHistory]=useState([])
  const [groupedDetail, setGroupedDetail]=useState([])
  useEffect(()=>{
    const loadData = async() =>
    {
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/video/report/${client}`
      })
      setVideoHistory(response.data)
      const responseG = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/video/report/grouped/${client}`
      }
      )
      setGroupedDetail(responseG.data)
    }
    loadData()   
    
  },[client])
  
  return(
  <>
    <SectionTitle title="Resources" subtitle="Video Watch Report" />
    <Widget> 
    <div className="flex flex-row flex-wrap">
      <PolarChart data={groupedDetail}/> 

  </div>
  </Widget>
  <Widget>
  <span className="text-xl w-full">Video Watch History</span>
  <table className="border-2 border-indigo-600 w-full" >
    <thead>
      <tr>
      <th className="border border-indigo-600 bg-indigo-100">Date/Time</th>
      <th className="border border-indigo-600 bg-indigo-100">User</th>
      <th className="border border-indigo-600 bg-indigo-100">Video</th>
      </tr>
    </thead>
    <tbody>
      {videoHistory.map((vids)=>(
        <tr className="" key={vids.id}>
          <td  className="border border-indigo-600 p-3">
            {moment(vids.dateTime*1000).format("MM/DD/YYYY h:m a")}
          </td>
          <td className="border border-indigo-600 p-3">
            {vids.username}
          </td>
          <td className="border border-indigo-600 p-3">
            {vids.videoName}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  </Widget>
  
    


  </>
)}
