import axios from 'axios';
import React, {useState, useEffect} from 'react'


const AirthinxOverview=({meterID})=>{
    const [selectedDate, setSelectedDate] = useState(Math.round(new Date().getTime()-86400000));
    const [selectedEndDate, setSelectedEndDate] = useState(Math.round(new Date().getTime()));
const [nodeeID, setNodeID]=useState([])
const [co2Data, setCo2Data]=useState([])
const [humidityData, setHumidityData]=useState([])
const [test, settest] =useState([])
    const getoverview=(meterID)=>{

    
    const options =   {
        method: 'get',
         headers: {
           'culper':'culper', },
         url: `https://gbmercury.com/iaqmeter/meters/${meterID}`,
         data: {  }
    //${match.params.id}
      }
      const options2 =   {
        method: 'post',
        headers: {'Accept':'application/json',
          'Authorization':'Bearer 301cf2e5-5ed7-40e0-bade-d91f0d2fb7ca',
          'Content-Type':'application/json'},
        url: 'https://api.environet.io/search/nodes',
        data: {
       
           "from": selectedDate, //state
           "to": selectedEndDate,
        }
      }
      axios.all([
        axios(options),
        axios(options2)
      ]).then(axios.spread(function(matrixData, airthinxData) {
          setNodeID([])
        matrixData.data.map((data2)=>{
           
     console.log(data2.nodeID)
       // setNodeID([...nodeeID, data2.nodeID])
        nodeeID.push(data2.nodeID)
    
       // console.log(meterID)
       // console.log(airthinxData.data[8])
        var n=0
        airthinxData.data.filter(a=>a._id===nodeeID[n]).map(m=>{
          console.log(m)
           console.log(nodeeID[n])
               var co2
                n+=1
            co2=m.data_points.filter(e=>e.name=="CO₂")
            
            for(var i=0; i<co2.length; i++){
            co2Data.push(co2[0].measurements[i][1])
            }// end of for loop
            var humidity
            console.log(m.data_points)
            humidity=m.data_points.filter(e=>e.name=="Humidity")
            console.log(humidity)
            if(true){
            for(var h=0; h<humidity.length; h++){
            humidityData.push(humidity[0].measurements[h][1])
            }
            }// end of for loop
        })

    })// end of matrix data map
     //   console.log(co2Data)
settest(co2Data)
       })).catch(err=>{
         console.log(err + ' there was an error')
       })
    
            }

useEffect( () => {
   setTimeout(getoverview(meterID),3000);
    
      }, []
     );
   
  
    return(
        <>
       {co2Data.length >0 || humidityData.length >0 ? 
            <>{Math.min(...humidityData) <30 ||  Math.max(...humidityData) >65
            ? (<small style={{color: 'red'}}> Humidity </small>) : (<small> Humidity </small>) }
               {Math.max(...co2Data) >1100 ? (<small style={{color: 'red'}}> CO₂ </small>) : (<small> CO₂ </small>) }
               <small>PM2.5 </small></> : <i>No devices added to this zone yet</i>}
               
        </>     
    )
} 
    export default AirthinxOverview

