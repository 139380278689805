import React, {useEffect, useState} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'
import logo from '../../images/gblogo.png'
import {formatCurrency} from '../../functions/numbers'
import {useParams} from 'react-router-dom'
import '../../styles/Printer-Friendly.css'
function ReportServiceInvoice () {
  const[serviceData, setServiceData]=useState({})
  const [lineItems, setLineItems]=useState([])
  const [orderID, setOrderID]=useState("")
  const [sumItems, setSumItems]=useState(0)
  const [customerData, setCustomerData]=useState()
  const [franchiseData, setFranchiseData]=useState()
  const {invNum} =useParams() 
  const [lastDate, setLastDate]=useState(0)

  const columns = React.useMemo(
      () => [
        {
          Header: 'OrderID',
          accessor: 'OrderID'
        },
        
        {
          Header: 'Service Type',
          accessor: 'TypeOfService'
        },
        {
          Header: 'Service Date',
          accessor: 'FinishDate',
          cell: props => <span>{moment(props.value).format('MM/DD/YYYY')}</span>
        },
        {
          Header: 'Service Status',
          accessor: 'OrderStatus',
        },
        
      ]
    )
  useEffect(()=>{
    const loadData = async() =>
    {
      
      const responseB = await axios({
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/order/invoices/items/${invNum}`
      })
      setLineItems(responseB.data)
      console.log(responseB.data)
      var runningTotal=0
      await responseB.data.map((e)=>(
        runningTotal=runningTotal+(e.Qty*e.Price)
        //console.log()
      ))
      setSumItems(runningTotal)
      setLastDate(responseB.data[0].LastClockStop*1000)
      const responseOrderDet = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/order/invoices/frominvoice/${invNum}`
      })
      await setOrderID(responseOrderDet.data[0].OrderID)
      const responseCust = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/customer/id/${responseB.data[0].CustomerID}`,
      })
      setCustomerData(responseCust.data[0])      
      const responseFran = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/franchise/${responseCust.data[0].franchise}`,
      })
      setFranchiseData(responseFran.data[0])  
      const response = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/order/single/${responseOrderDet.data[0].OrderID}`,
      })
      setServiceData(response.data)      
      
    }
    loadData()   
    
  },[])
  if (serviceData) {
    return(
      <>
      <Widget>
        <div className="p-4 max-w-screen-lg">
          <div className="flex flex-row items-center justify-between mb-16 ">
            <div className="flex flex-col">
              <span className="text-blue-500 text-4xl uppercase font-bold">
                Invoice
              </span>
              <span className="text-grey-700 text-lg">#{invNum}</span>
            </div>
            <div className="uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start whitespace-no-wrap">
              <div className="flex flex-row items-center justify-start space-x-2 text-blue-500">
                <img style={{height:90+ 'px'}} src={logo}/>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mb-16">
            <div className="flex flex-col">
              <span className="font-bold text-lg">Invoice to:</span>
              <span className="text-grey-700 text-lg">{customerData ? customerData.CustomerName : "John Doe"}</span>
              <span className="text-grey-700 text-lg">{customerData ? customerData.Address1 : ""}</span>
              {customerData ? 
              <span className="text-grey-700 text-lg">{customerData.Address2}</span>
              : ""}
              {customerData ? 
              <span className="text-grey-700 text-lg">{customerData.City}, {customerData.State} {customerData.Zip}</span>
              : ""}
            </div>
            
          <div className="flex flex-col">
              <span className="font-bold text-lg">Remit to:</span>
              <span className="text-grey-700 text-lg">{franchiseData ? franchiseData.franchiseName : "John Doe"}</span>
              <span className="text-grey-700 text-lg">{franchiseData ? franchiseData.RemitLine1 : ""}</span>
              {franchiseData ? 
              <span className="text-grey-700 text-lg">{franchiseData.RemitLine2}</span>
              : ""}
            </div>
            <div className="flex flex-col">
            <table>
              <tr>
              <td className="font-bold text-lg">Date:</td>
              <td className="text-grey-800 text-lg">{moment(lastDate).format("MM/DD/YYYY")}</td>
              </tr>
            {customerData ?
            <tr >
              <td className="font-bold text-lg bg-blue-100">Date Due:</td >
              <td className="text-grey-800 text-lg bg-blue-100">{moment(lastDate).add(customerData.PayTerms, "d").format("MM/DD/YYYY")}</td>
              
            </tr>
            :null
            }
          </table>
          </div>
          </div>
          <table className="w-full table-auto mb-16 text-left">
            <thead>
              <tr>
                <th className="pr-3 py-2 text-grey-700 text-xs leading-4 font-medium uppercase tracking-wider">
                  Description
                </th>
                <th className="px-3 py-2 text-grey-700 text-xs leading-4 font-medium uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-3 py-2 text-grey-700 text-xs leading-4 font-medium uppercase tracking-wider">
                  Rate
                </th>
                <th className="pl-3 py-2 text-grey-700 text-xs leading-4 font-medium uppercase tracking-wider text-right">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {lineItems.map((item, i) => (
                <tr key={i} className="break-clean">
                  <td className="pr-3 py-2 whitespace-no-wrap font-bold">
                    {item.ProdDesc}
                  </td>
                  <td className="px-3 py-2 whitespace-no-wrap">1</td>
                  <td className="px-3 py-2 whitespace-no-wrap">
                    {formatCurrency(item.Price)}
                  </td>
                  <td className="pl-3 py-2 whitespace-no-wrap text-right">
                    {formatCurrency(item.Price)}
                  </td>
                </tr>
              ))}
              {
              //  customerData.TaxRate > 0
              }
            </tbody>
          </table>
          <div className="flex flex-row w-full mb-16">
            <div className="flex flex-col w-1/2">
              
            </div>
            <div className="flex flex-col w-1/2 self-center text-right">
              <span className="font-bold">Total amount</span>
              <span className="text-4xl font-bold text-blue-500">{formatCurrency(sumItems)}</span>
            </div>
            
          </div>
          <div className="flex flex-col w-full self-center text-left">

            
                 <h4>Limited Warranty and Liability</h4>
<p className="text-xs font-bold pb-3">INFECTION CONTROLS, INC. WARRANTS THAT UPON COMPLETION OF ITS GERMBLAST<sup>®</sup> TREATMENT FOR CUSTOMER (MANAGING BIOBURDEN AND KILLING MICROORGANISMS) MICROORGANISMS WILL THEN EXIST IN ACCEPTABLE LEVELS AS DETERMINED BY INFECTION CONTROLS, INC, IN ITS SOLE OPINION AND DISCRETION. HOWEVER, INFECTION CONTROLS, INC. DOES NOT WARRANT AND EXPRESSLY DENIES AND EXCLUDES, ANY AND ALL OTHER EXPRESSED OR IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, INCLUDING, BUT NOT LIMITED TO, THE REINTRODUCTION OF SUCH MICROORGANISMS AFTER COMPLETION OF ITS GERMBLAST TREATMENT. INFECTION CONTROLS SOLE OBLIGATION UNDER THIS WARRANTY AND THE CUSTOMER’S EXCLUSIVE REMEDY IS LIMITED TO NO MORE THAN ONE OTHER TREATMENT, WITHOUT COST TO CUSTOMER, IF INFECTION CONTROLS DETERMINES, AT ITS SOLE DISCRETION, THAT ITS PRIOR TREATMENT FOR CUSTOMER WAS DEFECTIVE, PROVIDED CUSTOMER IMMEDIATELY, UPON DISCOVERY OF ANY TREATMENT FAILURE, NOTIFIES INFECTION CONTROLS OF ANY SUCH CLAIMED DEFECT OR FAILURE AND IS ALLOWED TO INSPECT THE TREATED PREMISES AND REVIEW ANY OBJECTIVE EVIDENCE UPON REQUEST.</p>

<p  className="text-xs font-bold pb-3">CUSTOMER AGREES THAT INFECTION CONTROLS, INC., AND ANY OF ITS OWNERS, EMPLOYEES, OFFICERS, AGENTS, OR ASSIGNS, WILL NOT BE SUBJECT TO ANY OTHER OR FURTHER, LIABILITY OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, ANY ACTUAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES RESULTING FROM ANY BREACH OF WARRANTY EXCEPT AS EXPRESSLY PROVIDED HEREIN.</p>

<p className="text-xs font-bold pb-3">THIS WARRANTY IS MADE IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THERE ARE NO WARRANTIES WHICH EXTEND BEYOND THE DESCRIPTION OF THE FACE HEREOF.</p>

<p  className="text-xs font-bold pb-3">CUSTOMER SHALL SAVE AND HOLD INFECTION CONTROLS, INC., ITS OWNERS, EMPLOYEES, OFFICERS, AGENTS, AND ASSIGNS, WHOLE AND HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS, COSTS, FEES, AND DAMAGES OF ANY KIND INCURRED OR IMPOSED AGAINST INFECTION CONTROLS, INC., OR ANY OF ITS OWNERS, EMPLOYEES, OFFICERS, AGENTS, AND ASSIGNS, AS A RESULT OF ANY AND ALL CLAIMS MADE BY CUSTOMER, OR CUSTOMER’S OWNERS, EMPLOYEES, OFFICERS, AGENTS, ASSIGNS, GUESTS, INVITEES, OR OTHER ASSOCIATED THIRD-PARTIES OF CUSTOMER, FOR ANY CLAIMS ARISING UNDER THE LAW, INCLUDING, BUT NOT LIMITED TO, TORT, CONTRACT, BREACH OF WARRANTIES, AND DEFECTIVE SERVICES AND/OR PRODUCTS.</p>
                                
            </div>
        </div>
      </Widget>
    </>
    )
} else {
  return(
    <>
      <SectionTitle title="Reports" subtitle="Service History" />
      <Widget title="Completed Services" description={<span>Lists all completed services</span>}>
        
        Loading Service History
      </Widget>
    </>
  )
}
}
export default ReportServiceInvoice
