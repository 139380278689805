import React from 'react';
import {Link} from 'react-router-dom'
import moment from 'moment'
export default function AuditList  (props) {
    
    
    return (
        <table  className="w-full">
            
            {props.Audits.map((singleA)=>(
                <tr className="mb-4">
                    <td className="m-3" key={singleA.AuditResultsID}><Link to={`/auditscorecard/${singleA.AuditResultsID}`}>
                    <table className="w-full">
                        <tr className="w-full">
                            <td className="w-48">
                            <span className="text-2xl font-bold">{moment((singleA.ScheduledOn*1000)).format("M/D/YYYY")}</span>
                            </td>
                            <td className="w-1/2">
                            <span className="text-2xl font-bold">Audit Template: {singleA.AuditName}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="w-1/2">
                            <span className="text-xl">{singleA.CampusName} {singleA.AreaName}</span>
                            </td>
                            <td className="w-1/2">
                            <span className="text-xl">{singleA.Custodian}</span>
                            </td>
                        </tr>
                        </table>    
                        </Link>
                    
                    </td>
                    
                </tr>
                
            )
            )}
            
        </table>
    );
}

