import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import FacilityList from './FacilityList'
import {ClientContext} from '../../providers/ClientProvider'
export default function AuditSettingsFacility () {
  const user = useContext(UserContext);
  const [campusName, setCampusName] = useState('');
  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'CampusName') {
        setCampusName(value);
    }
};
const [client, setClient] = useContext(ClientContext)
const [cards, setCards]=useState([])
  const addFacility = async(event) =>
    {
        event.preventDefault();  
        const options =   
        {
          method: 'post',
          data: {'campusName':campusName,
                'clientID':user.CustID},
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/audit/facility/new',
        }
        
        const response = await axios(options)

        setCards(existingCards=>[...existingCards,{CampusID:response.data.insertId,CampusName:campusName, Active:1, ATPAvg: 0, ATPStDev: 0, Score: 0}])
        console.log(response)
        setCampusName("")
        
    }
  useEffect(()=>{
    
    const loadData = async() =>
    {
      
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/audit/facility/all/${client}`
      }
      )
      setCards(response.data)
    }
    loadData()   
    
  },[])
  return(
  <>
    <SectionTitle title="Pages" subtitle="Settings - Facilities" />
    <Widget title="Page title" description={<span>Change Your Facilities' Settings Here</span>}>
      <p>This is an empty page right now.</p>
    </Widget>
    <Widget title="Facility List" description={<span>Add/Edit Facilities Here</span>}>
    <form >
            <label>Facility Name</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="CampusName"
            value = {campusName}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!campusName}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {addFacility(event)}}
            
            >
                Add New Employee
            </button>
            

            </form>
    </Widget>
    <Widget title="Facility List" description={<span>Manage the Team Here</span>}>
    
    <FacilityList Facilitys={cards}/>
    </Widget>
  </>
)}

