import React, { useContext, useEffect, useState } from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import MyBarChart from '../../components/charts/am5charts-all-atp'
import axios from 'axios'
import moment from 'moment'
import { ClientContext } from '../../providers/ClientProvider'
import { CSVLink} from 'react-csv'

export default function ATPFacility() {
    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'Area') {
          setArea(value);
        }
    };
    const [client, setClient] = useContext(ClientContext)
    const [locations, setLocations]=useState([])
    const [allATP, setAllATP] = useState()
    const [area, setArea]=useState()
    const [csvExport, setCSVExport] = useState({})
    var dataResult = []
    useEffect(() => {
            const loadData = async () => {
                const responseCampus = await axios(
                    {
                    method: 'get',
                    headers: {'Accept':'application/json',
                      'culper':'culper',
                      'Content-Type':'application/json'},
                    url: `https://gbmercury.com/customer/locations/${client}`
                  }
                  )
                setLocations(responseCampus.data)
            }
            if(client>0){
                loadData()
            }
    }, [client])
    useEffect(() => {
        if (!area || area < 1) {
            const loadData = async () => {
                var csvBuilder = []
                const options = {
                    method: 'get',
                    headers: {
                        Accept: 'application/json',
                        culper: 'culper',
                        'Content-Type': 'application/json',
                    },
                    url: `https://gbmercury.com/atps/gs/indiv/customer/${client}`,
                }
                const response = await axios(options)
                dataResult = response.data
                dataResult.sort((a, b) => (a.ATPDate > b.ATPDate ? 1 : -1))
                dataResult.map((e) => {
                    e.ATPDate = e.ATPDate * 1000
                    e.formattedDate = moment(e.ATPDate).format(
                        'MM/DD/YYYY hh:mm:ss a'
                    )
                    e.monthYear = moment(e.ATPDate).format('MM/YYYY')
                    e.quarterYear = moment(e.ATPDate).format('Q/YYYY')
                    e.Year = moment(e.ATPDate).format('YYYY')
                    csvBuilder.push({
                        'Sample Date': e.formattedDate,
                        'Sample Location': e.Location,
                        'ATP Value': e.ATPVal,
                    })
                })
                setCSVExport(csvBuilder)
                setAllATP(dataResult)
            }
            if(client>0)
            {
                loadData()
            }
        }
        else if(area >0) {
            
            const loadData = async () => {
                console.log('reload')
                var csvBuilder = []
                const options = {
                    method: 'get',
                    headers: {
                        Accept: 'application/json',
                        culper: 'culper',
                        'Content-Type': 'application/json',
                    },
                    url: `https://gbmercury.com/atps/gs/indiv/location/${area}`,
                }
                const response = await axios(options)
                dataResult = response.data
                dataResult.sort((a, b) => (a.ATPDate > b.ATPDate ? 1 : -1))
                dataResult.map((e) => {
                    e.ATPDate = e.ATPDate * 1000
                    e.formattedDate = moment(e.ATPDate).format(
                        'MM/DD/YYYY hh:mm:ss a'
                    )
                    e.monthYear = moment(e.ATPDate).format('MM/YYYY')
                    e.quarterYear = moment(e.ATPDate).format('Q/YYYY')
                    e.Year = moment(e.ATPDate).format('YYYY')
                    csvBuilder.push({
                        'Sample Date': e.formattedDate,
                        'Sample Location': e.Location,
                        'ATP Value': e.ATPVal,
                    })
                })
                setCSVExport(csvBuilder)
                setAllATP(dataResult)
            }
            if(client>0)
            {
                loadData()
            }
        }
    }, [area, client])
    if (allATP) {
        return (
            <>
                <SectionTitle title='Pages' subtitle='All ATP Samples' />
                <Widget
                    title='Page title'
                    description={<span>See a chart with all samples for a facility</span>}
                >
                    <form>
                    <select className="w-full p-3 mb-2 bg-grey-200" type="text" name="Area"
                        value = {area}
                        onChange = {(event) => onChangeHandler(event)}
                        >
                        <option value="-9">Please Select</option>
                        {
                        locations?
                        locations.map((oneArea)=>(
                            <option key={oneArea.LocationID} value={oneArea.LocationID}>{oneArea.LocationName} </option>

                        ))
                        :
                        null
                        }
                    </select>
                    </form>
                    <MyBarChart chartData={allATP} />
                    <CSVLink data={csvExport} filename={'all-atp-samples.csv'}>
                        Download records to CSV
                    </CSVLink>
                </Widget>
            </>
        )
    } else {
        return (
            <>
                <SectionTitle title='Pages' subtitle='All ATP Samples' />
                <Widget
                    title='Page title'
                    description={<span>See a chart with all samples for a facility</span>}
                >
                    Loading Chart Data...
                </Widget>
            </>
        )
    }
}