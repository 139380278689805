import React, {useState , useEffect, Fragment, useContext} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'
import { withRouter } from 'react-router-dom';
import {ClientContext} from '../../providers/ClientProvider'

const AuditReportFacility = ({history}) => {
  const [allATP, setAllATP]=useState()
  var auditSamples=[]



  const [client,setClient]=useContext(ClientContext)
  const [campusState, setCampusState]= useState([])
  const [atpAverage, setAtpAverage]= useState()
  const [scoreResults, setScoreResults]=useState()
  const [custodianState, setCustodianState]=useState()
  const [areaState, setAreaState]=useState()
  const [yearAgo, setYearAgo]=useState(Math.round(new Date().getTime()-31556926000))
  //const [mainAudit, setMainAudit]= useState()
  useEffect(()=>{
    const options=   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditcampus/all/${client}`,
    }

    const options2 = 
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditscorecard/answered/client/${client}`,
    }
    const options3 =   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditatp/all/${client}`,
    }

    const options4 =   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditarea/all/${client}`,
    }
    
    const loadData = async() =>
    {
  
     axios.all([
        axios(options),
        axios(options2),
        axios(options3),
        axios(options4),
      ]).then(axios.spread(function(campus, results, atp, area) {
        console.log(results.data)
        console.log(client)
     

  

  var campuses= campus.data
  var areas=area.data
  
 console.log(yearAgo)

  setCampusState(campus.data)
  setAtpAverage(atp.data)
  setScoreResults(results.data)
  console.log(campusState)
  console.log(atpAverage)

    
      setAllATP(auditSamples)
     

      })).catch(err=>{
        console.log(err + ' there was an error')
      })
  
    }
    if(client>0){
    loadData()   
    }
  },[yearAgo, client])
  if (campusState && campusState.length>0 && atpAverage && atpAverage.length>0 && scoreResults && client >0) {
return (
  <>
    <SectionTitle title="Pages" subtitle="Audit Report - Facility" />
    <Widget title="Page title" description={<span>See Audit Scores Samples from Your Facilities Here</span>}>
      <p>This is a page is in progress  </p>
      <h4>List of Facilities</h4>
{console.log(campusState)}
      <table>
       
       
          {campusState.map(camp=>(
          <tbody key={camp.CampusID}>
           <tr><td style={{fontSize: '18px'}}>{camp.CampusName }</td></tr>
           <tr>
{/* 
             testing */}
    {/* {scoreResults.filter(sr=> sr.CampusID==camp.CampusID).filter(sr=> sr.PassFail===1 
|| sr.PassFail===3).filter(sr=> sr.UpdateDate*1000> yearAgo).length}
{scoreResults.filter(sr=> sr.CampusID==camp.CampusID).filter(sr=> sr.PassFail===2 
  || sr.PassFail===1 ||sr.PassFail==3).filter(sr=> sr.UpdateDate*1000> yearAgo).length} */}


{!isNaN((scoreResults.filter(sr=> sr.CampusID==camp.CampusID).filter(sr=> sr.PassFail===1 
|| sr.PassFail===3).filter(sr=> sr.UpdateDate*1000> yearAgo).length)/
(scoreResults.filter(sr=> sr.CampusID==camp.CampusID).filter(sr=> sr.PassFail!==0)
.filter(sr=> sr.UpdateDate*1000> yearAgo).length)) ?
 
<td style={{backgroundColor:'#4d8da8', display: 'inline-block', width:' 40%', margin:'3%'}}><b>{Math.round(scoreResults.filter(sr=> sr.CampusID==camp.CampusID).filter(sr=> sr.PassFail==1 
|| sr.PassFail==3).filter(sr =>sr.UpdateDate*1000> yearAgo).length/
scoreResults.filter(sr=> sr.CampusID==camp.CampusID).filter(sr=> sr.PassFail==2 
|| sr.PassFail==1 ||sr.PassFail==3).filter(sr =>sr.UpdateDate*1000> yearAgo).length*100) +"%"
}</b> <br></br>Score </td> :
<td style={{backgroundColor:'#4d8da8', display: 'inline-block', width:' 40%', margin:'3%'}}><b>0%</b> <br></br>Score</td>}


{!isNaN(atpAverage.filter(AA=> AA.CampusID==camp.CampusID).filter(AA=> (AA.DateTime*1000)> yearAgo).reduce((
  accum, cv)=> accum + cv.ATPResult,0)/atpAverage.filter(AA=> AA.CampusID==camp.CampusID).filter(AA=> 
  (AA.DateTime*1000)> yearAgo).length) ? 
  <td style={{backgroundColor:'#42b393', display: 'inline-block', width:' 40%', margin:'3%'}}>{Math.round(atpAverage.filter(AA=> AA.CampusID==camp.CampusID).filter(AA=> (AA.DateTime*1000)> yearAgo).reduce((
    accum, cv)=> accum + cv.ATPResult,0)/atpAverage.filter(AA=> AA.CampusID==camp.CampusID).filter(AA=> 
    (AA.DateTime*1000)> yearAgo).length) + ' RLU'} <br></br>ATP Average</td>
    : <td style={{backgroundColor:'#42b393', display: 'inline-block', width:' 40%', margin:'3%'}}>0 RLU <br></br>ATP Average</td>
}
<td> <button style={{ padding: '6px', backgroundColor:'#34a8eb', marginRight: '6px'}} onClick={()=> history.push(`/campusreport/${camp.CampusID}`)}>Campus Report</button></td>
<td><button style={{ padding: '6px', backgroundColor:' #34a8eb'}}  onClick={()=> history.push(`/auditareasreport/${camp.CampusID}`)}>Detail View</button></td>
</tr>

         </tbody>
            ))}  
            {/* end of campus map */}


{/* 
            {scoreResults.filter(sr=> sr.CampusID==camp.CampusID).filter(sr=> sr.PassFail==1 || sr.PassFail==3 ).map(score=>{
  return <tr><td>{score.PassFail}</td></tr>
})} */}

      </table>
     
    </Widget>
  </>
) }
else {
  return(
    <>
      <SectionTitle title="Pages" subtitle="Audit Report - Facility" />
      <Widget title="Page Title" description={<span>See Audit Scores Samples from Your Facilities Here</span>}>
        
        Loading Chart Data...
      </Widget>
    </>
)}}
export default withRouter(AuditReportFacility)
