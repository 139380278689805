import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import EmployeeList from './EmployeeList'
import {ClientContext} from '../../providers/ClientProvider'
function AuditSettingsPersonnel () {
  const user = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [client, setClient] = useContext(ClientContext)

  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
  
    if(name === 'FirstName') {
        setFirstName(value);
    }
    else if(name === 'LastName') {
      setLastName(value);
  }
  else if(name === 'Title') {
    setTitle(value);
}
};
const [cards, setCards]=useState([])
  const addPersonnel = async(event) =>
    {
        event.preventDefault();  
        const options =   
        {
          method: 'post',
          data: {'firstName':firstName,
                  'lastName':lastName,
                  'title':title,
                'clientID':user.CustID},
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/audit/employee/new',
        }
        
        const response = await axios(options)

        setCards(tableRows=>[...tableRows,{CustodianID:response.data.insertId,Name:firstName + " " + lastName, Active:1, FirstName: firstName, LastName: lastName, Title: title, ATPAvg: 0, ATPStDev: 0, Score: 0}])
        console.log(response)
        setFirstName("")
        setLastName("")
        setTitle("Custodian")

    }
  useEffect(()=>{
    
    const loadData = async() =>
    {
      
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/audit/employee/all/${client}`
      }
      )
      setCards(response.data)
    }
    loadData()   
    
  },[client])

return (<>
    <SectionTitle title="Pages" subtitle="Settings - Personnel" />
    <Widget title="Question Bank" description={<span>Add New Audit Questions Here</span>}>
    
    <form >
            <label>First Name</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="FirstName"
            value = {firstName}
            onChange = {(event) => onChangeHandler(event)}
            />
            <label>Last Name</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="LastName"
            value = {lastName}
            onChange = {(event) => onChangeHandler(event)}
            />
            <label>Title</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="Title"
            value = {title}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!firstName || !lastName}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {addPersonnel(event)}}
            
            >
                Add New Employee
            </button>
            

            </form>
    </Widget>
    <Widget title="Custodian Bank" description={<span>Manage the Team Here</span>}>
    
    <EmployeeList Employees={cards}/>
    </Widget>
  </>
)}
export default AuditSettingsPersonnel
