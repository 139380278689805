import React, {useState, useContext, useEffect} from 'react'


export default function AtpSingle (props){
    
    return (
        <div className="p-4">
        <div className="text-lg">Sample Location: {props.atp.ATPSite}</div>
        <div className="text-sm">Sample Value: {props.atp.ATPResult} RLU</div>
        
        
        <button className="bg-red-300 text-white font-bold p-3 rounded-md" onClick={()=>props.toRemoveIt(props.atp.ID)}>Remove</button>
        
        </div>
    );
}
