import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom';

export default function Question (props){
  const user = useContext(UserContext)
    const [questionResult, setQuestionResult]=useState({})
    const [score, setScore]=useState(0)
    const [notes, setNotes]=useState("")
    const [answered, setAnswered]=useState(0)
    useEffect(()=>{
    
        const loadData = async() =>
        {
          const options={
            method: 'post',
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
              data: {'questionid':props.question.QuestionID,
                'auditid':props.auditid,
                },
            url: `https://gbmercury.com/auditscorecard/answered/question`
          }
            const responseAns = await axios(options)
            
          setQuestionResult(responseAns.data[0])
         if(responseAns.data[0]){
             setAnswered(responseAns.data[0].ResultsID)
             setNotes(responseAns.data[0].Notes)
             if(responseAns.data[0].PassFail>0){
                setScore(responseAns.data[0].PassFail)
             }
        }
         
         
      }
      loadData() 
    }
    
    ,[])
    return (
        <div className="px-4 py-2">
        <div className="text-lg">{props.question.Question}</div>
        <div className="text-sm">{props.question.Detail}</div>
        {score==0 ? <div>Not Scored</div>:null}
        {score==1 ? <div className="bg-green-600 p-2 text-green-100 font-bold">Meets Expectations</div>:null}
        {score==2 ? <div className="bg-red-600 p-2 text-red-100 font-bold">Needs Improvement</div>:null} 
        {score==3 ? <div className="bg-green-900 p-2 text-green-100 font-bold">Exceeds Expectations</div>:null}
        <div>{notes}</div>
  <hr className="my-2"/>


        </div>
    );
}
