import React, {useEffect, useState} from 'react'
import axios from 'axios'
import moment from 'moment'


function ServiceDates (props) {
  const[serviceDates, setServiceDates]=useState([])
  
  useEffect(()=>{
    const options =   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/order/dates/${props.OrderNum}`,
    }
    const loadData = async() =>
    {
      const response = await axios(options)
      var dataResult=response.data
      dataResult.map((e)=>{
        e.StartTime >0 ? e.Start=e.StartTime*1000 : e.Start="Missing Info"
        e.StartTime >0 ? e.PrettyDate=moment(e.Start).format("MM/DD/YYYY"):e.PrettyDate="Missing Date Info"
      }
      )
      
      setServiceDates(dataResult)
    }
    loadData()   
    
  },[])
  if (serviceDates) {
    return(
      <>
            {serviceDates.map((sData)=>(
                <span key={sData.CalID}>{sData.PrettyDate}, </span> 
            )
            )}
      </>
    )
} else {
  return(
    <>
      
        Loading Service Dates
      
    </>
  )
}
}
export default ServiceDates
