import React, {useState, useEffect, useContext, Fragment} from 'react';
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import  {UserContext} from '../../providers/UserProvider';
import AirthinxOverview from '../AirthinxOverview/AirthinxOverview';




const DeviceLocations=(props)=>{
  let history = useHistory()
  const user = useContext(UserContext);

var AQData=[]
var timestampsData=[]
const [measurements, setMeasurements]=useState([])
const [locationZones, setLocationZones]=useState([])
const [nodes, setNodes]=useState([])
const [locations, setLocations]=useState([])

var CO2Data=[]

var PM25Data=[]
var humidityData=[]
var timestampDay=[]
var tset=[1,2,3,4]
console.log(props)


  const loadZones = (clientID) => {
    console.log(clientID)
    const options =   {
     method: 'get',
     headers: {
       'culper':'culper',
       },
     url: `https://gbmercury.com/iaqmeter/locations/${clientID}`,
     data: {
     }
   }
   const options2 =   {
    method: 'get',
    headers: {
      'culper':'culper',
      },
    url: `https://gbmercury.com/iaqmeter/zones/${clientID}`,
   
    data: {
    }
  }
  
  axios.all([
    axios(options),
    axios(options2)
  ]).then(axios.spread(function(matrixLocations, matrixZones) {
    
    setLocations(matrixLocations.data)
    setLocationZones(matrixZones.data)
      return measurements
   })).catch(err=>{
     console.log(err + ' there was an error')
   })
  }


useEffect( () => {

 loadZones(props.clientID);
 
   },[] 
  );

  measurements.map((m)=>(
    getzones(m)   
  ))
  function getzones(item){

  if(item.measurements){
     for(var i=0; i<item.measurements.length; i++){
 
     if(item.name==="CO₂")
     {
        CO2Data.push(item.measurements[i][1]);
        
     timestampsData.push(moment(item.measurements[i][0]).format("h:mm")) 
    timestampDay.push(moment(item.measurements[i][0]).format("MMM D h:mm")) 
     }
     if(item.name==="PM2.5")
     {
        PM25Data.push(item.measurements[i][1]);
 
     }
     if(item.name==="Humidity")
     {
        humidityData.push(item.measurements[i][1]);
  
     }
   } }    }

var totalCo2Data=[]
totalCo2Data.push(CO2Data)

var maxCo2= Math.max(...totalCo2Data);
var maxHumid= Math.max(...humidityData);
var maxpm25=Math.max(...PM25Data)

return(
 <div className='d-flex mb-2 flex-wrap justify-content-center'>
  
 {locations.map((loc, idx)=>(
     <>
      <div key={idx} className='align-center mr-4 mb-3 border-b-2 mt-4'>
      <h4>{loc.LocationName}</h4>
<h5>Zone List</h5>
</div>
     {locationZones.filter(lz=>lz.location===loc.LocationID).map((zone)=>(
         <div key={zone.id} className="m-4">
        
        <span className='bg-blue-100'  
         onClick={() =>
          history.push(`/devicecompare/${zone.id}`)}>
       {zone.name}
       </span>
       <AirthinxOverview className="inline-block m-2" meterID={zone.id}/>
     </div>      
     ))}
    </>
 ))} 
  
</div>
)
  }
  export default withRouter(DeviceLocations);