import React, {useState, useContext,useEffect } from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import  {ClientContext} from "../../providers/ClientProvider"
import axios from 'axios'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { FaLockOpen } from 'react-icons/fa'
import AuditDashboard from '../AuditDashboard/AuditDashboard'
import { withRouter, useParams } from 'react-router-dom';
import HygieneFinalize from './HygieneFinalize'


const HandHygieneDash=({history})=>{

    const [client, setClient] = useContext(ClientContext)
    const { enqueueSnackbar} = useSnackbar()
    const[dept, setDept]=useState({})
    const [openAudit, setOpenAudit]=useState()
   const[deptAudit, setDeptAudit]=useState()
   const [closedAudit, setClosedAudit]=useState()
useEffect(()=>{
console.log(client)

const options=   
{
   
    //id == clientID list of all departments
  method: 'get',
  headers: {'Accept':'application/json',
    'culper':'culper',
    'Content-Type':'application/json'},
  url: `https://gbmercury.com/handhygiene/getdepts/${client}`, 

}

const options2=   
{
   // from hhmaster
    //list of all open audits id == clientID
  method: 'get',
  headers: {'Accept':'application/json',
    'culper':'culper',
    'Content-Type':'application/json'},
  url: `https://gbmercury.com/handhygiene/master/open/${client}`, 
}

const options3=   
{
   
    //list of all completed audits id ==clientID
  method: 'get',
  headers: {'Accept':'application/json',
    'culper':'culper',
    'Content-Type':'application/json'},
  url: `https://gbmercury.com/handhygiene/master/closed/${client}`, 
}

const options5=   
{
   
    //id == AuditID  from hhobservations 
  method: 'get',
  headers: {'Accept':'application/json',
    'culper':'culper',
    'Content-Type':'application/json'},
  url: `https://gbmercury.com/handhygiene/getdepts/${client}`, 

}
const options6=   
{
    // from hhprofessions ==clientID
  method: 'get',
  headers: {'Accept':'application/json',
    'culper':'culper',
    'Content-Type':'application/json'},
  url: `https://gbmercury.com/handhygiene/professions/${client}`, 
}

const loadData=async()=>{


axios.all([
    axios(options),
    axios(options2),
    axios(options3)
]).then(axios.spread(function(op1, op2, op3){
    console.log(op3.data)
setDept(op1.data)
setOpenAudit(op2.data)
setClosedAudit(op3.data)
console.log(op1.data)
})).catch(err=>{
    console.log(err)
})

}// end of load data



if(client==0){
    loadData()
}



  if(client>0){
    loadData()
}

},[deptAudit,client])

console.log(dept)
const onADD = async (event) => {
    event.preventDefault()
    const{name, value}=event.currentTarget
    console.log(deptAudit)
    const options = {
        method: 'post',
        data: {
            clientID: 129,
            deptID: deptAudit,
        },
        headers: {
            Accept: 'application/json',
        culper: 'culper',
            'Content-Type': 'application/json',
        },
        url: 'https://gbmercury.com/handhygiene/master/new',
    }
   const response = await axios(options)
    
console.log(options.data)
    enqueueSnackbar('Added',{variant: 'success',});
}

const chooseDept = (event) => {
    const{name, value}=event.currentTarget
 setDeptAudit(value)
 console.log(value)
}


if(dept && openAudit && closedAudit && client>0 ){
    return (
        <div>
                <SectionTitle title="Pages" subtitle="Hand Hygiene Management " />
    <Widget title="Page title" description="Create New Audits, Review Old Audits">
      {console.log(openAudit[0] +'openaudit')}
      {console.log(dept[0] +'dept')}

             
<form className="flex-auto p-6">
    <div className='border border-black rounded p-3 w-2/4' >
    <h6>New Hand Hygiene Audit</h6>
<label >Department:</label>

<select className=" focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-2/4 sm:w-3/5 text-sm text-black  mr-2 border border-black rounded-md py-2 pl-10"
onChange={(event)=>chooseDept(event)} name="audit" id="audit" >
    <option   value="0" className="bg-red-50" label="Select One">Select One</option>
    {dept.map(dept=>{
        return(
            <option key={dept.id}  value={dept.id} className="btn btn-primary" label={dept.DeptName}>{dept.DeptName} </option>
        )
       
    })}

</select  >
<br></br>
<button className= 'border border-4 border-blue-400  w-50 mt-4 mb-1 p-2 rounded bg-blue-300' elevation={3}  
onClick= {(event)=> {onADD(event)}} >Submit</button>
</div>
</form>

<hr></hr>

<table className="">
    <thead>
        <tr className="border">
            <td className="w-1/2 text-center " > Audit List</td>
            <td className="w-1/4 text-center"> Observation Count</td>
            <td className="w-1/4 text-center "> Actions</td>
        </tr>
    </thead>
    <tbody>
      {console.log(openAudit)}
            {openAudit.map(open=>{
                return(
                    <tr className="border text-center " key={open.id}>
                    <td className="border text-center ">
                        <button onClick={() => history.push(`/handhygieneaudit/${open.id}/${dept.filter(d=>d.id===open.Dept)[0].id}`)}>
                            {dept.length <1 ? ('Dept Unknown-'):  (dept.filter(d=>d.id==open.Dept)[0].DeptName) } initialized on {moment(open.InitDate*1000).format("MM/DD/YY")}  </button>
                        </td>
                    <td className="border text-center "> {open.ObservationCount!="null"? (open.ObservationCount): ("0")}</td>
                    <td className="border text-center"><HygieneFinalize auditid={open.id} dept={ dept.length <1 ? ('NULL'): (dept.filter(d=>d.id==open.Dept)[0].id)} /> </td>

                 
                
                    </tr>
                )
            })} 
        
        
    </tbody>
</table>

<table className="my-12">
    <thead>
    <tr className="border">
    <td className="w-1/2 text-center " >Completed Audit List</td>
    <td className="w-1/2 text-center" > Observation Count </td>
        </tr>
    </thead>
    <tbody>
       
              {closedAudit.map(closed=>{
                return (
                    <tr className="border text-center " key={closed.id}>
                <td className="border text-center p-2 ">
                {/* /${dept.filter(d=>d.id===closed.Dept)[0].id} */}
                <button onClick={() => history.push(`/handhygienereport/${closed.id}`)}>
                    Department {dept.filter(d=>d.id==closed.Dept)[0].DeptName} completed on: {moment(closed.CompDate*1000).format("MM/DD/YY")}
                    </button> </td>
                <td className="border text-center p-2">{closed.ObservationCount}</td>
                </tr>
                )
            })}  

    </tbody>
</table>


    </Widget>


        </div>
    )
}else return(
    <div>loading...</div>
)
}
export default withRouter(HandHygieneDash)