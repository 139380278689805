import React from 'react';
import moment from 'moment'
import { Radar } from 'react-chartjs-2'

import './PersonnelRadar.scss'

export default function PersonnelRadar(props){
  var labels=[]
  var scores=[]
  props.data.map((e)=>
  {
    labels.push(e.name)
    scores.push(e.score)
  }
  )
  
  console.log(props)
  const data = {
    labels: labels,
    datasets: [
      {
        label: props.title,
        backgroundColor: "rgba(220,220,220,0.2)",
        pointBackgroundColor: "rgba(220,220,220,1)",
        data: scores
      }
    ]
  }
  const options = {
    responsive:true,
    legend: {
      display: false
    },
    title: {
      display: true,
      text: props.title
    },
    scale: {
      reverse: false,
      ticks: {
        beginAtZero: true
      }
    }
  }
  
return(
  <>
  <Radar data={data} options={options} />
  </>
)
}