import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import './chartstyles.scss'

const Pm25Chart=({airthinxData})=>{

var PM25Data=[]
var timestampsData=[]
var timestampDay=[]

airthinxData.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
       {
        if(Item.measurements){
        for(var i=0; i<Item.measurements.length; i++){
 
 
        if(Item.name==="PM2.5")
        {
            PM25Data.push(Item.measurements[i][1]);
            timestampsData.push(moment(Item.measurements[i][0]).format("h:mm"))
            timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }
       }


       const dataPM25 = {
        labels: timestampsData  ,
        datasets: [
          { 
            
            borderColor: PM25Data.map(data=>
              {if(data>55){
                  return 'red'
              }else if(data>25){
                 return '#d4b624'
              }else{
                  return 'green'
              }} ) ,
              backgroundColor: PM25Data.map(data=>
                {if(data>55){
                  return 'red'
              }else if(data>25){
                 return '#d4b624'
              }else{
                  return 'rgb(39, 156, 97)'
              }} ) ,
            borderWidth: .5,
            hoverBackgroundColor: 'rgb(91, 86, 113)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:.7,
            pointRadius: 1.5,
            pointHoverRadius: 6,
            data: PM25Data ,  
            fill:false }, 
      ], 
      
        };
        const minData=Math.min(...PM25Data);
        const maxData=Math.max(...PM25Data)
        const avgData = PM25Data.reduce((a,b) => a + b, 0) / PM25Data.length
           PM25Data.map(function(value){
          var diff = value - avgData;
          return diff;
          
        });
        var squareDiffs = PM25Data.map(function(value){
          var diff = value - avgData;
          var sqr = diff * diff;
          return sqr;
        });
        function average(data){
          var sum = data.reduce(function(sum, value){
            return sum + value;
          }, 0);
        
          var avg = sum / data.length;
          return avg;
        }
        
        var avgSquareDiff = average(squareDiffs)
        var stdDev = Math.sqrt(avgSquareDiff);

return(
<div>

{PM25Data.length <1 ? (<p className="text-center m-5">No <b> PM2.5</b> Data for Time Period</p>) 
:( <Line data={dataPM25}
 options={{
    title:{
      display:true,
      text:['PM2.5 Levels'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right' },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: 'µg/m³',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min: 0,
            //(Math.min.apply(Math, PM25Data))-Math.round((Math.min.apply(Math, PM25Data)*.01)+1), 
            max: maxData>75? (Math.round(maxData+ maxData*.1)):(75),
            //(Math.max.apply(Math, PM25Data))+(Math.max.apply(Math, PM25Data)*.01) +1 
          }   }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[PM25Data.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: false,
        "autoSkipPadding": 5, } }]
  },
  annotation: {
    annotations: [{
       
       id: 'hline3',
       type: 'line',
       mode: 'horizontal',
       scaleID: 'y-axis-0',
       value: 25,
       borderColor: '#d4b624',
       borderWidth: .75,
       color: '#d4b624',
       label: {
          backgroundColor: "#d4b624",
          content: "Moderate >25",
         
          enabled: false
       }
    }, {
       id: 'hline2',
       type: 'line',
       mode: 'horizontal',
       scaleID: 'y-axis-0',
       value: 55,
       borderColor: 'red',
       borderWidth: .75,
       label: {
          backgroundColor: "red",
          content: "Bad >55",
          enabled: false,
          
       }
    }, ]
 },
  tooltips:{
    intersect: false }  }}
 /> ) }

 {/* <div class="avgBox ">
 <p class="minmax ">{`min: ${minData} `}</p> 
 <p class="minmax ">{`avg: ${avgData.toFixed(2)}`}</p> 
 <p class="minmax ">{`max: ${maxData} `}</p> 
 <p class="minmax ">{`Standard Deviation: ${stdDev.toFixed(2)} `}</p>   
</div> */}

<div className="d-flex flex-row justify-content-around align-items-center">
{/* <table className="">
  <tbody>
  <tr className="">
    <td className="minmax" > Minimum  </td>
    <td className="minmax text-right">{minData.toFixed(2)}</td>
  </tr>
  <tr className="">
    <td className="minmax"> Average </td>
    <td className="minmax text-right">{avgData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Maximum </td>
    <td className="minmax text-right">{maxData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Standard Deviation </td>
    <td className="minmax text-right">{stdDev.toFixed(2)}</td>
  </tr>
  </tbody>
</table> */}

<div className="legend">
  <table>
    <tbody>
  <tr className="legendRow">
<td>Excessive</td> 
<td className="redBox"> { ` > 55 `}</td>
</tr>
<tr>
<td>Moderate</td> 
<td className="yellowBox">{ `> 25`}</td>
</tr>
<tr>
<td>Good</td> 
<td className="greenBox">{ ` < 25  `}</td>
</tr>
</tbody>
  </table>
</div>

</div>

</div>

)
  }

  export default Pm25Chart
