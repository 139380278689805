import React, {useContext} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Layouts from './layouts'
import Routes from './Routes'
import './css/tailwind.css'
import './css/main.css'
import './css/animate.css'
import './css/_components.css'
import UserProvider from "./providers/UserProvider";
import ClientProvider from "./providers/ClientProvider";
import SignInGS from './pages/SignInGS'
import Application from './pages/Application'

const App = () => {
  
  return (
    <Router>
      <ClientProvider>
    <UserProvider>
    <Application/>
    </UserProvider>
    </ClientProvider>
    </Router>
  )
}
export default App
