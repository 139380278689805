import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import { useParams, useHistory } from 'react-router-dom';
import Question from './Question';
import moment from 'moment';
import MyAuditATP from '../../components/charts/amcharts-atp-audit-scorecard'
import AtpSingle from './AtpSingle'
import { useSnackbar } from 'notistack'
import {ClientContext} from '../../providers/ClientProvider'

export default function AuditReportCard () {
  const {auditid}=useParams()
  const user = useContext(UserContext)
  const [auditDetails, setAuditDetails]=useState({})
  const [atpLoc, setAtpLoc] = useState('')
  const [atpValue, setAtpValue] = useState('')
  const [questions, setQuestions]=useState([])
  const [showCustodianForm, setShowCustodianForm]=useState(false)
  const [custodians, setCustodians]=useState([])
  const [atps, setAtps]=useState([])
  const [client, setClient] = useContext(ClientContext)
  useEffect(()=>{
    
    const loadData = async() =>
    {
      const responseAD = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/auditresult/single/${auditid}`
      }
      )
      console.log(responseAD.data[0])
      setAuditDetails(responseAD.data[0])
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
         'culper':'culper',
         'Content-Type':'application/json'},
       url: `https://gbmercury.com/auditscorecard/questions/${responseAD.data[0].AuditID}`
     }
     )
      setQuestions(response.data)
      const responseATP = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
         'culper':'culper',
         'Content-Type':'application/json'},
       url: `https://gbmercury.com/auditatp/audit/${auditid}`
     }
     )
      setAtps(responseATP.data)
     console.log(atps)
      const responseCust = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/audit/employee/all/${client}`
      }
      )
      setCustodians(responseCust.data)

    }
    loadData()   
    
  },[])
  return(
  <>
    <SectionTitle title="Audit Module" subtitle={`Audit Scorecard - ${auditid}`} />
    <div className="text-xl">{auditDetails.CampusName} {auditDetails.AreaName}</div> 
    <div className="text-lg">Audit Template: {auditDetails.AuditName}</div>
    <div className="text-lg">Custodian: {auditDetails.Custodian}</div>
    <div className="text-lg">Date Completed: {moment(auditDetails.PerformedOn*1000).format('MM/DD/YYYY')}</div>
    <Widget title="Audit Questions" >
    {questions?
    <>
      {questions.map((e)=>(
        <Question key={e.MatrixID} question={e} auditid={auditid} areaid={auditDetails.AreaID} campusid={auditDetails.CampusID} custodianid={auditDetails.CustodianID} />
      )
        
      )}


    </>
    :
    null
    }
    </Widget>
    <Widget title="ATP Data">
    {atps.length>0?
  <>
  {auditDetails.Status === 1 ? <MyAuditATP atpData={atps}  /> :null}
  {atps.map((oneATP)=>(
    <AtpSingle key={oneATP.ID} atp={oneATP} />
  )
    
  )}

</>
  :
  null
  }
    </Widget>
  </>
)}


