import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import './chartstyles.scss'

const EtohChart=({m})=>{

var etOHData=[]
var timestampsData=[]
var timestampDay=[]

m.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
       {
        if(Item.measurements){
        for(var i=0; i<Item.measurements.length; i++){
 
        if(Item.name==="VOC (EtOH)")
        {  
            etOHData.push(Item.measurements[i][1]); 
            timestampsData.push(moment(Item.measurements[i][0]).format(" h:mm"))
            timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }
       }


       const dataEtoh = {
        labels: timestampsData  ,
        datasets: [
          { 
            borderColor: etOHData.map(data=>
              {if(data>6){
                  return 'red'
              }else if(data>3){
                 return '#d4b624'
              }else{
                  return 'green'
              }} ) ,
              backgroundColor: etOHData.map(data=>
                {if(data>6){
                  return 'red'
              }else if(data>3){
                 return '#d4b624'
              }else{
                  return 'rgb(39, 156, 97)'
              }} ) ,
            
            borderWidth: .5,
            hoverBackgroundColor: 'rgb(91, 86, 113)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:.7,
            pointRadius:1.5,
            pointHoverRadius: 6,
            data: etOHData    }, 
      ], 
      
        };

        const minData=Math.min(...etOHData);
        const maxData=Math.max(...etOHData)
        const avgData = etOHData.reduce((a,b) => a + b, 0) / etOHData.length
          etOHData.map(function(value){
          var diff = value - avgData;
          return diff;
          
        });
        var squareDiffs = etOHData.map(function(value){
          var diff = value - avgData;
          var sqr = diff * diff;
          return sqr;
        });
        function average(data){
          var sum = data.reduce(function(sum, value){
            return sum + value;
          }, 0);
        
          var avg = sum / data.length;
          return avg;
        }
        
        var avgSquareDiff = average(squareDiffs)
        var stdDev = Math.sqrt(avgSquareDiff);

return(
<div>

{etOHData.length<1 ? (<p class="text-center m-5">No <b> VOC (Etoh)</b> Data for Time Period</p>) :
(<Line data={dataEtoh}
 options={{
    title:{
      display:true,
      text:['VOC (Etoh) Levels'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right' },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: 'ppm',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min: (Math.min.apply(Math, etOHData))-Math.round((Math.min.apply(Math, etOHData)*.01)+1), 
            max: (Math.max.apply(Math, etOHData))+(Math.max.apply(Math, etOHData)*.01) +8 }   }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[etOHData.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: false,
        "autoSkipPadding": 5, } }]
  },
  annotation: {
    annotations: [{
       
       id: 'hline3',
       type: 'line',
       mode: 'horizontal',
       scaleID: 'y-axis-0',
       value: 3,
       borderColor: '#d4b624',
       borderWidth: .75,
       color: '#d4b624',
       label: {
          backgroundColor: "#d4b624",
          content: "Moderate >25",
         
          enabled: false
       }
    }, {
       id: 'hline2',
       type: 'line',
       mode: 'horizontal',
       scaleID: 'y-axis-0',
       value: 6,
       borderColor: 'red',
       borderWidth: .75,
       label: {
          backgroundColor: "red",
          content: "Bad >55",
          enabled: false,
          
       }
    }, ]
 },
  tooltips:{
    intersect: false }  }}
 />)}

 {/* <div class="avgBox ">
 <p class="minmax ">{`min: ${minData} `}</p> 
 <p class=" minmax">{`avg: ${avgData.toFixed(2)}`}</p> 
 <p class="minmax ">{`max: ${maxData} `}</p> 
 <p class=" minmax">{`Standard Deviation: ${stdDev.toFixed(2)} `}</p>  
</div> */}

<div class="d-flex flex-row justify-content-around align-items-center">
{/* <table class="">
  <tbody>
  <tr class="">
    <td class="minmax" > Minimum  </td>
    <td class="minmax text-right">{minData.toFixed(2)}</td>
  </tr>
  <tr class="">
    <td class="minmax"> Average </td>
    <td class="minmax text-right">{avgData.toFixed(2)}</td>
  </tr>
  <tr>
    <td class="minmax"> Maximum </td>
    <td class="minmax text-right">{maxData.toFixed(2)}</td>
  </tr>
  <tr>
    <td class="minmax"> Standard Deviation </td>
    <td class="minmax text-right">{stdDev.toFixed(2)}</td>
  </tr>
  </tbody>
</table> */}

<div class="legend">
  <table>
    <tbody>
  <tr class="legendRow">
<td>Excessive</td> 
<td class="redBox"> { ` > 6 `}</td>
</tr>
<tr>
<td>Moderate</td> 
<td class="yellowBox">{ `> 3`}</td>
</tr>
<tr>
<td>Good</td> 
<td class="greenBox">{ ` < 3  `}</td>
</tr>
</tbody>
  </table>
</div>




</div>

</div>

)
  }

  export default EtohChart
