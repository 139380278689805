import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import axios from 'axios'
import {Link} from 'react-router-dom'
import Widget from '../../components/widget'
import  {UserContext} from "../../providers/UserProvider";
import {ClientContext} from '../../providers/ClientProvider'
import PersonnelRadar from '../../components/charts/PersonnelRadar/PersonnelRadar';
export default function AuditReportPersonnel (){
  const [client, setClient]=useContext(ClientContext)
  const user = useContext(UserContext)
  const [custodians, setCustodians]=useState([])
  const [radarATP, setRadarATP]=useState([])
  const [radarScore, setRadarScore]=useState([])
  
  
  useEffect(()=>{
    
    const loadData = async() =>
    {
     const responseCust = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/audit/employee/all/${client}`
      }
      )
      setCustodians(responseCust.data)
      setTheArrays(responseCust.data)
      
    }
  loadData()   
    
  },[])

  const setTheArrays = (arrayFeed) => {
    var tempScores=[]
    var tempATP=[]
    arrayFeed.map((forRadar)=>
      {
        tempScores.push({
          name: forRadar.Name,
          score: forRadar.Score
        })
        tempATP.push({
          name: forRadar.Name,
          score: forRadar.ATPAvg
        })
        
      })
      setRadarATP(tempATP)
      setRadarScore(tempScores)
    console.log(tempScores)
    console.log(tempATP)
   
  }
  return(
  <>
    <SectionTitle title="Pages" subtitle="Audit Report - Personnel" />
    <Widget title="Page title" description={<span>See Scores from Your Personnel Here</span>}>
    
    {custodians?
    <>
    
      <div className="flex flex-row">
        <div className="w-2/5 p-4">
          <PersonnelRadar  data={radarScore} title="Average Evaluation Score"/>
          <div className="text-lg">This chart shows the average evaluation score for each employee.  The farther away from the center point, the higher the employee's score.</div>
        </div>
        <div className="w-2/5 p-4">
          <PersonnelRadar  data={radarATP} title="Average ATP Score"/>
          <div className="text-xl">This chart shows the average ATP sample value for each employee.  The target for post disinfection ATP samples is 200 RLU or less.</div>
        </div>
      </div>
      <h3>Custodians</h3>
      <h5>Select a custodian below to go to their report.</h5>
    <ul>
       {custodians.map((custodian)=>(
        <li><Link to={`/auditreport-personnel-detail/${custodian.CustodianID}`}><h5 className="border-2 p-2">{custodian.Name}</h5></Link></li>
      )
      )}
      </ul>
    </>
      :null}
    </Widget>    
  </>
)}