import React, {Component} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
class MyAuditATP extends Component {
    componentDidMount() {
      let chart = am4core.create("atpchart", am4charts.XYChart);
      //chart.dateFormatter.dateFormat = "MM/DD/YYYY";
      chart.paddingRight = 20;
      console.log(this.props)
      let data = this.props.atpData
      chart.data = data;
      console.log(chart.data)
      console.log(data)        
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category="ID"
      //don't show the labels!!!! they don't look good.  dates are too long
      categoryAxis.fontSize="0px";
     
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
      
        let title = chart.titles.create();
        title.text="Audit ATP Samples";
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "ID";
      series.dataFields.valueY = "ATPResult";
      series.tooltipText = `
      {ATPSite}
      ----
      {valueY.value} RLU
      `;

      chart.cursor = new am4charts.XYCursor();
  
 //     let scrollbarX = new am4charts.XYChartScrollbar();
      //scrollbarX.renderer.labels.template.disabled=true;

   //   scrollbarX.series.push(series);
      
   //   chart.scrollbarX = scrollbarX;
    
      this.chart = chart;
    }
  
    componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }
  
    render() {
      return (
        <div id="atpchart" style={{ width: "100%", height: "300px" }}></div>
      );
    }
  }
  export default MyAuditATP