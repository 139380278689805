import React, {useState, useEffect} from 'react';
import {Scatter} from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes'
import moment from 'moment'
import './Devicecomparestyles.scss'
import { CSVLink } from "react-csv";

export default function DeviceCompareScatter (props) {
  const [message, setMessage]=useState("")
  const [csvExport, setCSVExport] = useState([])
  const [maximumVal, setMaximumVal]=useState({maxValue:props.maxValue})
  var [dataCo2, setDataCo2]=useState([])
   var theMaxVal=1
    useEffect(()=>{
      const loadData = async() =>
      {
      var csvData1=[]
      var csvData2=[]
      var datasetObjs =[]
      var measureArr=[]
      var maxvalLoop=0
    //map through props data 
    props.data.map((e)=>{
      //filter by data type we want then map that
      var filteredData=e.measurements.filter(m=>m.name===props.dataType)[0]
      filteredData.measurements.map((subCollection)=>
      {
        measureArr.push({ x:subCollection[0], y:subCollection[1]})
        if(subCollection[1]>maxvalLoop)
        {
          maxvalLoop=subCollection[1]
        }
        csvData1.push({date:moment(subCollection[0]).format("MM/DD/YYYY h:mm a"), measurement: subCollection[1]})
      }) // end of measurement loop
      datasetObjs.push({label:e.friendlyName,  data:measureArr, showLine: true, fill:false,
      borderWidth: 1,})
      csvData2.push({label:e.friendlyName,  data:csvData1})
      measureArr=[]
      csvData1=[]
    }) // end of props map
    setCSVExport(csvData2)
    setDataCo2(datasetObjs)
    if(maximumVal.maxValue<maxvalLoop){
      setMaximumVal({maxValue:maxvalLoop})
    }    
  }
  loadData() 
  console.log(props.maxValue) 
  //theMaxVal=props.maxValue
  },[props.data])

var scatterData = {datasets:dataCo2}

var scatterOptions= {
    title:{
        display:true,
        text:[`${props.friendlyName} Levels`],
        fontSize:28
      },
  responsive: true,
  scales: {      
    xAxes: [{
        scaleLabel: {
            display: true,
            labelString: `Data from last 24 hours `,
             fontSize: 15 },
      type: 'time',
      time: {
          unit: 'minute'
      },
      
  }],
    yAxes: [{
        scaleLabel: {
            display: true,
            labelString: props.uom,
            fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
      ticks: {
        beginAtZero: true,
        stepSize: props.stepSize,
        max: maximumVal.maxValue
      }
    }],
    plugins: {
      colorschemes: {
          scheme: 'brewer.Paired12'
      }
  }
  }
}

    return <div className="mt-16">
    <Scatter options={scatterOptions} data={scatterData} />
    <h6 style={{color:'#ad3115'}}>{message}</h6>
    <div className="flex-wrap text-center justify-items-center">
    Download {props.friendlyName} data by device:
    {csvExport.map((indiv, index)=>(
     <CSVLink className="border-b border-black mx-2 inline-block" key={index} data={indiv.data} separator={','}
   filename={`${indiv.label}.csv`} >
         {props.dataType} for {indiv.label}
       
  </CSVLink>
  
  ))
  }
    </div>
</div>
}
