import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import axios from 'axios'
import Widget from '../../components/widget'
import  {UserContext} from "../../providers/UserProvider";
import {ClientContext} from '../../providers/ClientProvider'
import PersonnelHorizBar from '../../components/charts/PersonnelHorizBar/PersonnelHorizBar';
import { useParams } from 'react-router-dom';
import moment from 'moment'

export default function AuditReportPersonnelDetail (){
  const {custodianID} = useParams()
  const [client, setClient]=useContext(ClientContext)
  const user = useContext(UserContext)
 // const [custodian, setCustodian]=useState([])
  const [custATP, setCustATP]=useState([])
  // const [radarATP, setRadarATP]=useState([])
  // const [radarScore, setRadarScore]=useState([])
  
  
  useEffect(()=>{
    
    const loadData = async() =>
    {
    //  const responseCust = await axios(
    //     {
    //     method: 'get',
    //     headers: {'Accept':'application/json',
    //       'culper':'culper',
    //       'Content-Type':'application/json'},
    //     url: `https://gbmercury.com/auditcustodian/employee/${custodianID}`
    //   }
    //   )
    //   setCustodian(responseCust.data)
    var link=`https://gbmercury.com/auditatp/custodian/${custodianID}`
    console.log(link)  
    const responseATP = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: link
      }
      )
      setCustATP(responseATP.data)
      
    }
  loadData()   
  },[])


  return(
  <>
    <SectionTitle title="Pages" subtitle="Audit Report - Personnel" />
    <Widget title="Page title" description={<span>Detailed Personnel Report</span>}>
    
    here is my page {custodianID}

    { custATP ? 
    (
      <>
      <PersonnelHorizBar data={custATP} title="ATP Data - last 365 days"/>
      </>
    )
    : null}
    </Widget>    
  </>
)}