import firebase from 'firebase/app' 
import 'firebase/auth'
import 'firebase/firestore'
// eslint-disable-next-line
import { functions } from "firebase";
import axios from 'axios';


const firebaseConfig = {
    apiKey: "AIzaSyCFq-_xUJ6RB_IXTLRUVHtgFN-k9haTZyQ",
    authDomain: "matrix2-26dce.firebaseapp.com",
    databaseURL: "https://matrix2-26dce.firebaseio.com",
    projectId: "matrix2-26dce",
    storageBucket: "matrix2-26dce.appspot.com",
    messagingSenderId: "100714657528",
    appId: "1:100714657528:web:652de17568e8b911c76d0d",
    measurementId: "G-CC1PPDX63Y"
  };
  
  firebase.initializeApp(firebaseConfig);

  export const storage = firebase.storage();
  export const auth=firebase.auth();
  export const firestore = firebase.firestore();

  export const generateUserDocument = async(user, additionalData)=>{
    if(!user) return

    const userRef = firestore.doc(`users/${user.uid}`)
    const snapshot = await userRef.get()
    if(!snapshot.exists){
      const {email, displayName, photoURL}=user
      try{
        await userRef.set({
          displayName,
          email,
          photoURL,
          ...additionalData
        })
      }
      catch(error){
        console.log("Error creating user document", error);
      }

    }
    return getUserDocument(user.uid);
  }
  
  const getUserDocument=async uid=>{
    if(!uid)return null
    try{
      const userDocument = await firestore.doc(`users/${uid}`).get()
      var dataResult = await getMatrixData(userDocument.data().matrixID)
      
      return{
        uid,
        ...dataResult[0],
        ...userDocument.data(),
        
        
      }
    }
      catch (error){
        console.error("error fetching user",error)
      }
  }

  const getMatrixData=async uid=>{
    if(!uid)return null
    try{
      const options =   
    {
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/userx/oneuser/${uid}`,
    }
      const response = await axios(options)
      
      return response.data
    }
      catch (error){
        console.error("error fetching user",error)
      }
  }
  // Get a reference to the storage service, which is used to create references in your storage bucket
 
  export default firebase;