import React, {useContext, useState , useEffect, Fragment} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'
import MyBarChart from '../../components/charts/auditatp'
import '../Auditstyles.scss'
import { withRouter } from 'react-router-dom';
import { raf } from '@amcharts/amcharts4/.internal/core/utils/AsyncPending'
import {ClientContext} from '../../providers/ClientProvider'
const AuditAreasReport= ({history, match}) => {
  const [allATP, setAllATP]=useState()
  var auditSamples=[]

  function AddATP(array){
var sum=array.reduce(function(a,b){
  return a+b
})
return sum;
  }
  
  const [client, setClient] = useContext(ClientContext)
  const [campusState, setCampusState]= useState([])
  const [auditResultsState,setAuditResultsState]=useState()
  const [areaState, setAreaState]=useState()
  const [yearAgo, setYearAgo]=useState(Math.round(new Date().getTime()-31556926000))
const campusid=match.params.id
console.log(campusid)
  //const [mainAudit, setMainAudit]= useState()
  useEffect(()=>{
    const options=   
    {
        //coda use 288 clebareas
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/audit/area/all/${campusid}`, //GOOD
    }

    const options2 =   
    {
        //coda  audit results 262
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/auditresult/history/${client}`,
    } //url:'https://gbmercury.com/auditresult/area/1'

    const options3 =   //USE  LINE 29 clebcampus
    {
        //coda 
      method: 'get',
      headers: {'Accept':'application/json',
        'culper':'culper',
        'Content-Type':'application/json'},
      url: `https://gbmercury.com/audit/facility/${campusid}`, //GOOD
    }


    
    const loadData = async() =>
    {
  
     axios.all([
        axios(options),
        axios(options2),
        axios(options3),
  

      ]).then(axios.spread(function(areas, results, campus) {
setCampusState(campus.data)
setAreaState(areas.data)
setAuditResultsState(results.data)
console.log(areaState)
      })).catch(err=>{
        console.log(err + ' there was an error')
      })
  
    }
    loadData()   
 

  },[ ])
  
 
  if (areaState && auditResultsState && campusState) {
    console.log(areaState)
    console.log(campusState[0].CampusName)
return (
  <>
    <SectionTitle>{campusState[0].Campus}</SectionTitle>
    <Widget title=" " description={<span style={{fontSize:'24px'}}>{campusState[0].CampusName} Areas</span>}>
     {areaState.map((area,Index)=>{
         return <div key={Index}>
             <br></br>
<h6>{area.AreaName}</h6>
<table style={{width:'100%'}}>
    <tbody>
        <tr >
         <td className='areagreen'>
             {area.Score!==null ? (area.Score):('0') }%
             <br></br>
             <span style={{fontSize: '12px'}}> Score</span></td>
        <td className="areablue">
            {area.ATPAvg!==null ? (area.ATPAvg):('0') } RLU <br></br> 
            <span style={{fontSize: '12px'}}>ATP Average</span> </td>
            <td> </td>
            <td><button onClick={()=> 
              history.push(`/auditareascorecard/${area.AreaID}/${area.CampusID}`)} className="arear">Area Report</button></td>
        </tr>
       
        {console.log(areaState)}
       
    </tbody>
</table>
<br></br>
<h6>Audit History</h6>
<table style={{backgroundColor: '#e4eef2', width:'100%', padding:'531px'}}>
    <thead>
        <tr style={{fontWeight:'bold'}} >
            <td className="tabled">Date</td>
            <td className="tabled">Score</td>
            <td className="tabled">ATP Average</td>
            <td className="tabled">Action</td>
        </tr>
    </thead>
    <tbody>
    {auditResultsState.filter(rsf=> rsf.AreaName==area.AreaName).map((rs,Indx)=>{
                return<tr key={Indx} >
                    {console.log(auditResultsState)}
                    <td className="tabled">{moment(rs.PerformedOn*1000).format("MM/DD/YYYY")}</td>
                <td className="tabled">{rs.Score}</td>
                <td className="tabled">{rs.AvgATP}</td>
                <td className="tabled"><button onClick={()=> 
                    history.push(`/auditfacilityscorecard/${rs.AuditResultsID}/${area.CampusID}`)}className="scorecard"> Scorecard </button></td>
                </tr>
            })} 
            {console.log(auditResultsState)}
        
    </tbody>
</table>
<br></br>
<hr className="hr"></hr>
         </div>
         
     })}  
     {/* end of area map */}
    
    </Widget>
  </>
) }
else {
  return(
    <>
      <SectionTitle title="Pages" subtitle="Audit Report - Facility" />
      <Widget title="Page Title" description={<span>Audit Areas for facility- </span>}>
        
        Loading Chart Data... 
      </Widget>
    </>
)}}
export default withRouter(AuditAreasReport)
