import React, { useContext, useEffect, useState } from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import MyBarChart from '../../components/charts/am5charts-all-atp'
import axios from 'axios'
import moment from 'moment'
import { ClientContext } from '../../providers/ClientProvider'
import { CSVLink} from 'react-csv'
import AtpSingle from './AtpSingle'
import { useSnackbar } from 'notistack'

export default function ATPClient() {
    const { enqueueSnackbar} = useSnackbar()
    // eslint-disable-next-line
    const [client, setClient] = useContext(ClientContext)
    const [allATP, setAllATP] = useState()
    const [csvExport, setCSVExport] = useState({})
    const [atpLoc, setAtpLoc] = useState('')
    const [atpValue, setAtpValue] = useState('')
    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget
        if (name === 'AtpLoc') {
            setAtpLoc(value)
        } else if (name === 'AtpValue') {
            setAtpValue(value)
        }
    }
    const addATP = async (event) => {
        event.preventDefault()
        const rightNow = moment().unix()
        const rightNowFormat = moment(rightNow * 1000).format(
            'MM/DD/YYYY hh:mm:ss a'
        )
        console.log(rightNow)
        const options = {
            method: 'post',
            data: {
                atpLoc: atpLoc,
                atpValue: atpValue,
                dateTime: rightNow,
                clientID: client,
            },
            headers: {
                Accept: 'application/json',
                culper: 'culper',
                'Content-Type': 'application/json',
            },
            url: 'https://gbmercury.com/atps/gs/addatp',
        }
        const response = await axios(options)
        setCSVExport((existingAtps) => [
            ...existingAtps,
            {
                'Sample ID': response.data.insertId,
                'Sample Location': atpLoc,
                'ATP Value': atpValue,
                'Sample Date': rightNowFormat,
            },
        ])
        setAllATP((existingAtps) => [
            ...existingAtps,
            {
                ATPID: response.data.insertId,
                Location: atpLoc,
                ATPVal: atpValue,
                formattedDate: rightNowFormat,
            },
        ])
        setAtpLoc('')
        setAtpValue('')
        enqueueSnackbar('ATP Sample Added',{variant: 'success',});
    }
    const removeAnATP = async (atpToKill) => {
        console.log('button clicked!')
        const options = {
            method: 'post',
            data: { atpID: atpToKill, clientID: client },
            headers: {
                Accept: 'application/json',
                culper: 'culper',
                'Content-Type': 'application/json',
            },
            url: 'https://gbmercury.com/atps/gs/removeatp',
        }
        await axios(options)
        console.log(options)
        setCSVExport(
            csvExport.filter((subAtp) => subAtp['Sample ID'] !== atpToKill)
        )
        setAllATP(allATP.filter((subAtp) => subAtp.ATPID !== atpToKill))
        enqueueSnackbar('ATP Deleted',{variant: 'error',});
    }
    var dataResult = []
    useEffect(() => {
        if (client > 1) {
            const options = {
                method: 'get',
                headers: {
                    Accept: 'application/json',
                    culper: 'culper',
                    'Content-Type': 'application/json',
                },
                url: `https://gbmercury.com/atps/gs/clientsamples/${client}`,
            }
            console.log(options)
            const loadData = async () => {
                var csvBuilder = []
                const response = await axios(options)
                dataResult = response.data
                dataResult.sort((a, b) => (a.ATPDate > b.ATPDate ? 1 : -1))
                dataResult.map((e) => {
                    e.ATPDate = e.ATPDate * 1000
                    e.formattedDate = moment(e.ATPDate).format(
                        'MM/DD/YYYY hh:mm:ss a'
                    )
                    e.monthYear = moment(e.ATPDate).format('MM/YYYY')
                    e.quarterYear = moment(e.ATPDate).format('Q/YYYY')
                    e.Year = moment(e.ATPDate).format('YYYY')
                    csvBuilder.push({
                        'Sample ID': e.ATPID,
                        'Sample Date': e.formattedDate,
                        'Sample Location': e.Location,
                        'ATP Value': e.ATPVal,
                    })
                })
                setCSVExport(csvBuilder)
                setAllATP(dataResult)
            }
            loadData()
        }
    }, [client])
    if (allATP) {
        return (
            <>
                <SectionTitle title='Pages' subtitle='All ATP Samples' />
                <Widget
                    title='Page title'
                    description={<span>See a chart with all samples here</span>}
                >
                    <MyBarChart chartData={allATP} />
                    <CSVLink data={csvExport} filename={'my-atp-samples.csv'}>
                        Download records to CSV
                    </CSVLink>
                </Widget>

                <Widget title='Add/Remove ATP Samples'>
                    <form>
                        <label>Sample Location</label>
                        <input
                            className='w-full text-lg p-3 mb-2 bg-grey-200'
                            type='text'
                            name='AtpLoc'
                            value={atpLoc}
                            onChange={(event) => onChangeHandler(event)}
                        />
                        <label>ATP Value (RLU)</label>
                        <input
                            className='w-full text-lg p-3 mb-2 bg-grey-200'
                            type='text'
                            name='AtpValue'
                            value={atpValue}
                            onChange={(event) => onChangeHandler(event)}
                        />

                        <button
                            disabled={!atpLoc || !atpValue}
                            className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40'
                            onClick={(event) => {
                                addATP(event)
                            }}
                        >
                            Add Sample
                        </button>
                    </form>
                    {allATP.length > 0 ? (
                        <>
                            {allATP.map((oneATP) => (
                                <AtpSingle
                                    key={oneATP.ATPID}
                                    atp={oneATP}
                                    toRemoveIt={(atpToKill) =>
                                        removeAnATP(atpToKill)
                                    }
                                />
                            ))}
                        </>
                    ) : null}
                </Widget>
            </>
        )
    } else {
        return (
            <>
                <SectionTitle title='Pages' subtitle='My ATP Samples' />
                <Widget
                    title='Page title'
                    description={<span>See a chart with all samples here</span>}
                >
                    Loading Chart Data...
                </Widget>
            </>
        )
    }
}
