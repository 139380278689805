import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import {Link} from 'react-router-dom'
import  {UserContext} from "../../providers/UserProvider";
import {ClientContext} from '../../providers/ClientProvider'

import {useParams} from 'react-router-dom'
import { AiFillDelete } from 'react-icons/ai'

export default function AuditSettingsTemplateEdit (){
    const user = useContext(UserContext);
    const {templateid} = useParams()
    const [questionID, setQuestionID] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [auditDetails, setAuditDetails] = useState({});
    const [lastName, setLastName] = useState('');
    const [title, setTitle] = useState('');
    const[questionBank, setQuestionBank]=useState([])
    const[templateQuestions, setTemplateQuestions]=useState([])
    const onChangeHandler = (event) => {
      const {name, value} = event.currentTarget;
      if(name === 'FirstName') {
          setFirstName(value);
      }
      else if(name === 'LastName') {
        setLastName(value);
    }
    else if(name === 'Title') {
      setTitle(value);
    }
    else if(name === 'QuestionID') {
      setQuestionID(value);
    }
  };
  const [client, setClient] = useContext(ClientContext)
  const [cards, setCards]=useState([])
    const reorderQuestion = async(event) =>
      {
          event.preventDefault();  
          const options =   
          {
            method: 'post',
            data: {'firstName':firstName,
                  'lastName':lastName,
                  'title':title,
                  'clientID':user.CustID},
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: 'https://gbmercury.com/audit/employee/update',
          }
          const response = await axios(options)
          console.log(response)
          
          
      }
      const removeQuestion = async(event) =>
      {
           event.preventDefault();  
          const theMatrixID = event.target.MatrixID.value
           const options =   
          {
            method: 'post',
            data: {'matrixID':theMatrixID,
                  },
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: 'https://gbmercury.com/audit/auditquestions/remove',
          }
          const response = await axios(options)
            setTemplateQuestions(templateQuestions.filter((item)=> item.MatrixID != theMatrixID))
          // setTemplateQuestions(tableRows=>[...tableRows,{MatrixID:response.data.insertId,QuestionID:questionID, Question: questionDetail.Question, sortOrder:0}])
          console.log(response)
          }
      const addQuestion = async(event) =>
      {
          event.preventDefault();  
          const options =   
          {
            method: 'post',
            data: {'templateID':templateid,
                  'questionID':questionID,
                  'clientID':user.CustID},
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: 'https://gbmercury.com/audit/auditquestions/new',
          }
          const response = await axios(options)
          const questionDetail=questionBank.find(quest=>quest.QuestionID == questionID)
          setTemplateQuestions(tableRows=>[...tableRows,{MatrixID:response.data.insertId,QuestionID:questionID, Question: questionDetail.Question, sortOrder:0}])
          console.log(response)
      }
    useEffect(()=>{
      const loadData = async() =>
      {
        const responseB = await axios({
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/audit/question/all/${client}`
        })
        setQuestionBank(responseB.data)
        const responseA = await axios({
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/audit/audit/${templateid}`
        })
        setAuditDetails(responseA.data[0])
        const response = await axios(
          {
          method: 'get',
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/audit/auditquestions/all/${templateid}`
        }
        )
        setTemplateQuestions(response.data)
      }
      loadData()   
      
    },[])

    return (
        <>
        <Link to={`/auditsettings-template`}>back to template list</Link>
        <SectionTitle title="Audit Module" subtitle={auditDetails.Name} />
        <Widget title="Audit Builder" description={<span>Customize Your Audit Setup</span>}>
        
        <form>
          <select className=" w-full p-3 mb-2 bg-grey-200" name="QuestionID"
          onChange = {(event) => onChangeHandler(event)}
          >
            <option value="0" >Please Select a Question</option>
          {questionBank.map((qlist)=>(
                <option key={qlist.QuestionID}  value={qlist.QuestionID}>{qlist.Question}</option>   
            )
            )}
          </select>
          <button 
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {addQuestion(event)}}
            >
                Add Question to Audit
            </button>
        </form>
        <ul className="list-disc">
            
            {templateQuestions.map((singleQ)=>(
                <li className="p-3 w-full hover:bg-grey-100" key={singleQ.MatrixID}>
                  <form onSubmit = {(event) => {removeQuestion(event)}}>
                    {singleQ.Question}
                    <button 
                    className="bg-red-400 p-2 text-white float-right align-middle"
                    key={singleQ.MatrixID} 
                    
                    >
                      <input type="hidden" name="MatrixID" value={singleQ.MatrixID}/>
                      <span >
                      <AiFillDelete size={15}/>
                      </span>
                    </button>
                  </form>
                  </li>
                
            )
            )}
            
        </ul>
        
        </Widget>
            </>
    );
}
