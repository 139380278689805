import React, {useState , useEffect, Fragment,useContext} from 'react'

//import Widget from '../../components/widget'
import axios from 'axios'
import moment from 'moment'

//import { UserContext } from '../../providers/UserProvider'
import { withRouter } from 'react-router-dom';
import { GiConsoleController } from 'react-icons/gi'

const AuditAreasQuestionDetail=({quesID, area})=>{
    const [questionState, setQuestionState]=useState({})
useEffect(()=>{

    
    const options=
    {
        method: 'post',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
          data:{
              areaID: area,
              questionID: quesID
          },
        url: `https://gbmercury.com/auditresult/area/questionsummary`, 
      }
const loadQuestions= async()=>{
    axios(options).then(question=>{
       
        setQuestionState(question.data)
    })

}
loadQuestions()
},[quesID])
if(questionState.detailList){
    return(
 <> 

 <td style={{textAlign: 'center'}}>{questionState.passRate}%</td>
<ul>
    {questionState.detailList.map((qs,idx)=>{
       return (
       < li style={{textAlign: 'center'}} key={idx}>
           {moment(qs.dateTime*1000).format("MM/DD/YY")}-{qs.Notes} 
       </li>
       )
   })} 
</ul>

    {console.log(questionState)} 
    </>  
    )
}
else {
    return(
<td>blank</td>
    )
    
}

}
export default withRouter( AuditAreasQuestionDetail)