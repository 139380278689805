import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider"
import AuditList from './AuditList'
import {ClientContext} from '../../providers/ClientProvider'


export default function AuditHistory () {
  //const user = useContext(UserContext)
  const [client, setClient]=useContext(ClientContext)
  const [cards, setCards]=useState([])
  useEffect(()=>{
    
    const loadData = async() =>
    {
      
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/auditresult/history/${client}`
      }
      )
      setCards(response.data)
    }
    loadData()   
    
  },[client])
  return(
  <>
    <SectionTitle title="Pages" subtitle="Audit History" />
    <Widget title="Completed Audit List" description={<span>View Completed Audits Here</span>}>
      <AuditList Audits={cards}/>
      
    </Widget>
  </>
  )
}

