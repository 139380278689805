import React, {Component} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
class ATPStDev extends Component {
    componentDidMount() {
      let chart = am4core.create("atpstdev", am4charts.XYChart);
      chart.paddingRight = 20;
      let data = this.props.chartData
      chart.data = data;
      console.log(data)  
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category="PrettyDate"
      //categoryAxis.fontSize="0px";
     //console.log(this.props.maxVal)
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
      valueAxis.max=this.props.maxVal
      let title = chart.titles.create();
      title.text="ATP Standard Deviation Over Time";
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.categoryX = "PrettyDate";
      series.dataFields.valueY = "ATPStDev";
      series.tooltipText = `{PrettyDate} - {ATPStDev} RLU `;
      series.tensionX=0.77
      chart.cursor = new am4charts.XYCursor();
  
      // let scrollbarX = new am4charts.XYChartScrollbar();
     
      // scrollbarX.series.push(series);
      
      // chart.scrollbarX = scrollbarX;
    
      this.chart = chart;
    }
  
    componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }
  
    render() {
      return (
        <div id="atpstdev" style={{ width: "100%", height: "300px" }}></div>
      );
    }
  }
  export default ATPStDev