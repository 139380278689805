import React, {useState, useContext, useEffect} from 'react'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import {ClientContext} from '../../providers/ClientProvider'

import QuestionList from './QuestionList';

export default function AuditSettingsQuestion (){
  const [client, setClient] = useContext(ClientContext)
  const user = useContext(UserContext);
  const [question, setQuestion] = useState('');
  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
  
    if(name === 'Question') {
        setQuestion(value);
    }
};
  const [tableRows, setTableRows]=useState([])
  const addQuestion = async(event) =>
    {
        event.preventDefault();  
        const options =   
        {
          method: 'post',
          data: {'question':question,
                'clientID':user.CustID},
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/audit/question/new',
        }
        
        const response = await axios(options)
        setTableRows(tableRows=>[...tableRows,{QuestionID:response.data.insertId,Question:question}])
        console.log(response)
        setQuestion("")
    }
  useEffect(()=>{
    
    const loadData = async() =>
    {
      
      const response = await axios({
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/audit/question/all/${client}`
      })
      setTableRows(response.data)
    }
    loadData()   
    
  },[])
  
  return (<>
    
    <SectionTitle title="Audit Module" subtitle="Settings - Questions" />
    <Widget title="Question Bank" description={<span>Add New Audit Questions Here</span>}>
    
    <form >
            <label>Question</label>
            <input className="w-full p-3 mb-2 bg-grey-200" type="text" name="Question"
            value = {question}
            onChange = {(event) => onChangeHandler(event)}
            />
            <button 
            disabled={!question}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {addQuestion(event)}}
            
            >
                Add New Question
            </button>
            

            </form>
    </Widget>
    <Widget title="Question Bank" description={<span>View/Change Your Audit Questions Here</span>}>
    
    <QuestionList Questions={tableRows}/>
    </Widget>
    
  </>)
}
