import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import { useParams, useHistory } from 'react-router-dom';
import Question from './Question';
import moment from 'moment';
import MyAuditATP from '../../components/charts/amcharts-atp-audit-scorecard'
import AtpSingle from './AtpSingle'
import { useSnackbar } from 'notistack'
import {ClientContext} from '../../providers/ClientProvider'

export default function AuditDashboard () {
  let history = useHistory();
  const [client, setClient] = useContext(ClientContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {auditid}=useParams()
  const user = useContext(UserContext)
  const [auditDetails, setAuditDetails]=useState({})
  const [atpLoc, setAtpLoc] = useState('')
  const [atpValue, setAtpValue] = useState('')
  const [custodianID, setCustodianID] = useState('')
  const [questions, setQuestions]=useState([])
  const [showCustodianForm, setShowCustodianForm]=useState(false)
  const [custodians, setCustodians]=useState([])
  const [atps, setAtps]=useState([])
    const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'AtpLoc') {
        setAtpLoc(value);
    }
    else if(name === 'AtpValue') {
      setAtpValue(value);
    }
    else if(name === 'CustodianID') {
      setCustodianID(value);
    }
    
};
  const addATP = async(event) =>
    {
        event.preventDefault();  
        const rightNow=moment().unix()
        console.log(rightNow)
        const options={
          method: 'post',
          data: {'atpLoc':atpLoc,
                'atpValue':atpValue,
                'custodian':auditDetails.CustodianID,
                'campus':auditDetails.CampusID,
                'area':auditDetails.AreaID,
                'audit':auditid,
                'dateTime':rightNow,
                'clientID':user.CustID},
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/auditatp/new',
        }
        const response = await axios(options)
        setAtps(existingAtps=>[{ID:response.data.insertId,ATPSite:atpLoc, ATPResult:atpValue, AreaID:auditDetails.AreaID, CustodianID:auditDetails.CustodianID, ClientID:user.CustID, CampusID:auditDetails.CampusID, AuditResultsID:auditid,DateTime:moment().unix()}, ...existingAtps])
        setAtpLoc("")
        setAtpValue("")
        enqueueSnackbar('ATP Sample Added',{variant: 'success',});
        
    }
    const finalizeAudit = async(event) =>
    {
        event.preventDefault();  
        const options={
          method: 'get',
         headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: `https://gbmercury.com/auditscorecard/finalize/${auditid}`,
        }
        const response = await axios(options)
        enqueueSnackbar('Audit Finalized',{variant: 'success',});
        history.push('/audit-dashboard')
    }
    const removeAnATP = async(atpToKill)=> 
    {
      console.log("button clicked!")
      const options={
        method: 'post',
        data: {'atpid':atpToKill,
              'clientID':user.CustID},
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: 'https://gbmercury.com/auditatp/remove',
      }
      const response = await axios(options)
      console.log(options)
      setAtps(atps.filter(subAtp => subAtp.ID!==atpToKill))
      enqueueSnackbar('ATP Deleted',{variant: 'error',});
    }
    const updateCustodian = async(event) =>
    {
        event.preventDefault();  
        const options={
          method: 'post',
          data: {'custodianID':custodianID,
                'auditid':auditid,
                },
          headers: {'Accept':'application/json',
            'culper':'culper',
            'Content-Type':'application/json'},
          url: 'https://gbmercury.com/auditscorecard/employee/update',
        }
        const response = await axios(options)
        const newCust= custodians.filter(cust=>cust.CustodianID==custodianID)
        setAuditDetails({...auditDetails, CustodianID : custodianID,
        Custodian:newCust[0].Name} )
        setShowCustodianForm(!showCustodianForm)
        enqueueSnackbar('Custodian Updated',{variant: 'success',});
    }
  useEffect(()=>{
    
    const loadData = async() =>
    {
      const responseAD = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/auditresult/single/${auditid}`
      }
      )
      console.log(responseAD.data[0])
      setAuditDetails(responseAD.data[0])
      const response = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
         'culper':'culper',
         'Content-Type':'application/json'},
       url: `https://gbmercury.com/auditscorecard/questions/${responseAD.data[0].AuditID}`
     }
     )
      setQuestions(response.data)
      const responseATP = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
         'culper':'culper',
         'Content-Type':'application/json'},
       url: `https://gbmercury.com/auditatp/audit/${auditid}`
     }
     )
      setAtps(responseATP.data)
     console.log(atps)
      const responseCust = await axios(
        {
        method: 'get',
        headers: {'Accept':'application/json',
          'culper':'culper',
          'Content-Type':'application/json'},
        url: `https://gbmercury.com/audit/employee/all/${client}`
      }
      )
      setCustodians(responseCust.data)

    }
    loadData()   
    
  },[])
  return(
  <>
    <SectionTitle title="Audit Module" subtitle={`Audit Scorecard - ${auditid}`} />
    <div className="text-xl">{auditDetails.CampusName} {auditDetails.AreaName}</div> 
    <div className="text-lg">Audit Template: {auditDetails.AuditName}</div>
    <div className="text-lg" onClick={()=>{setShowCustodianForm(!showCustodianForm)}}>{auditDetails.Custodian}</div>
    {showCustodianForm && <form >
    
    <select name="CustodianID"
    onChange = {(event) => onChangeHandler(event)}
    >
      {
        custodians?
        <>
        {custodians.map((c)=>(
          <option key={c.CustodianID} value={c.CustodianID} >{c.Name}</option>
        ))}
        </>
        :
        null
      }

    </select>
    <button 
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {updateCustodian(event)}}
            >
            Update Custodian
            </button>
    </form>}
    <div className="text-lg">Score each question, add notes as appropriate, and hit update for each question to set a score.  Use the ATP form to add ATP samples to the audit.  Once complete, use the Finalize Audit button at the bottom to close out this audit.</div>
    <Widget title="Audit Questions" >
    {questions?
    <>
      {questions.map((e)=>(
        <Question key={e.MatrixID} question={e} auditid={auditid} areaid={auditDetails.AreaID} campusid={auditDetails.CampusID} custodianid={auditDetails.CustodianID} />
      )
        
      )}


    </>
    :
    null
    }
    </Widget>
    <Widget title="ATP Data">
    <form >
            <label>Sample Location</label>
            <input className="w-full text-lg p-3 mb-2 bg-grey-200" type="text" name="AtpLoc"
            value = {atpLoc}
            onChange = {(event) => onChangeHandler(event)}
            />
            <label>ATP Value (RLU)</label>
            <input className="w-full text-lg p-3 mb-2 bg-grey-200" type="text" name="AtpValue"
            value = {atpValue}
            onChange = {(event) => onChangeHandler(event)}
            />
            
            <button 
            disabled={!atpLoc || !atpValue}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {addATP(event)}}
            >
            Add Sample
            </button>
            

            </form>
    {atps.length>0?
  <>
  {auditDetails.Status === 1 ? <MyAuditATP atpData={atps}  /> :null}
  {atps.map((oneATP)=>(
    <AtpSingle key={oneATP.ID} atp={oneATP} toRemoveIt={atpToKill=>removeAnATP(atpToKill)} />
  )
    
  )}

</>
  :
  null
  }
    </Widget>
    <button 
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-40"
            onClick = {(event) => {finalizeAudit(event)}}
            >
            Finalize Audit
            </button>
  </>
)}


