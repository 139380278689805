import React, {createContext, useState, useEffect} from 'react'

export const ClientContext = createContext()

export default function ClientProvider (props) {
  const [client, setClient]=useState(0)
  useEffect(()=>{
      setClient(0)
  },[])     
  return(
            <ClientContext.Provider value={[client,setClient]}>
               {props.children}
            </ClientContext.Provider>
        )
}
