import React, {useState, useContext, useEffect} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import axios from 'axios'
import  {UserContext} from "../../providers/UserProvider";
import  {ClientContext} from "../../providers/ClientProvider";
import { useSnackbar } from 'notistack'
export default function ChangeOffice () {
  const { enqueueSnackbar} = useSnackbar()
    const user = useContext(UserContext)
    const [client, setClient] = useContext(ClientContext)
    const [clientList, setClientList]=useState([])
    console.log(client)
    useEffect(()=>{
        const loadData = async() =>
        {
          const responseAD = await axios(
            {
            method: 'get',
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: `https://gbmercury.com/userx/getrepdetails/${user.isGBEmployee}`
          }
          )
          console.log(responseAD.data)
          setClientList(responseAD.data)
        }
        loadData()   
        }
        ,[])
        const updateClientID = async(key) => 
          {
            const options={
            method: 'post',
            data: {'clientID':key,
                  'userID':user.userid,
                  },
            headers: {'Accept':'application/json',
              'culper':'culper',
              'Content-Type':'application/json'},
            url: 'https://gbmercury.com/userx/changeclient',
          }
          setClient(key)
          const response = await axios(options)
          enqueueSnackbar('Account Changed',{variant: 'success',});
        }
    return (
            <>
            <SectionTitle title="Change Client" subtitle="Change the client you're viewing" />
           <Widget title="Client List " >
           
            {clientList?
            <>
            <ul>
              {clientList.map((e)=>(
                <li key={e.ClientID}><button onClick={()=>updateClientID(e.ClientID)
                }>{e.CustomerName}</button></li>
              )
                
              )}
              </ul>
        
        
            </>
            :
            null
            }
           
            </Widget>
            </>
        );
}