import React from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import './chartstyles.scss'

const PressureChart=({airthinxData})=>{

var PressureData=[]
var timestampsData=[]
var timestampDay=[]

airthinxData.map((s) => (
    CheckIt(s)
    
    ))

 function CheckIt(Item)
       {
        if(Item.measurements){
        for(var i=0; i<Item.measurements.length; i++){
 
        if(Item.name==="Pressure")
        {
            PressureData.push(Item.measurements[i][1]);
            timestampsData.push(moment(Item.measurements[i][0]).format("h:mm"))
            timestampDay.push(moment(Item.measurements[i][0]).format("MMM D h:mm")) 
        }
    }
  }
       }


       const dataPressure= {
        labels: timestampsData  ,
        datasets: [
          { 
           
            borderColor: 'black',
            backgroundColor: 'white',
            borderWidth: .5,
            hoverBackgroundColor: 'rgb(91, 86, 113)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            barPercentage:.7,
            pointRadius: 1.5,
            pointHoverRadius: 6,
            data: PressureData,
          fill: false    }, 
      ], 
      
        };

        const minData=Math.min(...PressureData);
        const maxData=Math.max(...PressureData)
        const avgData = PressureData.reduce((a,b) => a + b, 0) / PressureData.length
           PressureData.map(function(value){
          var diff = value - avgData;
          return diff;
          
        });
        var squareDiffs = PressureData.map(function(value){
          var diff = value - avgData;
          var sqr = diff * diff;
          return sqr;
        });
        function average(data){
          var sum = data.reduce(function(sum, value){
            return sum + value;
          }, 0);
        
          var avg = sum / data.length;
          return avg;
        }
        
        var avgSquareDiff = average(squareDiffs)
        var stdDev = Math.sqrt(avgSquareDiff);

return(
<div>

{PressureData.length<1? (<p className="text-center m-5">No <b>Pressure</b> Data for Time Period</p>)
:  (<Line data={dataPressure}
 options={{
    title:{
      display:true,
      text:['Pressure'],
      fontSize:28
    },
    aspectRatio: 5,
    legend:{  display:false,  position:'right' },
    scales: {
      yAxes: [{
          scaleLabel: {
              display: true,
              labelString: 'hPa',
              fontSize: 15, fontColor: 'rgb(55, 61, 64)' },
          ticks: {   min:0,
            // (Math.min.apply(Math, PressureData))-Math.round((Math.min.apply(Math, PressureData)*.01)+1), 
            max: Math.round(avgData*2)
            //(Math.max.apply(Math, PressureData))+(Math.max.apply(Math, PressureData)*.01) +1
           }   }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: `Data from ${timestampDay[0]} -${timestampDay[PressureData.length-1]} `,
         fontSize: 15 },
      ticks: {  beginAtZero: false,
        "autoSkipPadding": 5, } }]
  },
  tooltips:{
    intersect: false }  }}
 />)}

 {/* <div class="avgBox ">
 <p class=" minmax">{`min: ${minData} `}</p> 
 <p class="minmax ">{`avg: ${avgData.toFixed(2)}`}</p> 
 <p class=" minmax">{`max: ${maxData} `}</p> 
 <p class="minmax ">{`Standard Deviation: ${stdDev.toFixed(2)} `}</p>  
</div> */}

{/* <div className="d-flex flex-row justify-content-around align-items-center">
<table className="">
  <tbody>
  <tr className="">
    <td className="minmax" > Minimum  </td>
    <td className="minmax text-right">{minData.toFixed(2)}</td>
  </tr>
  <tr className="">
    <td className="minmax"> Average </td>
    <td className="minmax text-right">{avgData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Maximum </td>
    <td className="minmax text-right">{maxData.toFixed(2)}</td>
  </tr>
  <tr>
    <td className="minmax"> Standard Deviation </td>
    <td className="minmax text-right">{stdDev.toFixed(2)}</td>
  </tr>
  </tbody>
</table>


</div> */}

</div>

)
  }

  export default PressureChart
